import React from "react";
import { Link } from "react-router-dom";
import "./common.css";

const classifiedData = [
  {
    name: "Rahul Sharma",
    email: "Rahul1234@gamil.com",
    mobileNumber: "9509397500",
    companyName: "Lakshya Trading Company",
    productOrService: "Stationery",
    imgSrc: "/assets/stationary.jpeg",
    altText: "Stationery - Lakshya Trading Company",
    mainProducts: "Stationery",
    years: "1 YRS",
    location: "Delhi, India",
    tooltipText: "456, Business Park, Delhi, India",
    rating: "4.2",
    ratingPercent: "85%",
    ratingsCount: "78",
    responseRate: "92%",
  },
  {
    _id: "vivek-nigam-uttar-pradesh",
    name: "Vivek Nigam",
    email: "VivekNigam@gmail.com",
    password: "12345678",
    mobileNumber: "9935819191",
    companyName: "Mahakal Hand Made Paper Industry",
    statename: "Uttar Pradesh",
    cityname: "Uttar Pradesh",
    productOrService: "Paper Sheet",
    imgSrc: "/assets/paper-sheet.jpg",
    mainProducts: "Paper Sheets",
    altText: "Mahakal Hand Made Paper Industry - Uttar Pradesh",
    years: "1 YRS",
    location: "Uttar Pradesh",
    tooltipText: "Mahakal Hand Made Paper Industry, Uttar Pradesh",
    rating: "4.3",
    ratingPercent: "87%",
    ratingsCount: "65",
    responseRate: "90%",
    whatsappConfirmed: false,
  },
];

const StationeryComponent = () => {
  return (
    <div className="main-box">
      <aside>
        <div className="flt-box-wrap">
          <div className="flt-box mb-0 flt-head">Filters By</div>
          <div className="flt-box bdrt-0">
            <p className="flt-title">Related Services</p>
            <div className="flt-content">
              <ul className="flt-list cust-scroll">
                <li>
                  <Link to="#">Pens</Link>
                </li>
                <li>
                  <Link to="#">Notebooks</Link>
                </li>
                <li>
                  <Link to="#">Office Supplies</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flt-box">
            <p className="flt-title">By Region</p>
            <div className="flt-content">
              <div className="flt-search">
                <input
                  type="text"
                  name="region_id"
                  placeholder="Search Region"
                  id="region-search-input"
                />
              </div>
              <ul className="flt-list cust-scroll" id="region-lists">
                <li>
                  <Link to="#">All India</Link>
                </li>
                <li>
                  <Link to="#">Delhi</Link>
                </li>
                <li>
                  <Link to="#">Maharashtra</Link>
                </li>
                <li>
                  <Link to="#">Uttar Pradesh</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
      <main>
        <section className="section">
          <p className="sect-title">Explore by Products</p>
          <div className="horprd expcatg" id="expcatg">
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/book1.jpeg"
                      width={55}
                      height={55}
                      alt="Books"
                    />
                  </figure>
                  <p className="title">Books</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/pen1.jpeg"
                      width={55}
                      height={55}
                      alt="Pens"
                    />
                  </figure>
                  <p className="title">Pens</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/colour1.jpeg"
                      width={55}
                      height={55}
                      alt="Colors"
                    />
                  </figure>
                  <p className="title">Colors</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/notebook.jpeg"
                      width={55}
                      height={55}
                      alt="Notebooks"
                    />
                  </figure>
                  <p className="title">Notebooks</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/officestationay.jpeg"
                      width={55}
                      height={55}
                      alt="Office Supplies"
                    />
                  </figure>
                  <p className="title">Office Supplies</p>
                </div>
              </Link>
            </div>
          </div>
        </section>
        <ul className="classfied-wrap">
          {classifiedData.map((item, index) => (
            <li key={index}>
              <div className="classified">
                <div className="prd-info">
                  <div className="prd-box">
                    <img
                      src={item.imgSrc}
                      alt={item.altText}
                      width={250}
                      height={250}
                    />
                  </div>
                </div>
                <div className="cinfo">
                  <div className="cbox">
                    <figure>
                      <span className="cmp-year">{item.years}</span>
                    </figure>
                    <div className="cboxr">
                      <Link to="#" target="_blank">
                        <h4 className="title">{item.companyName}</h4>
                      </Link>
                      <p className="cloc tooltip ellipsis">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi-location"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        {item.location}
                        <span className="tooltiptext">{item.tooltipText}</span>
                      </p>
                      <div className="rating-wrap">
                        <span className="rtbox">{item.rating}</span>
                        <span
                          className="crate"
                          style={{ "--_score": item.ratingPercent }}
                        />
                        <span className="rate-text">
                          {item.ratingsCount} Ratings
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="caddit">
                    <div className="item">
                      <div
                        className="ca-box modal-btn"
                        data-modal="motc"
                        data-src={item.trustCertificateUrl}
                      >
                        <p>
                          <i className="l3icon motc-icon" />
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="ca-box">
                        <p>
                          <i className="l3icon resp-icon" />
                        </p>
                        <p>
                          <span>Response Rate</span> <b>{item.responseRate}</b>
                        </p>
                      </div>
                      <p>
                        <span>Main Products</span> <b>{item.mainProducts}</b>
                      </p>
                    </div>
                  </div>
                  <div className="caction">
                    <Link to={"/register-buyer"}>
                      <p>Contact Supplier</p>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </main>
    </div>
  );
};

export default StationeryComponent;
