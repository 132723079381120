import React from "react";
import { Link } from "react-router-dom";
import "./common.css";

const classifiedData = [
  {
    _id: "anisa-banana-powder-darrang",
    name: "Abdul Aziz",
    email: "azizabdul222@gmail.com",
    password: "Abdul Aziz",
    mobileNumber: "9678985160",
    companyName: "Anisa Banana Powder",
    statename: "Assam",
    cityname: "Darrang",
    productOrService: "Banana powder, onion powder",
    imgSrc: "/assets/banana-powder1.jpg",
    mainProducts: "Banana powder, onion powder",
    altText: "Anisa Banana Powder - Darrang",
    years: "1 YR",
    location: "Darrang, Assam",
    tooltipText: "Abdul Aziz, Darrang",
    rating: "4.8",
    ratingPercent: "95%",
    ratingsCount: "83",
    responseRate: "97%",
    whatsappConfirmed: false,
  },

  {
    _id: "nilesh-jagtap-pune",
    name: "Nilesh Jagtap",
    email: "NileshJagtap@gmail.com",
    password: "123456789",
    mobileNumber: "7758085964",
    companyName: "Nilesh Jagtap",
    productOrService: "Banana Chip",
    imgSrc: "/assets/bana-chips3.jpg",
    mainProducts: "Banana Chip",
    altText: "Nilesh Jagtap - Banana Chip",
    years: "1 YRS", // Random value
    location: "Pune, Maharashtra, India",
    tooltipText: "Nilesh Jagtap, Banana Chip, Pune",
    rating: "4.4", // Random value
    ratingPercent: "88%", // Random value
    ratingsCount: "15", // Random value
    responseRate: "82%", // Random value
    whatsappConfirmed: false,
  },

  {
    _id: "vds-global-exim",
    name: "Shubham Machake",
    email: "vdsglobalexim@gmail.com",
    mobileNumber: "7249173273",
    companyName: "VDS Global Exim",
    productOrService: "Exporter of Alphonso Mango Pulp and Banana Powder",
    description:
      "Alphonso Mango Pulp is made from the finest Alphonso mangoes, known for their rich, golden-yellow color and smooth, velvety texture. Renowned for its naturally sweet flavor and tropical aroma, this pulp is ideal for use in smoothies. Premium Banana Powder by VDS Global Exim is made from high-quality, organically grown bananas sourced from India, renowned for its rich agricultural heritage.",
    imgSrc: "/assets/axim1.jpeg", // Placeholder image path
    mainProducts: "Alphonso Mango Pulp, Banana Powder",
    altText: "VDS Global Exim - Alphonso Mango Pulp, Banana Powder",
    years: "1 YRS", // Random value
    location: "India",
    tooltipText: "VDS Global Exim, Alphonso Mango Pulp, Banana Powder",
    rating: "4.7", // Random value
    ratingPercent: "94%", // Random value
    ratingsCount: "40", // Random value
    responseRate: "88%", // Random value
    whatsappConfirmed: true,
  },
  {
    name: "rushabh",
    email: "abc@gmail.com",
    password: "rushabh@123",
    mobileNumber: "9773179038",
    companyName: "kashi enterprise",
    productOrService: "banana powder",
    imgSrc: "/assets/bana1.jpg", // Correct image path needed
    mainProducts:
      "Organic Banana Powder, Natural Banana Powder, Instant Banana Powder, etc.",
    altText: "Banana Powder Suppliers - Kashi Enterprise",
    years: "1 YRS",
    location: "Gujrat India",
    tooltipText: "Gujrat India",
    rating: "78%",
    ratingPercent: "89%",
    ratingsCount: "90%",
    responseRate: "95%",
  },
  {
    name: "Tejas shinde",
    email: "abc@gmail.com",
    password: "tejas@123",
    mobileNumber: "8329952988",
    companyName: "Trishula Global Shipping",
    productOrService: "banana powder",
    imgSrc: "/assets/bnp.jpg", // Correct image path needed
    mainProducts:
      "Organic Banana Powder, Natural Banana Powder, Instant Banana Powder, etc.",
    altText: "Banana Powder Suppliers - Trishula Global Shipping",
    years: "1 YRS",
    location: "Chennai India",
    tooltipText: "Chennai India",
    rating: "88%",
    ratingPercent: "89%",
    ratingsCount: "90%",
    responseRate: "95%",
  },
  // {
  //     "name": "Tejas shinde",
  //     "email": "abc@gmail.com",
  //     "password": "tejas@123",
  //     "mobileNumber": "8329952988",
  //     "companyName": "",
  //     "productOrService": "banana powder",
  //     "imgSrc": "/assets/bnp.jpg", // Correct image path needed
  //     "mainProducts": "Organic Banana Powder, Natural Banana Powder, Instant Banana Powder, etc.",
  //     "altText": "Banana Powder Suppliers - Trishula Global Shipping",
  //     "years": "1 YRS",
  //     "location": "Chennai India",
  //     "tooltipText": "Chennai India",
  //     "rating": "88%",
  //     "ratingPercent": "89%",
  //     "ratingsCount": "90%",
  //     "responseRate": "95%"
  // },
  {
    name: "Praveen Kumar",
    email: "abc@gmail.com",
    password: "praveen@123",
    mobileNumber: "7056700315",
    companyName: "Earth's Nature",
    productOrService: "fruits and vegetable powder",
    imgSrc: "/assets/bana2.jpg", // Correct image path needed
    mainProducts:
      "Organic Banana Powder, Natural Banana Powder, Instant Banana Powder, etc.",
    altText: "Fruits and Vegetable Powder Suppliers - Earth's Nature",
    years: "1 YRS",
    location: "Chennai India",
    tooltipText: "Chennai India",
    rating: "88%",
    ratingPercent: "89%",
    ratingsCount: "90%",
    responseRate: "95%",
  },
  {
    _id: "musa-effigy-powder-pvt-ltd",
    name: "Surender Kumar Reddy",
    email: "sp4333friends@gmail.com",
    password: "surender kumar reddy",
    mobileNumber: "9346247309",
    companyName: "Musa Effigy Powder Pvt Ltd",
    statename: "Andhra Pradesh",
    cityname: "Nayunipalle",
    productOrService: "Banana Powder",
    imgSrc: "/assets/banana-powder3.jpg",
    mainProducts: "Banana Powder",
    altText: "Musa Effigy Powder Pvt Ltd - Banana Powder",
    years: "1 YRS",
    location: "Nayunipalle, Andhra Pradesh",
    tooltipText: "Musa Effigy Powder Pvt Ltd, Banana Powder",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "55",
    responseRate: "88%",
    whatsappConfirmed: false,
  },
  {
    _id: "danem-food-products",
    name: "Mohammed Asaduddin",
    email: "danemfoodproducts@gmail.com",
    password: "Mohammed Asaduddin",
    mobileNumber: "6303911559",
    companyName: "DANEM FOOD PRODUCTS",
    statename: "Telangana",
    cityname: "Hyderabad",
    productOrService: "Banana Powder",
    imgSrc: "/assets/banna-powder.jpg",
    mainProducts: "Banana Powder",
    altText: "DANEM FOOD PRODUCTS - Banana Powder",
    years: "1 YRS",
    location: "Hyderabad, Telangana",
    tooltipText: "DANEM FOOD PRODUCTS, Banana Powder",
    rating: "4.8",
    ratingPercent: "96%",
    ratingsCount: "78",
    responseRate: "90%",
    whatsappConfirmed: false,
  },
  {
    _id: "sun-shine-enterprises",
    name: "Mr. Shakir Shaikh",
    email: "sunshineagrigreen@gmail.com",
    password: "Mr. Shakir Shaikh",
    mobileNumber: "9893353614",
    companyName: "Sun Shine Enterprises",
    statename: "Madhya Pradesh",
    cityname: "Dewas",
    productOrService: "Banana Powder",
    imgSrc: "/assets/banana-powder5.jpg",
    mainProducts: "Banana Powder",
    altText: "Sun Shine Enterprises - Banana Powder Manufacturer",
    years: "1 YRS",
    location: "Dewas, Madhya Pradesh",
    tooltipText: "Sun Shine Enterprises - Experts in Banana Powder",
    rating: "4.9",
    ratingPercent: "97%",
    ratingsCount: "120",
    responseRate: "95%",
    whatsappConfirmed: true,
  },
  {
    _id: "krishna-chandra-das",
    name: "Mr. Krishna Chandra Das",
    email: "krishna16011987das@gmail.com",
    password: "Mr. Krishna Chandra Das",
    mobileNumber: "9064117988",
    companyName: "NABADITA A-1 ENTERPRISE",
    statename: "West Bengal",
    cityname: "North 24 Parganas",
    productOrService: "Banana Powder",
    imgSrc: "/assets/banana-powder4.jpg",
    mainProducts: "Banana Powder",
    altText: "Mr. Krishna Chandra Das - Supplier of Banana Powder",
    years: "1 YRS",
    location: "North 24 Parganas, West Bengal",
    tooltipText: "Mr. Krishna Chandra Das - Specialist in Banana Powder",
    rating: "4.2",
    ratingPercent: "84%",
    ratingsCount: "95",
    responseRate: "92%",
    whatsappConfirmed: false,
  },
  {
    _id: "bharath-banana-powder",
    name: "Bharath",
    email: "bharathharri@gmail.com",
    password: "bharathbharath",
    mobileNumber: "9100140887",
    companyName: "Laxmi Enterprises",
    statename: "Telangana",
    cityname: "Hyderabad",
    productOrService: "Banana Powder",
    imgSrc: "/assets/banana-powder6.jpg",
    mainProducts: "High-Quality Banana Powder",
    altText: "Bharath - Supplier of Banana Powder",
    years: "1 YRS",
    location: "Hyderabad, Telangana, India",
    tooltipText: "Bharath - Expert in Banana Powder Products",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "62",
    responseRate: "85%",
    whatsappConfirmed: true,
  },
  {
    _id: "nikul-patel-patel-agri-product",
    name: "Nikul Patel",
    email: "patelagriproduct@gmail.com",
    password: "nikul patel",
    mobileNumber: "9054627814",
    companyName: "Patel Agri Product",
    statename: "Gujrat",
    cityname: "Bharuch",
    productOrService: "Banana Powder",
    imgSrc: "/assets/bananaPowder19.jpg",
    mainProducts: "Organic Banana Powder, Fruit Powders, Agro Products",
    altText: "Nikul Patel - Supplier of Banana Powder",
    years: "1 YRS",
    location: "Bharuch, Gujarat, India",
    tooltipText: "Nikul Patel - Trusted Supplier of Banana Powder",
    rating: "4.4",
    ratingPercent: "88%",
    ratingsCount: "92",
    responseRate: "89%",
    whatsappConfirmed: false,
  },

  // Add more items if needed
];

const BananaPowderSuppliers = () => {
  return (
    <div className="main-box">
      <aside>
        <div className="flt-box-wrap">
          <div className="flt-box mb-0 flt-head">Filters By</div>
          <div className="flt-box bdrt-0">
            <p className="flt-title">Related Categories</p>
            <div className="flt-content">
              <ul className="flt-list cust-scroll">
                <li>
                  <Link to="#">Organic Banana Powder</Link>
                </li>
                <li>
                  <Link to="#">Natural Banana Powder</Link>
                </li>
                <li>
                  <Link to="#">Instant Banana Powder</Link>
                </li>
                {/* Add more categories as needed */}
              </ul>
            </div>
          </div>
          <div className="flt-box">
            <p className="flt-title">By State</p>
            <div className="flt-content">
              <div className="flt-search">
                <input
                  type="text"
                  name="state_id"
                  placeholder="Search State"
                  id="state-search-input"
                />
              </div>
              <ul className="flt-list cust-scroll" id="state-lists">
                <li>
                  <Link to="#">All India</Link>
                </li>
                <li>
                  <Link to="#">Maharashtra</Link>
                </li>
                <li>
                  <Link to="#">Gujarat</Link>
                </li>
                <li>
                  <Link to="#">Tamil Nadu</Link>
                </li>
                <li>
                  <Link to="#">Karnataka</Link>
                </li>
                <li>
                  <Link to="#">Delhi</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
      <main>
        <section className="section">
          <p className="sect-title">Explore by Categories</p>
          <div className="horprd expcatg" id="expcatg">
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/bana3.jpg"
                      width={55}
                      height={55}
                      alt="Banana Powder Category"
                    />
                  </figure>
                  <p className="title">Banana Powder Category</p>
                </div>
              </Link>
            </div>
            {/* Add more categories as needed */}
          </div>
        </section>
        <ul className="classfied-wrap">
          {classifiedData.map((item, index) => (
            <li key={index}>
              <div className="classified">
                <div className="prd-info">
                  <div className="prd-box">
                    <img
                      src={item.imgSrc}
                      alt={item.altText}
                      width={250}
                      height={250}
                    />
                  </div>
                </div>
                <div className="cinfo">
                  <div className="cbox">
                    <figure>
                      <span className="cmp-year">{item.years}</span>
                    </figure>
                    <div className="cboxr">
                      <Link to="#" target="_blank">
                        <h4 className="title">{item.companyName}</h4>
                      </Link>
                      <p className="cloc tooltip ellipsis">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi-location"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        {item.location}
                        <span className="tooltiptext">{item.tooltipText}</span>
                      </p>
                      <div className="rating-wrap">
                        <span className="rtbox">{item.rating}</span>
                        <span
                          className="crate"
                          style={{ "--_score": item.ratingPercent }}
                        />
                        <span className="rate-text">
                          {item.ratingsCount} Ratings
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="caddit">
                    <div className="item">
                      <div className="ca-box">
                        <p>
                          <i className="l3icon resp-icon" />
                        </p>
                        <p>
                          <span>Response Rate</span> <b>{item.responseRate}</b>
                        </p>
                      </div>
                      <p>
                        <span>Main Products</span> <b>{item.mainProducts}</b>
                      </p>
                    </div>
                  </div>
                  <div className="caction">
                    <Link to={"/register-buyer"}>
                      <p>Contact Supplier</p>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </main>
    </div>
  );
};

export default BananaPowderSuppliers;
