import React from "react";
import { Link } from "react-router-dom";
import "./common.css";

const classifiedData = [
  {
    name: "Krishan Singh",
    email: "krishansingh@gmail.com",
    mobileNumber: "9829014808",
    companyName: "Krishan Singh",
    productOrService: "Mustard Oil",
    imgSrc: "/assets/oils.jpeg", // Example image path, adjust as necessary
    altText: "Mustard Oil - Krishan Singh",
    mainProducts: "Mustard Oil, Pure Mustard Oil, Cold Pressed Mustard Oil",
    years: "1 YRS",
    location: "Delhi, India", // Update with actual location if available
    tooltipText: "Delhi, India",
    rating: "4.2",
    ratingPercent: "85%",
    ratingsCount: "60",
    responseRate: "90%",
    catalogLink: "/krishan-singh", // Path for redirection
  },
  {
    _id: "a1234567890wxyz5682",
    name: "Dhamani Enterprises",
    email: "dhamanieterprises@gmail.com",
    password: "12345678",
    mobileNumber: "9414057258",
    companyName: "Dhamani Enterprises",
    productOrService: "Oil",
    imgSrc: "/assets/oil7.jpeg", // Placeholder for image path
    mainProducts: "Edible Oils, Industrial Oils, Specialty Oils",
    altText: "Oil - Dhamani Enterprises",
    years: "1 YRS", // Placeholder for years of experience
    location: "Mumbai, India", // Placeholder for location
    tooltipText: "Dhamani Enterprises, Mumbai, India",
    rating: "4.2", // Placeholder for rating
    ratingPercent: "84%", // Placeholder for rating percentage
    ratingsCount: "80", // Placeholder for ratings count
    responseRate: "89%", // Placeholder for response rate
    whatsappConfirmed: true,
  },
  {
    _id: "western-info-tech-kudal",
    name: "Ibrahim Shaikh",
    email: "westerninfotech@gmail.com",
    password: "Ibrahim Shaikh",
    mobileNumber: "8975524939",
    companyName: "Western Info Tech",
    statename: "Maharashtra",
    cityname: "Kudal",
    productOrService: "Coconut Oil, Cashew",
    imgSrc: "/assets/coco-oil.jpg",
    mainProducts: "Coconut Oil, Cashew",
    altText: "Western Info Tech - Kudal, Maharashtra",
    years: "1 YRS",
    location: "Kudal, Maharashtra",
    tooltipText: "Western Info Tech, Kudal",
    rating: "4.3",
    ratingPercent: "86%",
    ratingsCount: "95",
    responseRate: "89%",
    whatsappConfirmed: true,
  },

  {
    _id: "a1234567890wxyz5685",
    name: "SHREE HIRALAL TEL UDHYOG",
    email: "shreehiralal@gmail.com",
    password: "12345678",
    mobileNumber: "9772096096",
    companyName: "SHREE HIRALAL TEL UDHYOG",
    productOrService: "Oil",
    imgSrc: "/assets/oils2.jpeg", // Placeholder for image path
    mainProducts: "Cooking Oil, Edible Oil",
    altText: "Oil - SHREE HIRALAL TEL UDHYOG",
    years: "1 YRS", // Placeholder for years of experience
    location: "India", // Placeholder for location
    tooltipText: "SHREE HIRALAL TEL UDHYOG, India",
    rating: "4.5", // Placeholder for rating
    ratingPercent: "90%", // Placeholder for rating percentage
    ratingsCount: "120", // Placeholder for ratings count
    responseRate: "92%", // Placeholder for response rate
    whatsappConfirmed: true,
  },
  {
    _id: "g3h4i5j6k7l8m9n0o1p2q3r4", // Random unique ID
    name: "Manish",
    email: "manish@gmail.com",
    mobileNumber: "8160586992",
    companyName: "Kanakdhara Enterprises",
    productOrService: "Oil",
    imgSrc: "/assets/oil4.jpeg", // Example image path
    mainProducts: "Edible Oils, Cooking Oils, Essential Oils, Organic Oils",
    altText: "Oil - Kanakdhara Enterprises",
    years: "1 YRS",
    location: "Pune, India",
    tooltipText: "12 Oil Avenue, Pune, India",
    rating: "4.4",
    ratingPercent: "89%",
    ratingsCount: "45",
    responseRate: "91%",
    whatsappConfirmed: true,
  },
  {
    name: "Pravin",
    email: "gpravinexport@gmail.com",
    password: "pravinpravin",
    mobileNumber: "9427568281",
    companyName: "G.PRAVIN EXPORT",
    productOrService: "Grocery, Cooking Oil",
    imgSrc: "/assets/oil3.jpeg",
    mainProducts: "Grocery, Cooking Oil, Spices",
    altText: "G.PRAVIN EXPORT - Grocery and Cooking Oil",
    years: "1 YRS",
    location: "Surat, Gujarat, India",
    tooltipText: "G.PRAVIN EXPORT, Surat",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "85",
    responseRate: "88%",
    whatsappConfirmed: true,
  },
  {
    _id: "salima-al-noor-traders-hosur",
    name: "salima",
    email: "hari28tvl@gmail.com",
    password: "9489289786",
    mobileNumber: "9489289786",
    companyName: "Al noor traders",
    productOrService: "vegetable, edible oil",
    imgSrc: "/assets/oil10.jpg",
    mainProducts: "vegetable, edible oil",
    altText: "Al noor traders - vegetable, edible oil",
    years: "1 YRS",
    location: "Hosur, Tamil Nadu, India",
    tooltipText: "Al noor traders, vegetable, edible oil, Hosur",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "30",
    responseRate: "85%",
    whatsappConfirmed: true,
  },
  {
    _id: "stalindurai-madhu-hosur-peppermint-oil",
    name: "Stalindurai Madhu",
    email: "StalinduraiMadhu@gmail.com",
    password: "12345678",
    mobileNumber: "8438998113",
    companyName: "Stalindurai Madhu",
    statename: "Tamil Nadu",
    cityname: "Hosur",
    productOrService: "Peppermint Oil",
    imgSrc: "/assets/peppermint-oil.jpg",
    mainProducts: "Peppermint Oil",
    altText: "Stalindurai Madhu - Peppermint Oil Supplier in Hosur",
    years: "1 YRS",
    location: "Hosur, Tamil Nadu",
    tooltipText: "Stalindurai Madhu - Quality Peppermint Oil Supplier",
    rating: "4.6",
    ratingPercent: "92%",
    ratingsCount: "210",
    responseRate: "90%",
    whatsappConfirmed: true,
  },
  {
    _id: "ksonal-international-surat",
    name: "Nilesh Gangani",
    email: "NileshGangani123@gmail.com",
    password: "Nilesh Gangani",
    mobileNumber: "9409810700",
    companyName: "Ksonal International",
    productOrService: "Peanut Oil",
    imgSrc: "/assets/peanut-oil.jpg",
    mainProducts: "Peanut Oil",
    altText: "Ksonal International - Peanut Oil",
    years: "1 YRS", // Random value
    location: "Surat, Gujarat, India",
    tooltipText: "Ksonal International, Peanut Oil, Surat",
    rating: "4.7", // Random value
    ratingPercent: "95%", // Random value
    ratingsCount: "40", // Random value
    responseRate: "92%", // Random value
    whatsappConfirmed: true,
  },
  {
    _id: "kaushik-puchhadiya",
    name: "Kaushik Puchhadiya",
    email: "info@greenselects.in",
    password: "Kaushik Puchhadiya",
    mobileNumber: "9727771703",
    companyName: "Green Selects",
    statename: "Gujarat",
    cityname: "Junagadh",
    productOrService: "Groundnut Oil",
    imgSrc: "/assets/groundnut-oil1.jpg",
    mainProducts: "Groundnut Oil",
    altText: "Kaushik Puchhadiya - Groundnut Oil Supplier",
    years: "1 YRS",
    location: "Junagadh, Gujarat",
    tooltipText: "Kaushik Puchhadiya - Expert in Groundnut Oil",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "120",
    responseRate: "95%",
    whatsappConfirmed: true,
  },
  {
    _id: "spm-samy-gold-coconut-groundnut-oil",
    name: "Mr. Shiva",
    email: "9865810599@gmail.com",
    password: "9865810599",
    mobileNumber: "9865810599",
    companyName: "SPM SAMY GOLD",
    statename: "Tamil Nadu",
    cityname: "Erode",
    productOrService: "Coconut Oil, Groundnut Oil, Natural Coconut Soap",
    imgSrc: "/assets/coco-oisls.jpg",
    mainProducts: "Coconut Oil, Groundnut Oil, Natural Coconut Soap",
    altText:
      "SPM SAMY GOLD - Supplier of Coconut Oil, Groundnut Oil, and Natural Coconut Soap",
    years: "1 YRS",
    location: "Erode, Tamil Nadu, India",
    tooltipText:
      "Mr. Shiva - Specialist in Coconut Oil, Groundnut Oil, and Natural Coconut Soap",
    rating: "4.6",
    ratingPercent: "93%",
    ratingsCount: "38",
    responseRate: "89%",
    whatsappConfirmed: true,
  },

  {
    _id: "karthika-oil-mill-coconut-groundnut-sesame-oil",
    name: "Mr. S. Jaganathan",
    email: "9659536355@gmail.com",
    password: "9659536355",
    mobileNumber: "9659536355",
    companyName: "KARTHIKA OIL MILL",
    statename: "Tamil Nadu",
    cityname: "Erode",
    productOrService: "Coconut Oil, Groundnut Oil, Sesame Oil",
    imgSrc: "/assets/coco-oil1.jpg",
    mainProducts: "Coconut Oil, Groundnut Oil, Sesame Oil",
    altText:
      "KARTHIKA OIL MILL - Supplier of Coconut Oil, Groundnut Oil, and Sesame Oil",
    years: "1 YRS",
    location: "Erode, Tamil Nadu, India",
    tooltipText:
      "Mr. S. Jaganathan - Specialist in Coconut Oil, Groundnut Oil, and Sesame Oil",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "42",
    responseRate: "90%",
    whatsappConfirmed: true,
  },
  {
    _id: "priyam-marachekku-oil-mill-edible-castor-lamp-oil",
    name: "Ms. M. Gomathi Priya",
    email: "9942051686@gmail.com",
    password: "9942051686",
    mobileNumber: "9942051686",
    companyName: "PRIYAM MARACHEKKU OIL MILL",
    statename: "Tamil Nadu",
    cityname: "Erode",
    productOrService: "Edible Oil, Castor Oil, Pure Lamp Oil",
    imgSrc: "/assets/edible-caster-oil.jpg",
    mainProducts: "Edible Oil, Castor Oil, Pure Lamp Oil",
    altText:
      "PRIYAM MARACHEKKU OIL MILL - Supplier of Edible Oil, Castor Oil, and Pure Lamp Oil",
    years: "1 YRS",
    location: "Erode, Tamil Nadu, India",
    tooltipText:
      "Ms. M. Gomathi Priya - Specialist in Edible Oil, Castor Oil, and Pure Lamp Oil",
    rating: "4.8",
    ratingPercent: "95%",
    ratingsCount: "50",
    responseRate: "92%",
    whatsappConfirmed: true,
  },
  {
    _id: "yora-virgin-coconut-oil-desiccated-powder-shell-gel",
    name: "Arun",
    email: "support@theyora.com",
    password: "939379215",
    mobileNumber: "8939379215",
    companyName: "YORA",
    statename: "Tamil Nadu",
    cityname: "Tirupur",
    productOrService:
      "Virgin Coconut Oil, Desiccated Coconut Powder, Coconut Shell, Coconut Gel",
    imgSrc: "/assets/virgin-coco-oil.jpg",
    mainProducts:
      "Virgin Coconut Oil, Desiccated Coconut Powder, Coconut Shell, Coconut Gel",
    altText:
      "YORA - Supplier of Virgin Coconut Oil, Desiccated Coconut Powder, Coconut Shell, and Coconut Gel",
    years: "1  YRS",
    location: "Tirupur, Tamil Nadu, India",
    tooltipText:
      "Arun - Specialist in Virgin Coconut Oil, Desiccated Coconut Powder, Coconut Shell, and Coconut Gel",
    rating: "4.8",
    ratingPercent: "95%",
    ratingsCount: "60",
    responseRate: "93%",
    whatsappConfirmed: true,
  },
  {
    _id: "nallaru-coconut-farmers-producer-company-limited",
    name: "Arumugavel.s",
    email: "9952239426@gmail.com",
    password: "9952239426",
    mobileNumber: "9952239426",
    companyName: "Nallaru Coconut Farmers Producer Company Limited",
    statename: "Tamil Nadu",
    cityname: "Tirupur",
    productOrService:
      "Virgin Coconut Oil, Coconut Sugar, Nallaru Coconut Oil, Groundnut Oil",
    imgSrc: "/assets/vergin-coco-oil3.jpg",
    mainProducts:
      "Virgin Coconut Oil, Coconut Sugar, Nallaru Coconut Oil, Groundnut Oil",
    altText:
      "Nallaru Coconut Farmers Producer Company Limited - Supplier of Virgin Coconut Oil, Coconut Sugar, Nallaru Coconut Oil, and Groundnut Oil",
    years: "1 YRS",
    location: "Tirupur, Tamil Nadu, India",
    tooltipText:
      "Arumugavel.s - Specialist in Virgin Coconut Oil, Coconut Sugar, Nallaru Coconut Oil, and Groundnut Oil",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "55",
    responseRate: "92%",
    whatsappConfirmed: true,
  },
  {
    _id: "selva-kumar-oil-mills",
    name: "S. Suresh Kumar",
    email: "9842379988@gmail.com",
    password: "9842379988",
    mobileNumber: "9842379988",
    companyName: "Selva Kumar Oil Mills",
    statename: "Tamil Nadu",
    cityname: "Tirupur",
    productOrService:
      "Cotton Seed Oil Cake, Washed Cotton Seed Oil, Chekku Gingelly Oil, Milling Oils",
    imgSrc: "/assets/cotton-sead-oil.jpg",
    mainProducts:
      "Cotton Seed Oil Cake, Washed Cotton Seed Oil, Chekku Gingelly Oil, Milling Oils",
    altText:
      "Selva Kumar Oil Mills - Supplier of Cotton Seed Oil Cake, Washed Cotton Seed Oil, Chekku Gingelly Oil, and Milling Oils",
    years: "1 YRS",
    location: "Tirupur, Tamil Nadu, India",
    tooltipText:
      "S. Suresh Kumar - Specialist in Cotton Seed Oil Cake, Washed Cotton Seed Oil, Chekku Gingelly Oil, and Milling Oils",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "50",
    responseRate: "90%",
    whatsappConfirmed: true,
  },
  {
    _id: "m3-oils-food-products",
    name: "Mrs Rani",
    email: "8428420484@gmail.com",
    password: "8428420484",
    mobileNumber: "8428420484",
    companyName: "M3 Oils & Food Products",
    statename: "Tamil Nadu",
    cityname: "Tirupur",
    productOrService:
      "Cold Pressed Sesame Oil, Cold Pressed Coconut Oil, Castor Oil, Red Sandal Products",
    imgSrc: "/assets/cotton-seasam-oil.jpg",
    mainProducts:
      "Cold Pressed Sesame Oil, Cold Pressed Coconut Oil, Castor Oil, Red Sandal Products",
    altText:
      "M3 Oils & Food Products - Supplier of Cold Pressed Sesame Oil, Cold Pressed Coconut Oil, Castor Oil, and Red Sandal Products",
    years: "1 YRS",
    location: "Tirupur, Tamil Nadu, India",
    tooltipText:
      "Mrs Rani - Specialist in Cold Pressed Sesame Oil, Cold Pressed Coconut Oil, Castor Oil, and Red Sandal Products",
    rating: "4.8",
    ratingPercent: "96%",
    ratingsCount: "48",
    responseRate: "92%",
    whatsappConfirmed: true,
  },
  {
    _id: "moosika-exporters",
    name: "Mr. Vijay Kumar",
    email: "6369793671@gmail.com",
    password: "6369793671",
    mobileNumber: "6369793671",
    companyName: "Moosika Exporters",
    statename: "Tamil Nadu",
    cityname: "Salem",
    productOrService:
      "Coconut Oil, Dried Coconut, Green Cardamom, Turmeric Powder, R22 Gas",
    imgSrc: "/assets/coconut-oil99.jpg",
    mainProducts:
      "Coconut Oil, Dried Coconut, Green Cardamom, Turmeric Powder, R22 Gas",
    altText:
      "Moosika Exporters - Supplier of Coconut Oil, Dried Coconut, Green Cardamom, Turmeric Powder, and R22 Gas",
    years: "1 YRS",
    location: "Salem, Tamil Nadu, India",
    tooltipText:
      "Mr. Vijay Kumar - Specialist in Coconut Oil, Dried Coconut, Green Cardamom, Turmeric Powder, and R22 Gas",
    rating: "4.6",
    ratingPercent: "92%",
    ratingsCount: "40",
    responseRate: "88%",
    whatsappConfirmed: true,
  },
  {
    _id: "jvt-groups",
    name: "Mr. Mathivarmaraja Ravichandiran",
    email: "mathivarman325@gmail.com",
    password: "7305556661",
    mobileNumber: "7305556661",
    companyName: "JVT GROUPS",
    statename: "Tamil Nadu",
    cityname: "Tirupattur",
    productOrService: "Groundnut Oil, Coconut Oil",
    imgSrc: "/assets/ground-nut-oil.jpg",
    mainProducts: "Groundnut Oil, Coconut Oil",
    altText: "JVT GROUPS - Trusted Manufacturer of Groundnut and Coconut Oils",
    years: "1 YRS",
    location: "Tirupattur, Tamil Nadu, India",
    tooltipText:
      "Mr. Mathivarmaraja Ravichandiran - Expert in Premium Quality Oils",
    rating: "4.6",
    ratingPercent: "93%",
    ratingsCount: "85",
    responseRate: "88%",
    whatsappConfirmed: true,
  },
  {
    _id: "pura-mark-seeds",
    name: "Mr. Isravel Thangaraj S",
    email: "9942769487@gmail.com",
    password: "9942769487",
    mobileNumber: "9942769487",
    companyName: "PURA MARK SEEDS",
    statename: "Tamil Nadu",
    cityname: "Tenkasi",
    productOrService: "Moringa Oil, Moringa Seeds",
    imgSrc: "/assets/moringa-oil11.jpg",
    mainProducts: "Moringa Oil, Moringa Seeds",
    altText: "PURA MARK SEEDS - Leading Supplier of Moringa Products",
    years: "1 YRS",
    location: "Tenkasi, Tamil Nadu, India",
    tooltipText:
      "Mr. Isravel Thangaraj S - Expert in High-Quality Moringa Products",
    rating: "4.8",
    ratingPercent: "96%",
    ratingsCount: "110",
    responseRate: "92%",
    whatsappConfirmed: true,
  },
  {
    _id: "anaivi-traders",
    name: "Mr. Senthilkumar.P",
    email: "8754060361@gmail.com",
    password: "8754060361",
    mobileNumber: "8754060361",
    companyName: "Anaivi Traders",
    statename: "Tamil Nadu",
    cityname: "Theni",
    productOrService: "Cold Pressed Edible Oil, Green Cardamom",
    imgSrc: "/assets/edible-oils.jpg",
    mainProducts: "Cold Pressed Edible Oil, Green Cardamom",
    altText:
      "Anaivi Traders - Supplier of Cold Pressed Edible Oil and Green Cardamom",
    years: "1 YRS",
    location: "Theni, Tamil Nadu, India",
    tooltipText:
      "Mr. Senthilkumar.P - Supplier of Premium Cold Pressed Oils and Green Cardamom",
    rating: "4.8",
    ratingPercent: "95%",
    ratingsCount: "72",
    responseRate: "94%",
    whatsappConfirmed: true,
  },
  {
    _id: "mahdhi-carbons",
    name: "Mr. Natheem Mohamed",
    email: "9790428588@gmail.com",
    password: "9790428588",
    mobileNumber: "9790428588",
    companyName: "Mahdhi Carbons",
    statename: "Tamil Nadu",
    cityname: "Coimbatore",
    productOrService:
      "Coconut, Edible Oil, Seeds, Food Grains & Cereals, Epoxy Coating",
    imgSrc: "/assets/oil8.jpg",
    mainProducts:
      "Coconut, Edible Oil, Seeds, Food Grains & Cereals, Epoxy Coating",
    altText:
      "Mahdhi Carbons - Supplier of Coconut, Edible Oil, Seeds, and Food Grains",
    years: "1 YRS",
    location: "Coimbatore, Tamil Nadu, India",
    tooltipText:
      "Mr. Natheem Mohamed - Supplier of Coconut, Edible Oils, and Food Grains",
    rating: "4.7",
    ratingPercent: "93%",
    ratingsCount: "68",
    responseRate: "91%",
    whatsappConfirmed: true,
  },
  {
    _id: "thamiraa-wooden-seeds-oil-product",
    name: "Mr. N. V Kalaiselvan",
    email: "kalai271114@gmail.com",
    password: "6379722974",
    mobileNumber: "6379722974",
    companyName: "Thamiraa Wooden Seeds Oil Product",
    statename: "Tamil Nadu",
    cityname: "Dindigul",
    productOrService:
      "Cold Pressed Sesame Oil, Cold Pressed Coconut Oil, Cold Pressed Groundnut Oil",
    imgSrc: "/assets/cold-pressesd-oil.jpg",
    mainProducts: "Cold Pressed Sesame Oil, Coconut Oil, Groundnut Oil",
    altText: "Thamiraa Wooden Seeds Oil Product - Cold Pressed Oils Supplier",
    years: "1 YRS",
    location: "Dindigul, Tamil Nadu, India",
    tooltipText: "Mr. N. V Kalaiselvan - Supplier of Cold Pressed Oils",
    rating: "4.8",
    ratingPercent: "94%",
    ratingsCount: "75",
    responseRate: "89%",
    whatsappConfirmed: true,
  },

  // Add more items if needed
];

const MustardOilStore = () => {
  return (
    <div className="main-box">
      {/* Aside section for filters */}
      <aside>
        <div className="flt-box-wrap">
          <div className="flt-box mb-0 flt-head">Filters By</div>
          <div className="flt-box bdrt-0">
            <p className="flt-title">Related Categories</p>
            <div className="flt-content">
              <ul className="flt-list cust-scroll">
                <li>
                  <Link to="#">Mustard Oil</Link>
                </li>
                <li>
                  <Link to="#">Edible Oils</Link>
                </li>
                <li>
                  <Link to="#">Cooking Oils</Link>
                </li>
                <li>
                  <Link to="#">Cold Pressed Oils</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flt-box">
          <p className="flt-title">By State</p>
          <div className="flt-content">
            <div className="flt-search">
              <input
                type="text"
                name="state_id"
                placeholder="Search State"
                id="state-search-input"
              />
            </div>
            <ul className="flt-list cust-scroll" id="state-lists">
              <li>
                <Link to="#">All India</Link>
              </li>
              <li>
                <Link to="#">Delhi</Link>
              </li>
              <li>
                <Link to="#">Haryana</Link>
              </li>
              <li>
                <Link to="#">Punjab</Link>
              </li>
              <li>
                <Link to="#">Uttar Pradesh</Link>
              </li>
            </ul>
          </div>
        </div>
      </aside>
      {/* Main section for classified listings */}
      <main>
        <section className="section">
          <p className="sect-title">Explore by Categories</p>

          <div className="horprd expcatg" id="expcatg">
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/mus1.jpeg"
                      width={55}
                      height={55}
                      alt="Mustard Oil"
                    />
                  </figure>
                  <p className="title">Mustard Oil</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/oil2.jpeg"
                      width={55}
                      height={55}
                      alt="Edible Oils"
                    />
                  </figure>
                  <p className="title">Edible Oils</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/oil3.jpeg"
                      width={55}
                      height={55}
                      alt="Cooking Oils"
                    />
                  </figure>
                  <p className="title">Cooking Oils</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/oil5.jpeg"
                      width={55}
                      height={55}
                      alt="Cold Pressed Oils"
                    />
                  </figure>
                  <p className="title">Cold Pressed Oils</p>
                </div>
              </Link>
            </div>
          </div>
        </section>

        <ul className="classfied-wrap">
          {classifiedData.map((item, index) => (
            <li key={index}>
              <div className="classified">
                <div className="prd-info">
                  <div className="prd-box">
                    <img
                      src={item.imgSrc}
                      alt={item.altText}
                      width={250}
                      height={250}
                    />
                  </div>
                </div>
                <div className="cinfo">
                  <div className="cbox">
                    <figure>
                      <span className="cmp-year">{item.years}</span>
                    </figure>
                    <div className="cboxr">
                      <Link to={item.catalogLink} target="_blank">
                        <h4 className="title">{item.companyName}</h4>
                      </Link>
                      <p className="cloc tooltip ellipsis">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi-location"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        {item.location}
                        <span className="tooltiptext">{item.tooltipText}</span>
                      </p>
                      <div className="rating-wrap">
                        <span className="rtbox">{item.rating}</span>
                        <span
                          className="crate"
                          style={{ "--_score": item.ratingPercent }}
                        />
                        <span className="rate-text">
                          {item.ratingsCount} Ratings
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="caddit">
                    <div className="item">
                      <div
                        className="ca-box modal-btn"
                        data-modal="motc"
                        data-src={item.trustCertificateUrl}
                      >
                        <p>
                          <i className="l3icon motc-icon" />
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="ca-box">
                        <p>
                          <i className="l3icon resp-icon" />
                        </p>
                        <p>
                          <span>Response Rate</span> <b>{item.responseRate}</b>
                        </p>
                      </div>
                      <p>
                        <span>Main Products</span> <b>{item.mainProducts}</b>
                      </p>
                    </div>
                  </div>
                  <div className="caction">
                    <Link to={"/register-buyer"}>
                      <p>Contact Supplier</p>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </main>
    </div>
  );
};

export default MustardOilStore;
