import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Catalog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faShieldHalved,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const products = [
  { id: 1, name: "Cabinet Handle", image: "./assets/sitar2.jpeg" },
  {
    id: 3,
    name: "Door Handles",
    image: "./assets/sitar1.jpeg",
  },
  {
    id: 5,
    name: "Alluminium Kuthi",
    image: "./assets/sitar3.jpeg",
  },
  {
    id: 6,
    name: "Door Handles",
    image: "./assets/sitar6.jpeg",
  },
  { id: 1, name: "Cancel Handle & Knob", image: "./assets/sitar4.jpeg" },
  {
    id: 3,
    name: "Cabinet Handles",
    image: "./assets/sitar7.jpeg",
  },
  {
    id: 5,
    name: "Curtain Brackets & Support",
    image: "./assets/sitar5.jpeg",
  },
  {
    id: 6,
    name: "Door Handles",
    image: "./assets/sitar1.jpeg",
  },
];

const TwentySevenCatalogs = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [highlight, setHighlight] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleReachEnd = () => {
    if (swiperInstance) {
      const isEnd = swiperInstance.isEnd;
      const navigationButton = document.querySelector(".swiper-button-next");
      if (navigationButton) {
        navigationButton.style.display = isEnd ? "none" : "block";
      }
    }
  };

  const handleReachStart = () => {
    if (swiperInstance) {
      const isBeginning = swiperInstance.isBeginning;
      const navigationButton = document.querySelector(".swiper-button-prev");
      if (navigationButton) {
        navigationButton.style.display = isBeginning ? "none" : "block";
      }
    }
  };

  const handleProductClick = () => {
    setActiveSection("products");
  };

  const renderContent = () => {
    switch (activeSection) {
      case "products":
        return (
          <div className="swiper-container">
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              onSwiper={setSwiperInstance}
              onReachEnd={handleReachEnd}
              onReachBeginning={handleReachStart}
              breakpoints={{
                640: { slidesPerView: 2, spaceBetween: 20 },
                768: { slidesPerView: 4, spaceBetween: 40 },
                1024: { slidesPerView: 7, spaceBetween: 30 },
              }}
              className="mySwiper"
            >
              {products.map((product) => (
                <SwiperSlide key={`product-${product.id}`}>
                  <div className="card" onClick={handleProductClick}>
                    <img src={product.image} alt={product.name} />
                    <p>{product.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="welcome">
              <h1>Our Products</h1>

              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets/sitar5.jpeg"
                      title="Heavy Curtain Support"
                      alt="Heavy Curtain Support"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Heavy Curtain Support</h3>
                    </div>
                    <div className="pro_det_sec">
                      <li>
                        <span>Product:</span> Heavy Curtain Support (CP, Anq,
                        Gold, Rose Gold, Coco, Ma Black)
                      </li>
                      <li>
                        <span>Weight Options:</span> 56gm, 60gm, 75gm, 80gm per
                        piece
                      </li>
                      <li>
                        <span>Availability:</span> In Stock
                      </li>
                      <li>
                        <span>Description:</span> Our heavy curtain support is
                        designed to provide excellent support for curtains while
                        maintaining a sleek and stylish appearance. Made from
                        high-quality aluminum, these supports are perfect for
                        use with 1-inch curtain rods. They are available in a
                        range of elegant finishes such as CP, Anq, Gold, Rose
                        Gold, Coco, and Ma Black.
                      </li>
                      <li>
                        <span>Key Benefits:</span>
                        <ul>
                          <li>
                            <span>Heavy Duty:</span> Crafted from durable
                            aluminum for superior strength and support.
                          </li>
                          <li>
                            <span>Versatile Designs:</span> Available in a
                            variety of finishes to suit different decor styles.
                          </li>
                          <li>
                            <span>Weight Options:</span> Available in 56gm,
                            60gm, 75gm, and 80gm, providing flexibility
                            depending on curtain weight.
                          </li>
                          <li>
                            <span>Easy Installation:</span> Simple to install on
                            1-inch curtain rods, ensuring secure placement for
                            heavy curtains.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>Usage:</span> Ideal for supporting heavy curtains
                        in residential and commercial spaces.
                      </li>
                      <li>
                        <span>Minimum Order Quantity:</span> 500 pairs
                      </li>
                      <li>
                        <span>Contact Us:</span> Contact us today to learn more
                        about our heavy curtain support and place your order.
                      </li>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets/sitar7.jpeg"
                      title="Product from Sitaram Enterprises"
                      alt="Product from Sitaram Enterprises"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Product from Sitaram Enterprises</h3>
                    </div>
                    <div className="pro_det_sec">
                      <li>
                        <span>Product:</span> Available in CP, TT, Rose Gold,
                        Gold, Black
                      </li>
                      <li>
                        <span>Availability:</span> Available for Bulk Orders
                      </li>
                      <li>
                        <span>Description:</span> This high-quality product is
                        offered by Sitaram Enterprises and is available in a
                        variety of elegant finishes such as CP, TT, Rose Gold,
                        Gold, and Black. Perfect for bulk orders, this product
                        provides both durability and style for your specific
                        needs.
                      </li>
                      <li>
                        <span>Key Benefits:</span>
                        <ul>
                          <li>
                            <span>Variety of Finishes:</span> Choose from CP,
                            TT, Rose Gold, Gold, or Black to match your style
                            preferences.
                          </li>
                          <li>
                            <span>Bulk Orders:</span> Ideal for bulk purchases,
                            making it a cost-effective solution for large
                            projects.
                          </li>
                          <li>
                            <span>Quality and Durability:</span> Manufactured to
                            ensure longevity and strength for various
                            applications.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>Usage:</span> Suitable for a wide range of uses,
                        perfect for residential or commercial applications.
                      </li>
                      <li>
                        <span>Contact Us:</span> Available at Sitaram
                        Enterprises for bulk orders. Contact us to place your
                        order and discover more about this product.
                      </li>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
      case "about":
        return (
          <div className="about-us-container">
            <div className="intro"></div>
            <div className="company-info">
              <h1>Welcome to in Our Company</h1>
              <h2>About Us</h2>
              <p>
                Sitaram Enterprise, based in Rajkot, Gujarat, we specialize in
                delivering top-tier stainless steel products designed for
                durability and style. Our diverse range includes high-quality
                door handles, stylish curtain brackets, sophisticated cabinet
                handles, and sturdy cloth hangers. With a commitment to
                excellence, we employ advanced technology and stringent quality
                control to ensure that every product meets the highest
                standards. Our goal is to provide our clients with superior
                products that enhance both functionality and aesthetics. Our
                sophisticated cabinet handles are designed to add a touch of
                refinement to your cabinetry, and our sturdy cloth hangers are
                built to withstand daily wear and tear. Each product is
                manufactured using advanced technology and stringent quality
                control measures to ensure they meet the highest standards of
                performance and longevity.
              </p>
              <p>
                To be the leading provider of premium stainless steel products
                in India, recognized for our commitment to quality, innovation,
                and sustainability. We envision a future where our products
                enhance everyday living, combining functionality with elegant
                design, and contributing to a more stylish and efficient world.
              </p>
              {/* <h3>All Kind of  We Do</h3> */}
              <p>
                Sitaram Enterprise, our mission is to deliver high-quality
                stainless steel products that cater to the diverse needs of our
                clients. We strive for excellence by utilizing advanced
                technology and rigorous quality control measures, ensuring our
                products stand the test of time.
              </p>
              <p>
                Sitaram Enterprise, located in Rajkot, Gujarat, excels in
                high-quality stainless steel products. They offer a wide range,
                including durable door handles, elegant curtain brackets,
                sophisticated cabinet handles, and sturdy cloth hangers.
              </p>
              Sitaram Enterprise, located in Rajkot, Gujarat, excels in
              high-quality stainless steel products. They offer a wide range,
              including durable door handles, elegant curtain brackets,
              sophisticated cabinet handles, and sturdy cloth hangers.
              <div className="video-container">
                <img src="./assets/ce-krishboxaraa Fashion.jpg" alt="" />
              </div>
            </div>
          </div>
        );
      case "contact":
        return (
          <div>
            {" "}
            <div className="contact-info">
              <h2>Contact Us</h2>
              <p>
                <strong>Address:</strong>
                Virani Aghat, Street Of Valambhiya Body Builders, Behind Balaji
                Refrigerator, Dhebar Road, Rajkot-360002
              </p>
              <p>Contact Person : Mr Nikhil Desai ji </p>
              {/* <p><strong>Contact Person:</strong>Mr. Rohit Patel</p> */}
            </div>
            <div className="map-container">
              <h3>Our Location</h3>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14770.915464502483!2d70.78404119897952!3d22.250363818907807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959ca85c9caed73%3A0x8b163eea77c4174b!2s7R22%2B4XH%20virani%20aghat%2C%20Dhebar%20Rd%2C%20Kotharoya%2C%20Bhakti%20Nagar%2C%20Rajkot%2C%20Gujarat%20360002!5e0!3m2!1sen!2sin!4v1736268400146!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        );
      default:
        return (
          <div>
            <div className="swiper-container">
              <Swiper
                modules={[Autoplay, Navigation, Pagination]}
                slidesPerView={1}
                spaceBetween={10}
                pagination={{ clickable: true }}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onSwiper={setSwiperInstance}
                onReachEnd={handleReachEnd}
                onReachBeginning={handleReachStart}
                breakpoints={{
                  640: { slidesPerView: 2, spaceBetween: 20 },
                  768: { slidesPerView: 4, spaceBetween: 40 },
                  1024: { slidesPerView: 7, spaceBetween: 30 },
                }}
                className="mySwiper"
              >
                {products.map((product) => (
                  <SwiperSlide key={`product-${product.id}`}>
                    <div className="card" onClick={handleProductClick}>
                      <img src={product.image} alt={product.name} />
                      <p>{product.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="welcome">
              <h1>Welcome to the Sitaram Enterprise</h1>
              <p>
                Sitaram Enterprise, based in Rajkot, Gujarat, we specialize in
                delivering top-tier stainless steel products designed for
                durability and style. Our diverse range includes high-quality
                door handles, stylish curtain brackets, sophisticated cabinet
                handles, and sturdy cloth hangers. With a commitment to
                excellence, we employ advanced technology and stringent quality
                control to ensure that every product meets the highest
                standards. Our goal is to provide our clients with superior
                products that enhance both functionality and aesthetics.
              </p>

              <p>
                Our display counters are crafted with precision, utilizing the
                finest materials to ensure durability and aesthetics go hand in
                hand.When you choose our display counters, you’re investing in a
                reliable solution that will consistently perform, leaving you
                worry-free.Your vision is our mission. We offer customization
                options that allow you to create a display counter that
                resonates with your business persona.
              </p>

              <p>
                High-Quality Craftsmanship: Each display counter is meticulously
                crafted with premium materials, ensuring durability and a
                polished, professional look. Cost-Effective: Our products
                provide exceptional quality at competitive prices, giving you
                great value without compromising on quality.
              </p>
              {/* <h2>High-Quality Fabrics: Premium Kalyani cotton and silk for comfort and durability.:</h2> */}

              <p>
                Sitaram Enterprise, we pledge to uphold the highest standards of
                quality in every product we deliver. Our commitment to
                excellence is unwavering, as we meticulously design and
                manufacture stainless steel products that not only meet but
                exceed industry expectations. Each item reflects our dedication
                to craftsmanship, ensuring that our clients receive nothing but
                the best.
              </p>
              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets/sitar1.jpeg"
                      title="Stainless Steel Round Door Handles"
                      alt="Stainless Steel Round Door Handles"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Stainless Steel Round Door Handles</h3>
                    </div>
                    <div className="pro_det_sec">
                      <li>
                        <span>Product:</span> Round Door Handles (Gold, Rose
                        Gold, Black San CP, Black CP, Black R.G, Black Gold)
                      </li>
                      <li>
                        <span>Availability:</span> In Stock
                      </li>
                      <li>
                        <span>Description:</span> These round door handles are
                        crafted from high-quality stainless steel for durability
                        and resistance to wear. With a sleek nickel finish and a
                        chrome overlay, they offer both functionality and a
                        modern aesthetic. Ideal for use on exterior doors, these
                        handles combine strength and style, making them a
                        practical choice for various applications.
                      </li>
                      <li>
                        <span>Key Benefits:</span>
                        <ul>
                          <li>
                            <span>High Durability:</span> Made from premium
                            stainless steel to ensure longevity and resistance
                            to wear.
                          </li>
                          <li>
                            <span>Modern Aesthetic:</span> Features a sleek
                            nickel finish with a chrome overlay for a
                            sophisticated look.
                          </li>
                          <li>
                            <span>Versatile Design:</span> Suitable for a
                            variety of door types, especially exterior doors.
                          </li>
                          <li>
                            <span>Easy to Install:</span> Simple installation
                            process for both residential and commercial use.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>Usage:</span> Perfect for exterior doors,
                        providing strength and style for homes and businesses
                        alike.
                      </li>
                      <li>
                        <span>Contact Us:</span> Reach out today to learn more
                        about our stainless steel round door handles and
                        discover how they can enhance your doors' functionality
                        and aesthetic appeal.
                      </li>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="company_header">
        <div className="top_head">
          <div className="company_left zdasds">
            <span className="logo_img2" style={{ background: "#d1d786" }}>
              SE
            </span>

            <div className="company_info">
              <div className="company_name2">
                <h1>
                  Sitaram Enterprise{" "}
                  <div className="_oth_info">
                    <a
                      href="https://www.google.co.in/maps/dir//12.9715987,77.5945627"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <b className="fa fa-map-marker" />
                        Dhebar Road, Rajkot, India{" "}
                      </span>
                    </a>
                  </div>
                </h1>
              </div>
            </div>
          </div>
          {/* <div className="company_right">
                        <Link to="tel:9327973636" className="contact_link">
                            <FontAwesomeIcon icon={faGauge} /> Contact Supplier
                        </Link>
                    </div> */}
        </div>
        <div className="bottom_head">
          <div className={`Premium-Header ${highlight ? "highlight" : ""}`}>
            <ul className="menu_sec">
              <li
                className={activeSection === "home" ? "active" : ""}
                onClick={() => setActiveSection("home")}
              >
                Home <FontAwesomeIcon icon={faGauge} />
              </li>
              <li
                className={activeSection === "products" ? "active" : ""}
                onClick={() => setActiveSection("products")}
              >
                Products <FontAwesomeIcon icon={faShieldHalved} />
              </li>
              <li
                className={activeSection === "about" ? "active" : ""}
                onClick={() => setActiveSection("about")}
              >
                About Us <FontAwesomeIcon icon={faMessage} />
                <ul className="dropdown"></ul>
              </li>
              <li
                className={activeSection === "contact" ? "active" : ""}
                onClick={() => setActiveSection("contact")}
              >
                Contact Us <FontAwesomeIcon icon={faShieldHalved} />
                <ul className="dropdown"></ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {renderContent()}
    </>
  );
};

export default TwentySevenCatalogs;
