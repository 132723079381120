import React, { useState, useEffect } from "react";
import "./OnionBuy.css"; // Assuming the same CSS file can be used, otherwise create a new CSS file.
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import Whyglobal from "../Components/Whyglobal";

const GrapesBuys = [
  {
    name: "Ravi Kumar",
    email: "ravi.kumar@gmail.com",
    mobileNumber: "9876543210",
    lookingFor: "Looking for fresh green grapes in bulk.",
    locationandDate: "Nashik 20-01-2025",
    productOrService: "Green Grapes",
    quantity: 50,
    unit: "ton",
    __v: 0,
  },
  {
    name: "Simran Sharma",
    email: "simran.sharma@gmail.com",
    mobileNumber: "9823456789",
    lookingFor: "Bulk purchase of seedless grapes for juice processing.",
    locationandDate: "Pune 18-01-2025",
    productOrService: "Seedless Grapes",
    quantity: 80,
    unit: "ton",
    __v: 0,
  },
  {
    name: "Ajay Singh",
    email: "ajay.singh@gmail.com",
    mobileNumber: "9123456780",
    lookingFor: "Need organic grapes for export purposes.",
    locationandDate: "Bangalore 17-01-2025",
    productOrService: "Organic Grapes",
    quantity: 60,
    unit: "ton",
    __v: 0,
  },
  {
    name: "Meera Patel",
    email: "meera.patel@gmail.com",
    mobileNumber: "9543216789",
    lookingFor: "Fresh grapes for wholesale distribution.",
    locationandDate: "Ahmedabad 15-01-2025",
    productOrService: "Fresh Grapes",
    quantity: 40,
    unit: "ton",
    __v: 0,
  },
  {
    name: "Karan Verma",
    email: "karan.verma@gmail.com",
    mobileNumber: "9012345678",
    lookingFor: "High-quality grapes for retail market.",
    locationandDate: "Delhi 14-01-2025",
    productOrService: "Table Grapes",
    quantity: 70,
    unit: "ton",
    __v: 0,
  },
  {
    name: "Priya Das",
    email: "priya.das@gmail.com",
    mobileNumber: "9654321890",
    lookingFor: "Black grapes in bulk for wine production.",
    locationandDate: "Hyderabad 13-01-2025",
    productOrService: "Black Grapes",
    quantity: 100,
    unit: "ton",
    __v: 0,
  },
  {
    name: "Rohan Gupta",
    email: "rohan.gupta@gmail.com",
    mobileNumber: "9876512345",
    lookingFor: "Need grapes for manufacturing dried raisins.",
    locationandDate: "Mumbai 12-01-2025",
    productOrService: "Green Grapes",
    quantity: 90,
    unit: "ton",
    __v: 0,
  },
];

const GrapesBuy = () => {
  const { userId } = useAuth();
  const [userIsPremium, setUserIsPremium] = useState(false);
  const [shownNumbers, setShownNumbers] = useState([]);
  const [leadsViewed, setLeadsViewed] = useState(0);

  useEffect(() => {
    if (!userId) return;

    // Load the viewed leads from local storage
    const storedViewedLeads =
      JSON.parse(localStorage.getItem(`grapesViewedLeads_${userId}`)) || [];
    setShownNumbers(storedViewedLeads);

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/getUserWithPremiumStatus/${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserIsPremium(data.isPremium);
        setLeadsViewed(data.leadsViewed);
        console.log("Premium Status:", data.isPremium);
      })
      .catch((error) => {
        console.error("Error fetching user information:", error);
      });
  }, [userId]);

  const toggleNumber = async (index) => {
    if (!userIsPremium) return; // Only premium users can see numbers

    if (leadsViewed >= 25) {
      alert("You have reached the limit of 25 leads viewed per month.");
      return;
    }

    if (shownNumbers.includes(index)) return; // If already shown, do nothing

    const updatedShownNumbers = [...shownNumbers, index];
    setShownNumbers(updatedShownNumbers);

    // Update local storage
    localStorage.setItem(
      `grapesViewedLeads_${userId}`,
      JSON.stringify(updatedShownNumbers)
    );

    const buyer = GrapesBuys[index]; // Get the buyer information

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/incrementLeadsViewed/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            buyerName: buyer.name,
            mobileNo: buyer.mobileNumber,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLeadsViewed(data.leadsViewed);
      } else {
        const errorData = await response.json();
        console.error("Error incrementing leads viewed:", errorData.error);
      }
    } catch (error) {
      console.error("Error incrementing leads viewed:", error.message);
    }
  };

  return (
    <>
      <div className="container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"/"}>Home</Link>
          </li>
          <li>/</li>
          <li className="breadcrumb-item active">Grapes Leads</li>
        </ol>
      </div>
      <div className="containersx">
        <div className="buyers">
          {GrapesBuys.map((buyer, index) => (
            <div key={index} className="buyer-card">
              <h2 style={{ color: "green" }}>{buyer.lookingFor}</h2>
              <p>
                <strong>Buyer Name:</strong> {buyer.name}
              </p>
              <p>
                <strong>Quantity:</strong> {buyer.quantity} {buyer.unit}
              </p>
              <p>
                <strong>Location & Date:</strong> {buyer.locationandDate}
              </p>
              <p>
                <strong>Mobile No.:</strong>{" "}
                {userIsPremium
                  ? shownNumbers.includes(index)
                    ? buyer.mobileNumber
                    : "********"
                  : "********"}
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                {userIsPremium && (
                  <button
                    className="detail-btn"
                    style={{
                      padding: "5px 10px",
                      background: "#f7f1f1",
                      color: "orange",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleNumber(index)}
                  >
                    Click to see
                  </button>
                )}
              </div>
              <div className="btns-imp">
                <Link to={"/login"}>
                  <button className="detailing-btn">View More</button>
                </Link>
                <Link to={"/register-Company"}>
                  <button className="regis-btn">Register Now</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="sidebar">
          <div className="mains-slide">
            <div className="tk23-hkCont_onergt">
              <div className="tk23-hkCont_premserv__box">
                <h1 className="tk23-hkCont_premserv__ttl">
                  Our Membership Plans
                </h1>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Standard Plan
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Advance Plan
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Premium Plan
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Whyglobal />
      </div>
    </>
  );
};

export default GrapesBuy;
