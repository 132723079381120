import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./FreeCataloge.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faShieldHalved,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const products = [
  { id: 1, name: "Plastic Chairs", image: "./assets/plastic1.jpg" },
  { id: 3, name: "Plastic Tables", image: "./assets/plastic2.jpg" },
  { id: 5, name: "Plastic Storage", image: "./assets/plastic3.jpg" },
  { id: 6, name: "Plastic Containers", image: "./assets/plastic4.jpg" },
  { id: 1, name: "Plastic Furniture", image: "./assets/plastic-fur.jpg" },
  { id: 3, name: "Toilet Paper Holder", image: "./assets/plastic6.jpg" },
  { id: 5, name: "Plastic Tables", image: "./assets/plastic1.jpg" },
  { id: 6, name: "Plastic Chairs", image: "./assets/plastic2.jpg" },
];

const RjPlastCatalog = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [highlight, setHighlight] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleReachEnd = () => {
    if (swiperInstance) {
      const isEnd = swiperInstance.isEnd;
      const navigationButton = document.querySelector(".swiper-button-next");
      if (navigationButton) {
        navigationButton.style.display = isEnd ? "none" : "block";
      }
    }
  };

  const handleReachStart = () => {
    if (swiperInstance) {
      const isBeginning = swiperInstance.isBeginning;
      const navigationButton = document.querySelector(".swiper-button-prev");
      if (navigationButton) {
        navigationButton.style.display = isBeginning ? "none" : "block";
      }
    }
  };

  const handleProductClick = () => {
    setActiveSection("products");
  };

  const renderContent = () => {
    switch (activeSection) {
      case "products":
        return (
          <div className="swiper-container">
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              onSwiper={setSwiperInstance}
              onReachEnd={handleReachEnd}
              onReachBeginning={handleReachStart}
              breakpoints={{
                640: { slidesPerView: 2, spaceBetween: 20 },
                768: { slidesPerView: 4, spaceBetween: 40 },
                1024: { slidesPerView: 7, spaceBetween: 30 },
              }}
              className="mySwiper"
            >
              {products.map((product) => (
                <SwiperSlide key={`product-${product.id}`}>
                  <div className="card" onClick={handleProductClick}>
                    <img src={product.image} alt={product.name} />
                    <p>{product.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        );

      default:
        return (
          <div>
            <div className="swiper-container">
              <Swiper
                modules={[Autoplay, Navigation, Pagination]}
                slidesPerView={1}
                spaceBetween={10}
                pagination={{ clickable: true }}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onSwiper={setSwiperInstance}
                onReachEnd={handleReachEnd}
                onReachBeginning={handleReachStart}
                breakpoints={{
                  640: { slidesPerView: 2, spaceBetween: 20 },
                  768: { slidesPerView: 4, spaceBetween: 40 },
                  1024: { slidesPerView: 7, spaceBetween: 30 },
                }}
                className="mySwiper"
              >
                {products.map((product) => (
                  <SwiperSlide key={`product-${product.id}`}>
                    <div className="card">
                      <img src={product.image} alt={product.name} />
                      <p>{product.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="welcome">
              <h1>Welcome to the Rajasthan Plastic Syndicate Company</h1>
              <p>
                Rajasthan Plastic Syndicate is a trusted name in the
                manufacturing and supply of high-quality plastic containers and
                bottles. Based in Rajasthan, we have earned a reputation for
                delivering innovative, durable, and eco-friendly packaging
                solutions to meet the diverse needs of industries such as food
                and beverages, pharmaceuticals, cosmetics, household products,
                and more.{" "}
              </p>
              <h2>We Deals in All kind of Plastic Products</h2>
              <p>
                Our product portfolio includes a wide range of poly packs, such
                as packaging bags, pouches, rolls, and liners, suitable for
                industries like agriculture, food and beverage, pharmaceuticals,
                retail, and more. Crafted from high-grade plastic materials, our
                products ensure superior strength, flexibility, and resistance
                to wear and tear, making them ideal for both lightweight and
                heavy-duty applications.
              </p>
              {/* <h2>Our Products :</h2> */}
              <p>
                Rajasthan Plastic Syndicate, we pride ourselves on offering
                customizable solutions to match your branding and packaging
                needs. From size and thickness to color and printing, our
                products can be tailored to reflect your brand identity while
                ensuring optimal functionality. Sustainability is at the heart
                of our operations. We are committed to eco-friendly
                manufacturing practices and offer recyclable options, helping
                our clients contribute to a greener future without compromising
                on quality.
              </p>
              <p>
                With a dedicated team and advanced production facilities, we
                ensure timely delivery, competitive pricing, and exceptional
                service. Over the years, we have earned the trust of our clients
                by consistently providing reliable and innovative packaging
                solutions. Choose Rajasthan Plastic Syndicate for all your
                plastic packaging needs and experience the perfect combination
                of quality, efficiency, and sustainability. Let us help you pack
                your products with excellence.
              </p>
              {/* <p>Unmatched Expertise
                                With years of experience in the elevator industry, our skilled team brings unparalleled knowledge and proficiency to every project.</p> */}

              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets/plastic-rack.jpg"
                      title="Kashmiri Saffron Threads"
                      alt="Kashmiri Saffron Threads"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Stainless Steel Triangle Shelf</h3>
                    </div>
                    <div className="pro_det_sec">
                      <ul className="attributes">
                        <li>
                          <span>Brand:</span> Rajasthan Plastic Syndicate
                        </li>
                        <li>
                          <span>Product:</span> Stainless Steel Triangle Shelf
                        </li>
                        <li>
                          <span>Material:</span> Crafted from durable,
                          corrosion-resistant stainless steel, designed for
                          longevity and reliability
                        </li>
                        <li>
                          <span>Shape:</span> Triangle - Optimally designed to
                          utilize corner spaces efficiently, perfect for compact
                          areas
                        </li>
                        <li>
                          <span>Size:</span> 7 inches in width - Spacious enough
                          to store toiletries, kitchen essentials, or decorative
                          items with ease
                        </li>
                        <li>
                          <span>Number of Shelves:</span> 1 - A practical
                          single-shelf design, adaptable for various
                          applications
                        </li>
                        <li>
                          <span>Color:</span> Elegant silver finish - Enhances
                          sophistication while seamlessly blending with any
                          interior style
                        </li>
                        <li>
                          <span>Mounting Type:</span> Wall-mounted - Easy to
                          install, sturdy, and secure with included mounting
                          hardware
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="company_header">
        <div className="top_head">
          <div className="company_left zdasds">
            <span className="logo_img2" style={{ background: "#d1d786" }}>
              CF
            </span>

            <div className="company_info">
              <div className="company_name2">
                <h1>
                  Rajasthan Plastic Syndicate{" "}
                  <div className="_oth_info">
                    <a
                      href="https://www.google.co.in/maps/dir//12.9715987,77.5945627"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <b className="fa fa-map-marker" />
                        Nagpur, India{" "}
                      </span>
                    </a>
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_head">
          <div className={`Premium-Header ${highlight ? "highlight" : ""}`}>
            <ul className="menu_sec">
              <li>
                Home <FontAwesomeIcon icon={faGauge} />
              </li>
              <li>
                Products <FontAwesomeIcon icon={faShieldHalved} />
              </li>
              <li>
                About Us <FontAwesomeIcon icon={faMessage} />
                <ul className="dropdown"></ul>
              </li>
              <li>
                Contact Us <FontAwesomeIcon icon={faShieldHalved} />
                <ul className="dropdown"></ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {renderContent()}
    </>
  );
};

export default RjPlastCatalog;
