import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "./catet.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const MaincountryCateg = () => {
  return (
    <>
      <nav id="category-slide" className="nav">
        <div className="container">
          <ul className="full-nav">
            <li className="item fnav-icon-wrap">
              <span className="cp nav-item fnav-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width={19}
                  height={18}
                >
                  <path d="M8 4H21V6H8V4ZM4.5 6.5C3.67157 6.5 3 5.82843 3 5C3 4.17157 3.67157 3.5 4.5 3.5C5.32843 3.5 6 4.17157 6 5C6 5.82843 5.32843 6.5 4.5 6.5ZM4.5 13.5C3.67157 13.5 3 12.8284 3 12C3 11.1716 3.67157 10.5 4.5 10.5C5.32843 10.5 6 11.1716 6 12C6 12.8284 5.32843 13.5 4.5 13.5ZM4.5 20.4C3.67157 20.4 3 19.7284 3 18.9C3 18.0716 3.67157 17.4 4.5 17.4C5.32843 17.4 6 18.0716 6 18.9C6 19.7284 5.32843 20.4 4.5 20.4ZM8 11H21V13H8V11ZM8 18H21V20H8V18Z" />
                </svg>{" "}
                All Categories
              </span>
              <div className="top-nav">
                <ul className="top-nav-links">
                  <li>
                    {/* <a href="/"> */}
                    <Link to="#">
                      <h4>agriculture</h4>{" "}
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>

                    <div className="hover-links">
                      <div className="row">
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Fruits</Link>
                            </li>
                            <li>
                              <Link to="#">Apple</Link>
                            </li>
                            <li>
                              <Link to="#">Banana</Link>
                            </li>
                            <li>
                              <Link to="#">Grapes</Link>
                            </li>
                            <li>
                              <Link to="#">Watermelon</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Flowers, Plants & Trees</Link>
                            </li>
                            <li>
                              <Link to="#">
                                Jasmine
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Tulip
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Hibiscus
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Gardenia
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Wheat</Link>
                            </li>
                            <li>
                              <Link to="#">pulses</Link>
                            </li>
                            <li>
                              <Link to="#">Yellow Corn</Link>
                            </li>
                            <li>
                              <Link to="#">Maize</Link>
                            </li>
                            <li>
                              <Link to="#">Wheat</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Rice</Link>
                            </li>
                            <li>
                              <Link to="#">Arborio Rice</Link>
                            </li>
                            <li>
                              <Link to="#">Sona Masoori Rice</Link>
                            </li>
                            <li>
                              <Link to="#">Normal Rice</Link>
                            </li>
                            <li>
                              <Link to="#">Basmati Rice</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Farming Equipment & Supplies</Link>
                            </li>
                            <li>
                              <Link to="/">Jaw Crusher</Link>
                            </li>
                            <li>
                              <Link to="/">Harvesters</Link>
                            </li>
                            <li>
                              <Link to="/">Harrow</Link>
                            </li>
                            <li>
                              <Link to="/">Cultivators</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Organic Seed Varieties</Link>
                            </li>
                            <li>
                              <Link to="/">Sunflower Seeds</Link>
                            </li>
                            <li>
                              <Link to="/">Flax Seeds</Link>
                            </li>
                            <li>
                              <Link to="#">
                                Chia Seeds
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Mustard Seeds
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Vegetables</Link>
                            </li>
                            <li>
                              <Link to="/">Cabbage</Link>
                            </li>
                            <li>
                              <Link to="/">Spinach</Link>
                            </li>
                            <li>
                              <Link to="#">Onion</Link>
                            </li>
                            <li>
                              <Link to="#">Potato</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">
                                Agricultural Machinery & Components
                              </Link>
                            </li>
                            <li>
                              <Link to="#">Heavy-duty Farm Trailers</Link>
                            </li>
                            <li>
                              <Link to="#">Ergonomic Tractor Seats</Link>
                            </li>
                            <li>
                              <Link to="/">Compact Walking Tractors</Link>
                            </li>
                            <li>
                              <Link to="#">Durable Hitch Pins</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Sustainable # Solutions</Link>
                            </li>
                            <li>
                              <Link to="/">Regenerative Farming</Link>
                            </li>
                            <li>
                              <Link to="/">Water Conservation Practices</Link>
                            </li>
                            <li>
                              <Link to="/">Soil Health Analysis</Link>
                            </li>
                            <li>
                              <Link to="/">Eco-friendly Pest Management</Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="text-center">
                        <Link to="#" className="view-all">
                          View All Categories in #
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to="#">
                      <h4>Clothing and Fashion</h4>{" "}
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>

                    <div className="hover-links">
                      <div className="row">
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Footwear Collection</Link>
                            </li>
                            <li>
                              <Link to="/#">Stylish Boots</Link>
                            </li>
                            <li>
                              <Link to="/#">Comfortable Shoes</Link>
                            </li>
                            <li>
                              <Link to="/#">Sandals & Slippers</Link>
                            </li>
                            <li>
                              <Link to="/#">Casual Flip-Flops</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Everyday Casual Wear</Link>
                            </li>
                            <li>
                              <Link to="#">Denim Jeans</Link>
                            </li>
                            <li>
                              <Link to="#">Casual T-Shirts</Link>
                            </li>
                            <li>
                              <Link to="#">Casual Trousers</Link>
                            </li>
                            <li>
                              <Link to="#">Button-Down Shirts</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Intimate Wear & Nightwear</Link>
                            </li>
                            <li>
                              <Link to="#">Comfortable Bras</Link>
                            </li>
                            <li>
                              <Link to="#">Cotton Panties</Link>
                            </li>
                            <li>
                              <Link to="#">Sleeveless Vests</Link>
                            </li>
                            <li>
                              <Link to="#">Soft Night Suits</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Fashion Accessories Collection</Link>
                            </li>
                            <li>
                              <Link to="/">Stylish Sunglasses</Link>
                            </li>
                            <li>
                              <Link to="#">
                                Leather Wallets
                              </Link>
                            </li>
                            <li>
                              <Link to="#">Fashionable Belts</Link>
                            </li>
                            <li>
                              <Link to="#">Elegant Scarves</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Winter Wear Collection</Link>
                            </li>
                            <li>
                              <Link to="#">Cozy Shawls</Link>
                            </li>
                            <li>
                              <Link to="#">Warm Mufflers</Link>
                            </li>
                            <li>
                              <Link to="#">Sweaters</Link>
                            </li>
                            <li>
                              <Link to="#">Sweatshirts & Hoodies</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Ethnic Wear - Sarees</Link>
                            </li>
                            <li>
                              <Link to="#">Designer Sarees</Link>
                            </li>
                            <li>
                              <Link to="#">Silk Sarees</Link>
                            </li>
                            <li>
                              <Link to="#">Georgette Sarees</Link>
                            </li>
                            <li>
                              <Link to="#">Printed Sarees</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Women's Apparel & Accessories</Link>
                            </li>
                            <li>
                              <Link to="#">Stylish Blouses</Link>
                            </li>
                            <li>
                              <Link to="#">Lehenga & Choli Sets</Link>
                            </li>
                            <li>
                              <Link to="#">Cotton Tops & Tunics</Link>
                            </li>
                            <li>
                              <Link to="#">Salwar Suits & Sets</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">
                                Children's Apparel & Accessories
                              </Link>
                            </li>
                            <li>
                              <Link to="#">Kid's Formal Suits</Link>
                            </li>
                            <li>
                              <Link to="#">Trendy T-Shirts</Link>
                            </li>
                            <li>
                              <Link to="#">Comfortable Shorts</Link>
                            </li>
                            <li>
                              <Link to="#">Denim Jeans for Kids</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Men's Apparel & Accessories</Link>
                            </li>
                            <li>
                              <Link to="#">Casual T-Shirts</Link>
                            </li>
                            <li>
                              <Link to="#">Denim Jeans</Link>
                            </li>
                            <li>
                              <Link to="#">Formal Shirts</Link>
                            </li>
                            <li>
                              <Link to="#">Trousers & Pants</Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="text-center">
                        <Link to="#" className="view-all">
                          View All Categories in Apparel & Fashion
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to="#">
                      <h4>Chemicals</h4> <FontAwesomeIcon icon={faAngleRight} />{" "}
                    </Link>

                    <div className="hover-links">
                      <div className="row">
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Polymers & Resins</Link>
                            </li>
                            <li>
                              <Link to="/#">PVC Polymers</Link>
                            </li>
                            <li>
                              <Link to="#">Epoxy Polymers</Link>
                            </li>
                            <li>
                              <Link to="#">Polyester Polymers</Link>
                            </li>
                            <li>
                              <Link to="#">Phenolic Polymers</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Chemical Processing Equipment</Link>
                            </li>
                            <li>
                              <Link to="/">Industrial Reactors</Link>
                            </li>
                            <li>
                              <Link to="/">Storage Tanks</Link>
                            </li>
                            <li>
                              <Link to="/">Pressure Tanks</Link>
                            </li>
                            <li>
                              <Link to="/">Mixing Systems</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Chemical Raw Materials</Link>
                            </li>
                            <li>
                              <Link to="/">Calcium Compounds</Link>
                            </li>
                            <li>
                              <Link to="/">Iron Compounds</Link>
                            </li>
                            <li>
                              <Link to="/">Mercury Compounds</Link>
                            </li>
                            <li>
                              <Link to="/">Methyl Compounds</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Advanced Chemicals</Link>
                            </li>
                            <li>
                              <Link to="/">Baking Agents</Link>
                            </li>
                            <li>
                              <Link to="/">Preservatives</Link>
                            </li>
                            <li>
                              <Link to="/">Lab Reagents</Link>
                            </li>
                            <li>
                              <Link to="/">Cleaning Agents</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Coatings & Paints</Link>
                            </li>
                            <li>
                              <Link to="#">Solvents</Link>
                            </li>
                            <li>
                              <Link to="#">Protective Coatings</Link>
                            </li>
                            <li>
                              <Link to="#">Aerosol Paints</Link>
                            </li>
                            <li>
                              <Link to="paints/">Clay-Based Products</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Pigments & Dyes</Link>
                            </li>
                            <li>
                              <Link to="/">Colorant Dyes</Link>
                            </li>
                            <li>
                              <Link to="/">Fabric Dyes</Link>
                            </li>
                            <li>
                              <Link to="/">Reactive Colorants</Link>
                            </li>
                            <li>
                              <Link to="/">Pigment Powders</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Agricultural Chemicals</Link>
                            </li>
                            <li>
                              <Link to="/">Soil Nutrients</Link>
                            </li>
                            <li>
                              <Link to="/">Pesticides</Link>
                            </li>
                            <li>
                              <Link to="/">Nitrogen Fertilizers</Link>
                            </li>
                            <li>
                              <Link to="/">Organic Additives</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Chemical Production Machinery</Link>
                            </li>
                            <li>
                              <Link to="/">Crystallization Units</Link>
                            </li>
                            <li>
                              <Link to="/">Compression Equipment</Link>
                            </li>
                            <li>
                              <Link to="/">Centrifuge Units</Link>
                            </li>
                            <li>
                              <Link to="/">Distillation Equipment</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Acids & Solutions</Link>
                            </li>
                            <li>
                              <Link to="/">Hydrochloric Solutions</Link>
                            </li>
                            <li>
                              <Link to="/">Phosphoric Solutions</Link>
                            </li>
                            <li>
                              <Link to="/">Amino Compounds</Link>
                            </li>
                            <li>
                              <Link to="/">Lead-Based Solutions</Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="text-center">
                        <Link to="#" className="view-all">
                          View All Categories in Chemicals
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to="#">
                      <h4>Gifts &amp; Crafts</h4>{" "}
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>

                    <div className="hover-links">
                      <div className="row">
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Beaded Crafts</Link>
                            </li>
                            <li>
                              <Link to="/">Decorative Beads</Link>
                            </li>
                            <li>
                              <Link to="/">Geometric Beads</Link>
                            </li>
                            <li>
                              <Link to="#">Gemstone Beads</Link>
                            </li>
                            <li>
                              <Link to="/">Beaded Artworks</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Candle Crafts</Link>
                            </li>
                            <li>
                              <Link to="#">Candle Holders</Link>
                            </li>
                            <li>
                              <Link to="#">Decorative Candle Lamps</Link>
                            </li>
                            <li>
                              <Link to="#">Gel-Based Candles</Link>
                            </li>
                            <li>
                              <Link to="#">Candle Stands</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Ceramic Art</Link>
                            </li>
                            <li>
                              <Link to="/">Ceramic Bongs</Link>
                            </li>
                            <li>
                              <Link to="/">Ceramic Dishware</Link>
                            </li>
                            <li>
                              <Link to="/">Ceramic Glassware</Link>
                            </li>
                            <li>
                              <Link to="/">Ceramic Pottery</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Gift Items</Link>
                            </li>
                            <li>
                              <Link to="/">Branded Gifts</Link>
                            </li>
                            <li>
                              <Link to="/">Corporate Gifts</Link>
                            </li>
                            <li>
                              <Link to="/">Gift Accessories</Link>
                            </li>
                            <li>
                              <Link to="#">Keepsakes</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Artworks & Sculptures</Link>
                            </li>
                            <li>
                              <Link to="#">Canvas Art</Link>
                            </li>
                            <li>
                              <Link to="#">Figurines</Link>
                            </li>
                            <li>
                              <Link to="#">Religious Figures</Link>
                            </li>
                            <li>
                              <Link to="#">Artistic Sculptures</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Holiday Decorations</Link>
                            </li>
                            <li>
                              <Link to="/">Christmas Bag</Link>
                            </li>
                            <li>
                              <Link to="/">Christmas Gifts</Link>
                            </li>
                            <li>
                              <Link to="/">Christmas Ornaments</Link>
                            </li>
                            <li>
                              <Link to="/">Christmas Trees</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Wooden Crafts</Link>
                            </li>
                            <li>
                              <Link to="/arts">Wood-Framed Mirrors</Link>
                            </li>
                            <li>
                              <Link to="/arts">Wooden Decorations</Link>
                            </li>
                            <li>
                              <Link to="/arts">Wooden Figurines</Link>
                            </li>
                            <li>
                              <Link to="/arts">Carved Wood Art</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Brass Artifacts</Link>
                            </li>
                            <li>
                              <Link to="#">Brass Figurines</Link>
                            </li>
                            <li>
                              <Link to="#">Brass Incense Holders</Link>
                            </li>
                            <li>
                              <Link to="#">Brass Memorials</Link>
                            </li>
                            <li>
                              <Link to="#">Brass Ornaments</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Vintage Collectibles</Link>
                            </li>
                            <li>
                              <Link to="/">Historical Telescopes</Link>
                            </li>
                            <li>
                              <Link to="/">Nautical Instruments</Link>
                            </li>
                            <li>
                              <Link to="/">Antique Lanterns</Link>
                            </li>
                            <li>
                              <Link to="/">Field Glasses</Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="text-center">
                        <Link to="#" className="view-all">
                          View All Categories in Gifts & Crafts
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to={"#"}>
                      <h4>Furniture</h4> <FontAwesomeIcon icon={faAngleRight} />
                    </Link>

                    <div className="hover-links">
                      <div className="row">
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Vintage Desks</Link>
                            </li>
                            <li>
                              <Link to="#">Classic Lounge Chairs</Link>
                            </li>
                            <li>
                              <Link to="#">Antique Dressers</Link>
                            </li>
                            <li>
                              <Link to="#">Vintage Cabinets</Link>
                            </li>
                            <li>
                              <Link to="#">Classic Wardrobes</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Wooden Storage Units</Link>
                            </li>
                            <li>
                              <Link to="#">Wooden Side Tables</Link>
                            </li>
                            <li>
                              <Link to="#">Wooden Filing Cabinets</Link>
                            </li>
                            <li>
                              <Link to="#">Rustic Wooden Benches</Link>
                            </li>
                            <li>
                              <Link to="#">Wooden Bookcases</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Iron Display Racks</Link>
                            </li>
                            <li>
                              <Link to="/">Iron Bed Frames</Link>
                            </li>
                            <li>
                              <Link to="#">Metal Shelving Units</Link>
                            </li>
                            <li>
                              <Link to="#">Steel Workstations</Link>
                            </li>
                            <li>
                              <Link to="#">Brass Desk Lamps</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Retail Checkout Counters</Link>
                            </li>
                            <li>
                              <Link to="/">Promotional Display Stands</Link>
                            </li>
                            <li>
                              <Link to="/">Glass Display Cases</Link>
                            </li>
                            <li>
                              <Link to="/">Adjustable Display Racks</Link>
                            </li>
                            <li>
                              <Link to="/">Retail Shelving Units</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Sleek Kitchen Cabinets</Link>
                            </li>
                            <li>
                              <Link to="#">Stylish Coffee Tables</Link>
                            </li>
                            <li>
                              <Link to="#">Modern Recliners</Link>
                            </li>
                            <li>
                              <Link to="#">Designer Sectionals</Link>
                            </li>
                            <li>
                              <Link to="#">Contemporary Sofas</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Surgical Operating Tables</Link>
                            </li>
                            <li>
                              <Link to="#">School Furniture</Link>
                            </li>
                            <li>
                              <Link to="#">Laboratory Workstations</Link>
                            </li>
                            <li>
                              <Link to="#">Hospital Bedside Tables</Link>
                            </li>
                            <li>
                              <Link to="#">Medical Examination Tables</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Plastic Storage Bins</Link>
                            </li>
                            <li>
                              <Link to="#">Plastic Outdoor Tables</Link>
                            </li>
                            <li>
                              <Link to="#">Stackable Plastic Stools</Link>
                            </li>
                            <li>
                              <Link to="#">Molded Plastic Chairs</Link>
                            </li>
                            <li>
                              <Link to="#">Plastic Garden Furniture</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Sofa Armrests</Link>
                            </li>
                            <li>
                              <Link to="#">Adjustable Footrests</Link>
                            </li>
                            <li>
                              <Link to="#">Cushion Covers</Link>
                            </li>
                            <li>
                              <Link to="#">Recliner Mechanisms</Link>
                            </li>
                            <li>
                              <Link to="#">Chair Base Accessories</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Chair Cushions</Link>
                            </li>
                            <li>
                              <Link to="#">Drawer Mechanisms</Link>
                            </li>
                            <li>
                              <Link to="#">Adjustable Chair Bases</Link>
                            </li>
                            <li>
                              <Link to="#">Furniture Slides</Link>
                            </li>
                            <li>
                              <Link to="#">Chair Armrests</Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="text-center">
                        <Link to="#" className="view-all">
                          View All Categories in Furniture
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to={"# "}>
                      <h4>Food Products &amp; Beverage</h4>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>

                    <div className="hover-links">
                      <div className="row">
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Candy</Link>
                            </li>
                            <li>
                              <Link to="/">Chewy Toffees</Link>
                            </li>
                            <li>
                              <Link to="/">Crispy Wafers</Link>
                            </li>
                            <li>
                              <Link to="/">Fruity Lollipops</Link>
                            </li>
                            <li>
                              <Link to="/">Sweet Treats</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Cottage Cheese</Link>
                            </li>
                            <li>
                              <Link to="#">Cream</Link>
                            </li>
                            <li>
                              <Link to="#">Clarified Butter</Link>
                            </li>
                            <li>
                              <Link to="#">Fresh Milk</Link>
                            </li>
                            <li>
                              <Link to="#">Milk Products</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/teasupplier">Classic Tea</Link>
                            </li>
                            <li>
                              <Link to="/teasupplier">Green Tea</Link>
                            </li>
                            <li>
                              <Link to="/coffee">Instant Brew Coffee</Link>
                            </li>
                            <li>
                              <Link to="/coffee">Premium Coffee</Link>
                            </li>
                            <li>
                              <Link to="/coffee">Hot Beverages</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Extra Virgin Olive Oil</Link>
                            </li>
                            <li>
                              <Link to="/">Mustard Cooking Oil</Link>
                            </li>
                            <li>
                              <Link to="/">Refined Cooking Oil</Link>
                            </li>
                            <li>
                              <Link to="/">Sunflower Cooking Oil</Link>
                            </li>
                            <li>
                              <Link to="/">Cooking Oils</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Almonds</Link>
                            </li>
                            <li>
                              <Link to="#">Cashew Nuts</Link>
                            </li>
                            <li>
                              <Link to="#">Fresh Walnuts</Link>
                            </li>
                            <li>
                              <Link to="#">Juicy Raisins</Link>
                            </li>
                            <li>
                              <Link to="#">Nuts & Dried Fruits</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/#">Crushed Red Chilli</Link>
                            </li>
                            <li>
                              <Link to="/#">Flavoring Spices</Link>
                            </li>
                            <li>
                              <Link to="/#">Golden Turmeric</Link>
                            </li>
                            <li>
                              <Link to="/#">Ground Coriander</Link>
                            </li>
                            <li>
                              <Link to="/#">Tamarind Paste</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Brown Eggs</Link>
                            </li>
                            <li>
                              <Link to="#">Chicken</Link>
                            </li>
                            <li>
                              <Link to="#">Eggs</Link>
                            </li>
                            <li>
                              <Link to="#">Mutton</Link>
                            </li>
                            <li>
                              <Link to="#">Organic Brown Eggs</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Corn Shellers</Link>
                            </li>
                            <li>
                              <Link to="/">Maize Sheller</Link>
                            </li>
                            <li>
                              <Link to="/">Roti Makers</Link>
                            </li>
                            <li>
                              <Link to="/">Shawarma Grills</Link>
                            </li>
                            <li>
                              <Link to="/">Sugarcane Juicers</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Cold Brew Coffee</Link>
                            </li>
                            <li>
                              <Link to="/">Espresso</Link>
                            </li>
                            <li>
                              <Link to="/">Iced Coffee</Link>
                            </li>
                            <li>
                              <Link to="/">Mocha</Link>
                            </li>
                            <li>
                              <Link to="/">Vanilla Latte</Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="text-center">
                        <Link to="# " className="view-all">
                          View All Categories in Food Products & Beverage
                        </Link>
                      </div>
                    </div>
                  </li>

                  <li>
                    <Link to={"# "}>
                      <h4>Jewellery</h4> <FontAwesomeIcon icon={faAngleRight} />
                    </Link>

                    {/* <div className="hover-links">
                                            <div className="row">
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/gold-jewelry">
                                                                {" "}
                                                                Gold Jewelry{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/gold-rings">
                                                                {" "}
                                                                Gold Rings{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/gold-bangles">
                                                                {" "}
                                                                Gold Bangles{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/gold-earrings">
                                                                {" "}
                                                                Gold Earrings{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/gold-necklace">
                                                                {" "}
                                                                Gold Necklace{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/silver-jewelry">
                                                                {" "}
                                                                Silver Jewelry{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/silver-earrings-wholesale">
                                                                {" "}
                                                                Silver Earrings{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/silver-bracelets-wholesale">
                                                                {" "}
                                                                Silver Bracelets{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/silver-necklace-wholesale">
                                                                {" "}
                                                                Silver Necklace{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/silver-bangles-wholesale">
                                                                {" "}
                                                                Silver Bangles{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/diamond-jewelry">
                                                                {" "}
                                                                Diamond Jewelry{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/diamond-bangles">
                                                                {" "}
                                                                Diamond Bangles{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/diamond-bracelets">
                                                                {" "}
                                                                Diamond Bracelets{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/diamond-rings">
                                                                {" "}
                                                                Diamond Rings{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/diamond-necklaces">
                                                                {" "}
                                                                Diamond Necklaces{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/fashion-jewelry">
                                                                {" "}
                                                                Fashion Jewelry{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/wrist-bands">
                                                                {" "}
                                                                Wrist Bands{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/stone-bracelet">
                                                                {" "}
                                                                Stone Bracelet{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/pearl-bangle">
                                                                {" "}
                                                                Pearl Bangle{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/necklace-sets">
                                                                {" "}
                                                                Necklace Sets{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/beaded-jewelry">
                                                                {" "}
                                                                Beaded Jewelry{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/beaded-necklace">
                                                                {" "}
                                                                Beaded Necklace{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/beaded-earrings">
                                                                {" "}
                                                                Beaded Earrings{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/beaded-rings">
                                                                {" "}
                                                                Beaded Rings{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/beaded-bangles">
                                                                {" "}
                                                                Beaded Bangles{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/bangles">
                                                                {" "}
                                                                Bangles{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/designer-bangles">
                                                                {" "}
                                                                Designer Bangles{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/acrylic-bangles">
                                                                {" "}
                                                                Acrylic Bangles{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/metal-bangles">
                                                                {" "}
                                                                Metal Bangles{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/lac-bangle">
                                                                {" "}
                                                                Lac Bangles{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market#">
                                                                {" "}
                                                                Gemstones{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/rhinestone">
                                                                {" "}
                                                                Rhinestone{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/rose-quartz">
                                                                {" "}
                                                                Rose Quartz{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/emerald-stone">
                                                                {" "}
                                                                Emerald Stone{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/garnet-stone">
                                                                {" "}
                                                                Garnet Stone{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/jewelry-accessories">
                                                                {" "}
                                                                Jewelry Accessories{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/watch-boxes">
                                                                {" "}
                                                                Watch Boxes{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/bangle-boxes">
                                                                {" "}
                                                                Bangle Boxes{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/jewelry-box">
                                                                {" "}
                                                                Jewelry Box{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/silver-jewelry-box">
                                                                {" "}
                                                                Silver Jewelry Box{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/precious-stones-amp-gemstone-jewelry">
                                                                {" "}
                                                                Precious Stones &amp; Gemstone Jewelry{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/blue-sapphire">
                                                                {" "}
                                                                Blue Sapphire{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/quartz-stone">
                                                                {" "}
                                                                Quartz Stone{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/tourmaline-ring">
                                                                {" "}
                                                                Tourmaline Ring{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/kundan-necklaces">
                                                                {" "}
                                                                Kundan Necklaces{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <a
                                                    href="/"
                                                    className="view-all"
                                                >
                                                    View All Categories in Jewelry
                                                </a>
                                            </div>
                                        </div> */}

                    <div className="hover-links">
                      <div className="row">
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Artificial Diamond Jewelry</Link>
                            </li>
                            <li>
                              <Link to="/">Diamond Bracelets</Link>
                            </li>
                            <li>
                              <Link to="/">Diamond Earrings</Link>
                            </li>
                            <li>
                              <Link to="/">Diamond Necklaces</Link>
                            </li>
                            <li>
                              <Link to="/">Silver Jewelry</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Artificial Gemstones</Link>
                            </li>
                            <li>
                              <Link to="/">Diamond Stones</Link>
                            </li>
                            <li>
                              <Link to="/">Emerald Stone</Link>
                            </li>
                            <li>
                              <Link to="/">Garnet Stones</Link>
                            </li>
                            <li>
                              <Link to="/">Rose Quartz</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Bangle Boxes</Link>
                            </li>
                            <li>
                              <Link to="/">Jewelry Accessories</Link>
                            </li>
                            <li>
                              <Link to="/">Jewelry Box</Link>
                            </li>
                            <li>
                              <Link to="/">Silver Jewelry Box</Link>
                            </li>
                            <li>
                              <Link to="/">Watch Boxes</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Stunning Gold Earrings</Link>
                            </li>
                            <li>
                              <Link to="/">Luxury Gold Necklaces</Link>
                            </li>
                            <li>
                              <Link to="/">Elegant Gold Rings</Link>
                            </li>
                            <li>
                              <Link to="/">Designer Gold Bangles</Link>
                            </li>
                            <li>
                              <Link to="/">Classic Gold Jewelry</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Stylish Silver Bracelets</Link>
                            </li>
                            <li>
                              <Link to="/">Fine Silver Jewelry</Link>
                            </li>
                            <li>
                              <Link to="/">Exquisite Silver Necklaces</Link>
                            </li>
                            <li>
                              <Link to="/">Elegant Silver Earrings</Link>
                            </li>
                            <li>
                              <Link to="/">Classic Silver Bangles</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Tourmaline Ring</Link>
                            </li>
                            <li>
                              <Link to="/">Quartz Stone</Link>
                            </li>
                            <li>
                              <Link to="/">Kundan Necklaces</Link>
                            </li>
                            <li>
                              <Link to="/">Blue Sapphire</Link>
                            </li>
                            <li>
                              <Link to="#">
                                Precious Stones & Gemstone Jewelry
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Stone Bracelet</Link>
                            </li>
                            <li>
                              <Link to="/">Wrist Bands</Link>
                            </li>
                            <li>
                              <Link to="/">Pearl Bangle</Link>
                            </li>
                            <li>
                              <Link to="/">Fashion Jewelry</Link>
                            </li>
                            <li>
                              <Link to="/">Beaded Jewelry</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Rhinestone</Link>
                            </li>
                            <li>
                              <Link to="#">Rose Quartz</Link>
                            </li>
                            <li>
                              <Link to="#">Emerald Stone</Link>
                            </li>
                            <li>
                              <Link to="#">Garnet Stone</Link>
                            </li>
                            <li>
                              <Link to="#">Gemstones</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Jewelry Box</Link>
                            </li>
                            <li>
                              <Link to="/">Silver Jewelry Box</Link>
                            </li>
                            <li>
                              <Link to="/">Bangle Boxes</Link>
                            </li>
                            <li>
                              <Link to="/">Watch Boxes</Link>
                            </li>
                            <li>
                              <Link to="/">Jewelry Accessories</Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="text-center">
                        <Link to="/" className="view-all">
                          View All Categories in Jewelry
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    {/* <a href="/industry#">
                                            <h4>Health &amp; Beauty</h4>{" "}
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </a> */}
                    <Link to={"#"}>
                      <h4>Health &amp; Beauty</h4>{" "}
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>
                    {/* <div className="hover-links">
                                            <div className="row">
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/cosmetic-makeup-products">
                                                                {" "}
                                                                Cosmetic &amp; Makeup Products{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/bindis">
                                                                {" "}
                                                                Bindis{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/face-packs">
                                                                {" "}
                                                                Face Packs{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/rose-water">
                                                                {" "}
                                                                Rose Water{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/hair-extension">
                                                                {" "}
                                                                Hair Extension{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/baby-care-products">
                                                                {" "}
                                                                Baby Care Products{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/baby-oil">
                                                                {" "}
                                                                Baby Oil{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/baby-powder">
                                                                {" "}
                                                                Baby Powder{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/baby-walkers">
                                                                {" "}
                                                                Baby Walkers{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/baby-diapers">
                                                                {" "}
                                                                Baby Diapers{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/ayurvedic-products">
                                                                {" "}
                                                                Ayurvedic Products{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/amla-powder">
                                                                {" "}
                                                                Amla Powder{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/ayurvedic-oil">
                                                                {" "}
                                                                Ayurvedic Oil{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/ayurvedic-soap">
                                                                {" "}
                                                                Ayurvedic Soap{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/neem-products">
                                                                {" "}
                                                                Neem Products{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/beauty-equipment">
                                                                {" "}
                                                                Beauty Equipment{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/razor-blade">
                                                                {" "}
                                                                Razor Blade{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/tattoo-machine">
                                                                {" "}
                                                                Tattoo Machine{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/plastic-combs">
                                                                {" "}
                                                                Plastic Combs{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/hair-removal-machine">
                                                                {" "}
                                                                Hair Removal Machine{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/body-fragrances">
                                                                {" "}
                                                                Body Fragrance{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/attar">
                                                                {" "}
                                                                Attar{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/deodorants">
                                                                {" "}
                                                                Deodorants{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/perfume-set">
                                                                {" "}
                                                                Perfume Set{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/hair-fragrance">
                                                                {" "}
                                                                Hair Fragrance{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/eye-care-products">
                                                                {" "}
                                                                Eye Care Products{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/contact-lenses">
                                                                {" "}
                                                                Contact Lenses{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/optical-lens">
                                                                {" "}
                                                                Optical Lens{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/safety-goggles">
                                                                {" "}
                                                                Safety Goggles{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/spectacle-frames">
                                                                {" "}
                                                                Spectacle Frames{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/fitness-equipment">
                                                                {" "}
                                                                Fitness Equipment{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/body-massager">
                                                                {" "}
                                                                Body Massager{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/motorized-treadmill">
                                                                {" "}
                                                                Motorized Treadmill{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/morning-walker">
                                                                {" "}
                                                                Morning Walker{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/shoulder-press">
                                                                {" "}
                                                                Shoulder Press{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/pharmaceutical-machinery">
                                                                {" "}
                                                                Pharmaceutical Machinery{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/tablet-press">
                                                                {" "}
                                                                Tablet Press{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/vibro-sifter">
                                                                {" "}
                                                                Vibro Sifter{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/multi-mill">
                                                                {" "}
                                                                Multi Mill{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/octagonal-blender">
                                                                {" "}
                                                                Octagonal Blender{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/medical-equipment-amp-supplies">
                                                                {" "}
                                                                Medical Equipment &amp; Supplies{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/digital-thermometers">
                                                                {" "}
                                                                Digital Thermometers{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/surgical-instruments">
                                                                {" "}
                                                                Surgical Instruments{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/vertical-autoclave">
                                                                {" "}
                                                                Vertical Autoclave{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/x-ray-machine ">
                                                                {" "}
                                                                X Ray Machine{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <a
                                                    href="/"
                                                    className="view-all"
                                                >
                                                    View All Categories in Health &amp; Beauty
                                                </a>
                                            </div>
                                        </div> */}

                    <div className="hover-links">
                      <div className="row">
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Professional Beauty Tools</Link>
                            </li>
                            <li>
                              <Link to="#">Durable Plastic Combs</Link>
                            </li>
                            <li>
                              <Link to="#">Efficient Hair Removal Devices</Link>
                            </li>
                            <li>
                              <Link to="/">Advanced Tattoo Machines</Link>
                            </li>
                            <li>
                              <Link to="/">Precision Razor Blades</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Luxurious Body Fragrances</Link>
                            </li>
                            <li>
                              <Link to="#">Long-Lasting Hair Fragrances</Link>
                            </li>
                            <li>
                              <Link to="#">Exclusive Perfume Sets</Link>
                            </li>
                            <li>
                              <Link to="#">Refreshing Deodorants</Link>
                            </li>
                            <li>
                              <Link to="#">Elegant Attars</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">
                                High-Performance Motorized Treadmills
                              </Link>
                            </li>
                            <li>
                              <Link to="/">Relaxing Body Massagers</Link>
                            </li>
                            <li>
                              <Link to="/">Convenient Morning Walkers</Link>
                            </li>
                            <li>
                              <Link to="/">
                                Versatile Shoulder Press Machines
                              </Link>
                            </li>
                            <li>
                              <Link to="/">
                                Comprehensive Fitness Equipment
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Robust Vertical Autoclaves</Link>
                            </li>
                            <li>
                              <Link to="/">Precision Surgical Instruments</Link>
                            </li>
                            <li>
                              <Link to="/">Accurate Digital Thermometers</Link>
                            </li>
                            <li>
                              <Link to="/">
                                Essential Medical Equipment &amp; Supplies
                              </Link>
                            </li>
                            <li>
                              <Link to="/">Advanced X-Ray Machines</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">
                                Innovative Pharmaceutical Machinery
                              </Link>
                            </li>
                            <li>
                              <Link to="/">
                                High-Capacity Octagonal Blenders
                              </Link>
                            </li>
                            <li>
                              <Link to="/">Advanced Multi Mills</Link>
                            </li>
                            <li>
                              <Link to="/">Reliable Vibro Sifters</Link>
                            </li>
                            <li>
                              <Link to="/">
                                Efficient Tablet Press Machines
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Versatile Spectacle Frames</Link>
                            </li>
                            <li>
                              <Link to="/">Protective Safety Goggles</Link>
                            </li>
                            <li>
                              <Link to="/">Precision Optical Lenses</Link>
                            </li>
                            <li>
                              <Link to="/">High-Quality Contact Lenses</Link>
                            </li>
                            <li>
                              <Link to="#">Advanced Eye Care Solutions</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Revitalizing Face Masks</Link>
                            </li>
                            <li>
                              <Link to="#">Decorative Bindis</Link>
                            </li>
                            <li>
                              <Link to="#">Makeup &amp; Beauty Essentials</Link>
                            </li>
                            <li>
                              <Link to="#">Hydrating Rose Water</Link>
                            </li>
                            <li>
                              <Link to="#">Premium Hair Extensions</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Comfortable Baby Diapers</Link>
                            </li>
                            <li>
                              <Link to="/">Sturdy Baby Walkers</Link>
                            </li>
                            <li>
                              <Link to="/">Gentle Baby Powder</Link>
                            </li>
                            <li>
                              <Link to="/">Soothing Baby Oil</Link>
                            </li>
                            <li>
                              <Link to="/">Infant Care Essentials</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Purifying Neem Products</Link>
                            </li>
                            <li>
                              <Link to="/">Organic Ayurvedic Soap</Link>
                            </li>
                            <li>
                              <Link to="/">Healing Ayurvedic Oil</Link>
                            </li>
                            <li>
                              <Link to="/">Nourishing Amla Powder</Link>
                            </li>
                            <li>
                              <Link to="/">Holistic Ayurvedic Remedies</Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="text-center">
                        <Link to="#" className="view-all">
                          View All Categories in Health &amp; Beauty
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to={"#"}>
                      {" "}
                      <h4>Home Supplies</h4>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>
                    <div className="hover-links">
                      <div className="row">
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Bags & Suitcases</Link>
                            </li>
                            <li>
                              <Link to="#">Backpack</Link>
                            </li>
                            <li>
                              <Link to="#">Briefcase</Link>
                            </li>
                            <li>
                              <Link to="#">School Bags</Link>
                            </li>
                            <li>
                              <Link to="#">Shopping Bags</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Beauty Equipment</Link>
                            </li>
                            <li>
                              <Link to="#">Hair Removal Machine</Link>
                            </li>
                            <li>
                              <Link to="/">Razor Blade</Link>
                            </li>
                            <li>
                              <Link to="/">Tattoo Machine</Link>
                            </li>
                            <li>
                              <Link to="#">Plastic Combs</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Fitness Equipment</Link>
                            </li>
                            <li>
                              <Link to="/">Body Massager</Link>
                            </li>
                            <li>
                              <Link to="/">Motorized Treadmill</Link>
                            </li>
                            <li>
                              <Link to="/">Morning Walker</Link>
                            </li>
                            <li>
                              <Link to="/">Shoulder Press</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Household Items</Link>
                            </li>
                            <li>
                              <Link to="/">Automatic Dispenser</Link>
                            </li>
                            <li>
                              <Link to="/">Dry Iron</Link>
                            </li>
                            <li>
                              <Link to="/">Geyser</Link>
                            </li>
                            <li>
                              <Link to="/">Hand Blender</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Home Cleaning Products</Link>
                            </li>
                            <li>
                              <Link to="/">Brooms</Link>
                            </li>
                            <li>
                              <Link to="/">Floor Cleaner</Link>
                            </li>
                            <li>
                              <Link to="/">Mops</Link>
                            </li>
                            <li>
                              <Link to="/">Phenyle</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Home Decorations</Link>
                            </li>
                            <li>
                              <Link to="/">Lamp Shades</Link>
                            </li>
                            <li>
                              <Link to="/">Posters</Link>
                            </li>
                            <li>
                              <Link to="/">Table Lamps</Link>
                            </li>
                            <li>
                              <Link to="/">Vases</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Kitchenware</Link>
                            </li>
                            <li>
                              <Link to="/">Bowls</Link>
                            </li>
                            <li>
                              <Link to="/">Cutlery Set</Link>
                            </li>
                            <li>
                              <Link to="/">Kettles</Link>
                            </li>
                            <li>
                              <Link to="/">Water Jugs</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Buckets, Mugs & Storage Bins</Link>
                            </li>
                            <li>
                              <Link to="#">Bucket</Link>
                            </li>
                            <li>
                              <Link to="#">Dustbins</Link>
                            </li>
                            <li>
                              <Link to="#">Plastic Basket</Link>
                            </li>
                            <li>
                              <Link to="#">Plastic Mugs</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/rugsandcarpets?search=carpet">
                                Door Mats & Bath Mats
                              </Link>
                            </li>
                            <li>
                              <Link to="/rugsandcarpets?search=carpet">
                                Cotton Mat
                              </Link>
                            </li>
                            <li>
                              <Link to="/rugsandcarpets?search=carpet">
                                Foot Mats
                              </Link>
                            </li>
                            <li>
                              <Link to="/rugsandcarpets?search=carpet">
                                Welcome Mat
                              </Link>
                            </li>
                            <li>
                              <Link to="/rugsandcarpets?search=carpet">
                                Yoga Mat
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Medical Equipment & Supplies</Link>
                            </li>
                            <li>
                              <Link to="/">Digital Thermometers</Link>
                            </li>
                            <li>
                              <Link to="/">Surgical Instruments</Link>
                            </li>
                            <li>
                              <Link to="/">Vertical Autoclave</Link>
                            </li>
                            <li>
                              <Link to="/">X Ray Machine</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Pharmaceutical Machinery</Link>
                            </li>
                            <li>
                              <Link to="/">Multi Mill</Link>
                            </li>
                            <li>
                              <Link to="/">Octagonal Blender</Link>
                            </li>
                            <li>
                              <Link to="/">Tablet Press</Link>
                            </li>
                            <li>
                              <Link to="/">Vibro Sifter</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Tableware</Link>
                            </li>
                            <li>
                              <Link to="/">Coasters</Link>
                            </li>
                            <li>
                              <Link to="/">Dinner Sets</Link>
                            </li>
                            <li>
                              <Link to="/">Glass Sheets</Link>
                            </li>
                            <li>
                              <Link to="/">Napkin Rings</Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="text-center">
                        <Link to="#" className="view-all">
                          View All Categories in Home Supply
                        </Link>
                      </div>
                    </div>
                  </li>
                  {/* <li>
                    <Link to={"#"}>
                      {" "}
                      <h4>Home Supplies</h4>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>
                    <div className="hover-links">
                      <div className="row">
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Bags & Suitcases</Link>
                            </li>
                            <li>
                              <Link to="#">
                                Backpack
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Briefcase
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                School Bags
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Shopping Bags
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Beauty Equipment</Link>
                            </li>
                            <li>
                              <Link to="#">
                                Hair Removal Machine
                              </Link>
                            </li>
                            <li>
                              <Link to="/">Razor Blade</Link>
                            </li>
                            <li>
                              <Link to="/">Tattoo Machine</Link>
                            </li>
                            <li>
                              <Link to="#">
                                Plastic Combs
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Fitness Equipment</Link>
                            </li>
                            <li>
                              <Link to="/">Body Massager</Link>
                            </li>
                            <li>
                              <Link to="/">Motorized Treadmill</Link>
                            </li>
                            <li>
                              <Link to="/">Morning Walker</Link>
                            </li>
                            <li>
                              <Link to="/">Shoulder Press</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Household Items</Link>
                            </li>
                            <li>
                              <Link to="/">Automatic Dispenser</Link>
                            </li>
                            <li>
                              <Link to="/">Dry Iron</Link>
                            </li>
                            <li>
                              <Link to="/">Geyser</Link>
                            </li>
                            <li>
                              <Link to="/">Hand Blender</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Home Cleaning Products</Link>
                            </li>
                            <li>
                              <Link to="/">Brooms</Link>
                            </li>
                            <li>
                              <Link to="/">Floor Cleaner</Link>
                            </li>
                            <li>
                              <Link to="/">Mops</Link>
                            </li>
                            <li>
                              <Link to="/">Phenyle</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Home Decorations</Link>
                            </li>
                            <li>
                              <Link to="/">Lamp Shades</Link>
                            </li>
                            <li>
                              <Link to="/">Posters</Link>
                            </li>
                            <li>
                              <Link to="/">Table Lamps</Link>
                            </li>
                            <li>
                              <Link to="/">Vases</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Kitchenware</Link>
                            </li>
                            <li>
                              <Link to="/">Bowls</Link>
                            </li>
                            <li>
                              <Link to="/">Cutlery Set</Link>
                            </li>
                            <li>
                              <Link to="/">Kettles</Link>
                            </li>
                            <li>
                              <Link to="/">Water Jugs</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">
                                Buckets, Mugs & Storage Bins
                              </Link>
                            </li>
                            <li>
                              <Link to="#">Bucket</Link>
                            </li>
                            <li>
                              <Link to="#">Dustbins</Link>
                            </li>
                            <li>
                              <Link to="#">
                                Plastic Basket
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Plastic Mugs
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/rugsandcarpets?search=carpet">
                                Door Mats & Bath Mats
                              </Link>
                            </li>
                            <li>
                              <Link to="/rugsandcarpets?search=carpet">
                                Cotton Mat
                              </Link>
                            </li>
                            <li>
                              <Link to="/rugsandcarpets?search=carpet">
                                Foot Mats
                              </Link>
                            </li>
                            <li>
                              <Link to="/rugsandcarpets?search=carpet">
                                Welcome Mat
                              </Link>
                            </li>
                            <li>
                              <Link to="/rugsandcarpets?search=carpet">
                                Yoga Mat
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Medical Equipment & Supplies</Link>
                            </li>
                            <li>
                              <Link to="/">Digital Thermometers</Link>
                            </li>
                            <li>
                              <Link to="/">Surgical Instruments</Link>
                            </li>
                            <li>
                              <Link to="/">Vertical Autoclave</Link>
                            </li>
                            <li>
                              <Link to="/">X Ray Machine</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Pharmaceutical Machinery</Link>
                            </li>
                            <li>
                              <Link to="/">Multi Mill</Link>
                            </li>
                            <li>
                              <Link to="/">Octagonal Blender</Link>
                            </li>
                            <li>
                              <Link to="/">Tablet Press</Link>
                            </li>
                            <li>
                              <Link to="/">Vibro Sifter</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Tableware</Link>
                            </li>
                            <li>
                              <Link to="/">Coasters</Link>
                            </li>
                            <li>
                              <Link to="/">Dinner Sets</Link>
                            </li>
                            <li>
                              <Link to="/">Glass Sheets</Link>
                            </li>
                            <li>
                              <Link to="/">Napkin Rings</Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="text-center">
                        <Link to="#" className="view-all">
                          View All Categories in Home Supply
                        </Link>
                      </div>
                    </div>
                  </li> */}
                  {/* <li>
                    <Link to={"/industrial-machinery"}>
                      <h4> Industrial Machinery </h4>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>

                    <div className="hover-links">
                      <div className="row">
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Agricultural Machines & Plant</Link>
                            </li>
                            <li>
                              <Link to="#">Machine Parts</Link>
                            </li>
                            <li>
                              <Link to="#">Tiller Blades</Link>
                            </li>
                            <li>
                              <Link to="#">Mist Sprayers</Link>
                            </li>
                            <li>
                              <Link to="#">Trigger Sprayers</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Bakery & Dairy Machinery</Link>
                            </li>
                            <li>
                              <Link to="#">Biscuit Machines</Link>
                            </li>
                            <li>
                              <Link to="#">Pizza Oven</Link>
                            </li>
                            <li>
                              <Link to="#">Rotary Rack Oven</Link>
                            </li>
                            <li>
                              <Link to="#">Kneading Machines</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Boilers & Furnace</Link>
                            </li>
                            <li>
                              <Link to="#">Heat Exchangers</Link>
                            </li>
                            <li>
                              <Link to="#">Steam Boilers</Link>
                            </li>
                            <li>
                              <Link to="#">Industrial Burners</Link>
                            </li>
                            <li>
                              <Link to="#">Gas Burners</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="text-center">
                          <Link className="view-all" to="#">
                            View All Categories in Industrial Machinery
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li> */}
                  <li>
                    <Link to={"# "}>
                      <h4> Industrial Supplies </h4>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>

                    <div className="hover-links">
                      <div className="row">
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Programmable Controllers</Link>
                            </li>
                            <li>
                              <Link to="/">Pressure Measuring Devices</Link>
                            </li>
                            <li>
                              <Link to="/">Precision Scales</Link>
                            </li>
                            <li>
                              <Link to="/">Industrial Gauges</Link>
                            </li>
                            <li>
                              <Link to="/">Digital Flow Meters</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Used Industrial Cranes</Link>
                            </li>
                            <li>
                              <Link to="/">Mobile Cranes</Link>
                            </li>
                            <li>
                              <Link to="/">Industrial Conveyors</Link>
                            </li>
                            <li>
                              <Link to="/">Heavy-Duty Trolleys</Link>
                            </li>
                            <li>
                              <Link to="/">Automatic Jacks</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Precision Abrasives</Link>
                            </li>
                            <li>
                              <Link to="/">Mounting Brackets</Link>
                            </li>
                            <li>
                              <Link to="/">Heavy-Duty Ropes</Link>
                            </li>
                            <li>
                              <Link to="/">Diamond Cutting Tools</Link>
                            </li>
                            <li>
                              <Link to="/">Abrasive Tools</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Pressure Regulating Filters</Link>
                            </li>
                            <li>
                              <Link to="/">Mechanical Filters</Link>
                            </li>
                            <li>
                              <Link to="/">Hydraulic Oil Filters</Link>
                            </li>
                            <li>
                              <Link to="/">Industrial Cloth Filters</Link>
                            </li>
                            <li>
                              <Link to="/">Cartridge Filters</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Renewable Energy Turbines</Link>
                            </li>
                            <li>
                              <Link to="/">Portable Generators</Link>
                            </li>
                            <li>
                              <Link to="/">Power Generator</Link>
                            </li>
                            <li>
                              <Link to="/">Diesel Power Engines</Link>
                            </li>
                            <li>
                              <Link to="/">Commercial Generators</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Waterproof Tapes</Link>
                            </li>
                            <li>
                              <Link to="/">Thickening Agents</Link>
                            </li>
                            <li>
                              <Link to="/">Packaging Tapes</Link>
                            </li>
                            <li>
                              <Link to="/">Industrial Sealants</Link>
                            </li>
                            <li>
                              <Link to="/">Construction Adhesives</Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="text-center">
                        <Link className="view-all" to="# ">
                          View All Categories in Industrial Supplies
                        </Link>
                      </div>
                    </div>
                  </li>

                  <li>
                    <Link to={"# "}>
                      <h4> Industrial Machinery </h4>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>

                    <div className="hover-links">
                      <div className="row">
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="#">Universal Fasteners</Link>
                            </li>
                            <li>
                              <Link to="#">Threaded Screws</Link>
                            </li>
                            <li>
                              <Link to="#">Flanged Bolts</Link>
                            </li>
                            <li>
                              <Link to="#">Coupling Connectors</Link>
                            </li>
                            <li>
                              <Link to="#">Anchor Bolts</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Sona Masoori Rice</Link>
                            </li>
                            <li>
                              <Link to="/">1121 Golden Sella Basmati Rice</Link>
                            </li>
                            <li>
                              <Link to="/">Fresh Green Chilli</Link>
                            </li>
                            <li>
                              <Link to="/">Rcc Compound Wall</Link>
                            </li>
                            <li>
                              <Link to="/">Pulses</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Stevia Products</Link>
                            </li>
                            <li>
                              <Link to="/">Agricultural Machines</Link>
                            </li>
                            <li>
                              <Link to="/">Industrial Equipment</Link>
                            </li>
                            <li>
                              <Link to="/">Packaging Solutions</Link>
                            </li>
                            <li>
                              <Link to="/">Fertilizers</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Raincoats</Link>
                            </li>
                            <li>
                              <Link to="/">Work Wear</Link>
                            </li>
                            <li>
                              <Link to="/">T-Shirts</Link>
                            </li>
                            <li>
                              <Link to="/">Shirts</Link>
                            </li>
                            <li>
                              <Link to="/">Formal Wear</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Rugs</Link>
                            </li>
                            <li>
                              <Link to="/">Carpets</Link>
                            </li>
                            <li>
                              <Link to="/">Handwoven Textiles</Link>
                            </li>
                            <li>
                              <Link to="/">Bed Linen</Link>
                            </li>
                            <li>
                              <Link to="/">Mattresses</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Generator Sets</Link>
                            </li>
                            <li>
                              <Link to="/">Industrial Fans</Link>
                            </li>
                            <li>
                              <Link to="/">Turbines</Link>
                            </li>
                            <li>
                              <Link to="/">Power Storage Solutions</Link>
                            </li>
                            <li>
                              <Link to="/">Energy Management Systems</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Metal Sheets</Link>
                            </li>
                            <li>
                              <Link to="/">Steel Bars</Link>
                            </li>
                            <li>
                              <Link to="/">Welding Products</Link>
                            </li>
                            <li>
                              <Link to="/">Construction Materials</Link>
                            </li>
                            <li>
                              <Link to="/">Industrial Tools</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Solar Panels</Link>
                            </li>
                            <li>
                              <Link to="/">Solar Inverters</Link>
                            </li>
                            <li>
                              <Link to="/">Wind Turbines</Link>
                            </li>
                            <li>
                              <Link to="/">Hydropower Solutions</Link>
                            </li>
                            <li>
                              <Link to="/">Electric Vehicles</Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="text-center">
                        <Link className="view-all" to="# ">
                          View All Categories in machinery
                        </Link>
                      </div>
                    </div>
                  </li>
                  {/* <li>
                    <Link to={"/industrial-machinery"}>
                      <h4> Industrial machinery </h4>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>
                  </li> */}

                  <li>
                    {/* <a href="/industry/tools-equipment">
                                            <h4>Tools &amp; Equipment </h4>{" "}
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </a> */}
                    <Link to={"#"}>
                      {" "}
                      <h4>Tools &amp; Equipment </h4>{" "}
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>
                    {/* <div className="hover-links">
                                            <div className="row">
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/hand-tools">
                                                                {" "}
                                                                Hand Tools{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/hand-saws">
                                                                {" "}
                                                                Hand Saws{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/t-wrenches">
                                                                {" "}
                                                                T Wrenches{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/power-tools">
                                                                {" "}
                                                                Power Tools{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/four-way-spanner">
                                                                {" "}
                                                                Four Way Spanner{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/molds-and-dies">
                                                                {" "}
                                                                Molds and Dies{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/pvc-moulds">
                                                                {" "}
                                                                PVC Moulds{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/blow-molds">
                                                                {" "}
                                                                Blow Molds{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/cutting-dies">
                                                                {" "}
                                                                Cutting Dies{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/die-casting-dies">
                                                                {" "}
                                                                Die Casting Dies{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/testing-equipment">
                                                                {" "}
                                                                Testing Equipment{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/moisture-meter">
                                                                {" "}
                                                                Moisture Meter{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/hardness-tester">
                                                                {" "}
                                                                Hardness Tester{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/insulation-tester">
                                                                {" "}
                                                                Insulation Tester{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/sound-level-meters">
                                                                {" "}
                                                                Sound Level Meters{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/cutting-tools">
                                                                {" "}
                                                                Cutting Tools{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/saw-blades">
                                                                {" "}
                                                                Saw Blades{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/concrete-saw">
                                                                {" "}
                                                                Concrete Saw{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/circular-blade">
                                                                {" "}
                                                                Circular Blade{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/carbide-tools">
                                                                {" "}
                                                                Carbide Tools{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/heat-treating-equipment">
                                                                {" "}
                                                                Heat Treating Equipment{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/hot-plates">
                                                                {" "}
                                                                Hot Plates{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/autoclaves">
                                                                {" "}
                                                                Autoclaves{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/rotary-dryers">
                                                                {" "}
                                                                Rotary Dryers{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/cartridge-heaters">
                                                                {" "}
                                                                Cartridge Heaters{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/welding-equipment">
                                                                {" "}
                                                                Welding Equipment{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/solder">
                                                                {" "}
                                                                Solder{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/welding-rectifier">
                                                                {" "}
                                                                Welding Rectifier{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/welding-electrodes">
                                                                {" "}
                                                                Welding Electrodes{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/tig-welding-machine">
                                                                {" "}
                                                                TIG Welding Machine{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/machine-tools">
                                                                {" "}
                                                                Machine Tools{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/spindles">
                                                                {" "}
                                                                Spindles{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/expansion-joints">
                                                                {" "}
                                                                Expansion Joints{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/shearing-machines">
                                                                {" "}
                                                                Shearing Machines{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/in-well-drilling-rig">
                                                                {" "}
                                                                In Well Drilling Rig{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/laboratory-equipment">
                                                                {" "}
                                                                Laboratory Equipment{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/ph-meter">
                                                                {" "}
                                                                PH Meter{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/micrometer">
                                                                {" "}
                                                                Micrometer{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/laboratory-glassware">
                                                                {" "}
                                                                Laboratory Glassware{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/binocular-microscope">
                                                                {" "}
                                                                Binocular Microscope{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/fluid-handling-equipment">
                                                                {" "}
                                                                Fluid Handling Equipment{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/c-ring">
                                                                {" "}
                                                                C Rings{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/check-valves">
                                                                {" "}
                                                                Check Valves{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/gate-valves">
                                                                {" "}
                                                                Gate Valves{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/submersible-pumps ">
                                                                {" "}
                                                                Submersible Pumps{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <a
                                                    href="/"
                                                    className="view-all"
                                                >
                                                    View All Categories in Tools &amp; Equipment
                                                </a>
                                            </div>
                                        </div> */}

                    <div className="hover-links">
                      <div className="row">
                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Welding Rectifiers</Link>
                            </li>
                            <li>
                              <Link to="/">Welding Electrodes</Link>
                            </li>
                            <li>
                              <Link to="/">TIG Welding Machines</Link>
                            </li>
                            <li>
                              <Link to="/">Soldering Supplies</Link>
                            </li>
                            <li>
                              <Link to="/">Welding Equipment</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Sound Measurement Instruments</Link>
                            </li>
                            <li>
                              <Link to="/">Moisture Analysis Devices</Link>
                            </li>
                            <li>
                              <Link to="/">Insulation Testers</Link>
                            </li>
                            <li>
                              <Link to="/">Hardness Measurement Tools</Link>
                            </li>
                            <li>
                              <Link to="/">Testing Equipment</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">T-Wrenches</Link>
                            </li>
                            <li>
                              <Link to="/">Power Tools</Link>
                            </li>
                            <li>
                              <Link to="/">Hand Saws</Link>
                            </li>
                            <li>
                              <Link to="/">Hand Tools</Link>
                            </li>
                            <li>
                              <Link to="/">Four-Way Spanners</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Die Casting Molds</Link>
                            </li>
                            <li>
                              <Link to="/">Cutting Dies</Link>
                            </li>
                            <li>
                              <Link to="/">Blow Molds</Link>
                            </li>
                            <li>
                              <Link to="/">PVC Molds</Link>
                            </li>
                            <li>
                              <Link to="/">Molds and Dies</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Spindles</Link>
                            </li>
                            <li>
                              <Link to="/">Shearing Machines</Link>
                            </li>
                            <li>
                              <Link to="/">In-Well Drilling Rigs</Link>
                            </li>
                            <li>
                              <Link to="/">Expansion Joints</Link>
                            </li>
                            <li>
                              <Link to="/">Machine Tools</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">PH Meters</Link>
                            </li>
                            <li>
                              <Link to="/">Micrometers</Link>
                            </li>
                            <li>
                              <Link to="/">Laboratory Glassware</Link>
                            </li>
                            <li>
                              <Link to="/">Binocular Microscopes</Link>
                            </li>
                            <li>
                              <Link to="/">Laboratory Equipment</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Rotary Dryers</Link>
                            </li>
                            <li>
                              <Link to="/">Hot Plates</Link>
                            </li>
                            <li>
                              <Link to="/">Heat Treating Equipment</Link>
                            </li>
                            <li>
                              <Link to="/">Cartridge Heaters</Link>
                            </li>
                            <li>
                              <Link to="/">Autoclaves</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Submersible Pump Systems</Link>
                            </li>
                            <li>
                              <Link to="/">Gate Valves</Link>
                            </li>
                            <li>
                              <Link to="/">Check Valves</Link>
                            </li>
                            <li>
                              <Link to="/">C-Rings</Link>
                            </li>
                            <li>
                              <Link to="/">Fluid Handling Equipment</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4">
                          <ul>
                            <li>
                              <Link to="/">Saw Blades</Link>
                            </li>
                            <li>
                              <Link to="/">Circular Blades</Link>
                            </li>
                            <li>
                              <Link to="/">Concrete Saws</Link>
                            </li>
                            <li>
                              <Link to="/">Carbide Tools</Link>
                            </li>
                            <li>
                              <Link to="/">Cutting Tools</Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="text-center">
                        <Link to="#" className="view-all">
                          View All Categories in Tools &amp; Equipment
                        </Link>
                      </div>
                    </div>
                  </li>
                  {/* <li>
                                        <a
                                            href="companies"
                                            className="all-catg"
                                        >
                                            <h4>All Categories</h4>
                                        </a>
                                    </li> */}
                  <li>
                    {/* <a href="/industry/furniture">
                                            <h4>Furniture</h4>{" "}
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </a> */}
                    {/* <Link to={'#'}>
                                            <h4>Furniture</h4>{" "}
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </Link>
                                        <div className="hover-links">
                                            <div className="row">
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/antique-furniture">
                                                                {" "}
                                                                Antique Furniture{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/arm-chairs">
                                                                {" "}
                                                                Arm Chairs{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/antique-tables">
                                                                {" "}
                                                                Antique Tables{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/antique-cabinets">
                                                                {" "}
                                                                Antique Cabinets{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/antique-almirah">
                                                                {" "}
                                                                Antique Almirah{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/wood-furniture">
                                                                {" "}
                                                                Wood Furniture{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/wooden-sofa">
                                                                {" "}
                                                                Wooden Sofa{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/wooden-almirah">
                                                                {" "}
                                                                Wooden Almirah{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/wooden-wine-rack">
                                                                {" "}
                                                                Wooden Wine Rack{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/wooden-dining-table">
                                                                {" "}
                                                                Wooden Dining Table{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/market/wood-furniture">
                                                                {" "}
                                                                Metal Furniture{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/steel-chair">
                                                                {" "}
                                                                Steel Chair{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/brass-stand">
                                                                {" "}
                                                                Brass Stand{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/iron-table">
                                                                {" "}
                                                                Iron Table{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/iron-furniture">
                                                                {" "}
                                                                Iron Furniture{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/">
                                                                {" "}
                                                                Commercial Use Furniture{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/executive-table">
                                                                {" "}
                                                                Executive Table{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/revolving-chairs">
                                                                {" "}
                                                                Revolving Chairs{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/computer-chair">
                                                                {" "}
                                                                Computer Chair{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/drawer-cabinets">
                                                                {" "}
                                                                Drawer Cabinets{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/">
                                                                {" "}
                                                                Plastic Furniture{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/plastic-chairs">
                                                                {" "}
                                                                Plastic Chairs{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/plastic-stool">
                                                                {" "}
                                                                Plastic Stool{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/plastic-tables">
                                                                {" "}
                                                                Plastic Tables{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/plastic-rack">
                                                                {" "}
                                                                Plastic Rack{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/">
                                                                {" "}
                                                                Home Furniture{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/recliner">
                                                                {" "}
                                                                Recliner{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/designer-sofa-set">
                                                                {" "}
                                                                Designer Sofa Set{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/designer-chair">
                                                                {" "}
                                                                Designer Chair{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/kitchen-cabinets">
                                                                {" "}
                                                                Kitchen Cabinets{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/">
                                                                {" "}
                                                                Institutional &amp; Hospital Furniture{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/">
                                                                {" "}
                                                                I V Stand{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/">
                                                                {" "}
                                                                School Furniture{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/operating-table">
                                                                {" "}
                                                                Operating Table{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/laboratory-furniture">
                                                                {" "}
                                                                Laboratory Furniture{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/">
                                                                {" "}
                                                                Retail Displays &amp; Fixtures{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/">
                                                                {" "}
                                                                Supermarket Rack{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/">
                                                                {" "}
                                                                Letter Boards{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/">
                                                                {" "}
                                                                Display Box{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/">
                                                                {" "}
                                                                Glass Shelves{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <ul>
                                                        <li>
                                                            <a href="/">
                                                                {" "}
                                                                Furniture Accessories{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/armrest">
                                                                {" "}
                                                                Armrest{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/stretcher">
                                                                {" "}
                                                                Stretcher{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/chair-base">
                                                                {" "}
                                                                Chair Base{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/drawer-slides">
                                                                {" "}
                                                                Drawer Slides{" "}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                               
                                                <Link className="view" to={'#'}>     View All Categories in Home Supplies{" "}</Link>
                                            </div>
                                        </div> */}
                  </li>
                </ul>
              </div>
            </li>
            <li className="item">
              {/* <a
                                href="#"
                                className="nav-item"
                            >
                                #
                            </a> */}
              <Link className="nav-item" to={"#"}>
                Home and Supplies
              </Link>
              <div className="submenu" style={{ display: "none" }}>
                <div className="container">
                  <ul className="submenu-wrap">
                    <li>
                      <Link to="#" className="title">
                        Bags &amp; Suitcase
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Travel Bags</Link>
                        </li>
                        <li>
                          <Link to="#">Carry Bags</Link>
                        </li>
                        <li>
                          <Link to="#">Cotton Bags</Link>
                        </li>
                        <li>
                          <Link to="#">Ladies Bags</Link>
                        </li>
                        <li>
                          <Link to="#">Shopping Bags</Link>
                        </li>
                        <li>
                          <Link to="#">Suitcase</Link>
                        </li>
                        <li>
                          <Link to="#">Leather Suitcase</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Kitchenware
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Blender</Link>
                        </li>
                        <li>
                          <Link to="#">Mixer Grinder</Link>
                        </li>
                        <li>
                          <Link to="#">Plates</Link>
                        </li>
                        <li>
                          <Link to="#">Bowls</Link>
                        </li>
                        <li>
                          <Link to="#">Pans</Link>
                        </li>
                        <li>
                          <Link to="#">Trays</Link>
                        </li>
                        <li>
                          <Link to="#">Cutlery Set</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Home Decorations
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Statues</Link>
                        </li>
                        <li>
                          <Link to="#">Flower Vases</Link>
                        </li>
                        <li>
                          <Link to="#">Lamp Shades</Link>
                        </li>
                        <li>
                          <Link to="#">Posters</Link>
                        </li>
                        <li>
                          <Link to="#">Key Hanger</Link>
                        </li>
                        <li>
                          <Link to="#">Flower Stand</Link>
                        </li>
                        <li>
                          <Link to="#">Lamp Stands</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Home Cleaning Products
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Brooms</Link>
                        </li>
                        <li>
                          <Link to="#">Sponges</Link>
                        </li>
                        <li>
                          <Link to="#">Mops</Link>
                        </li>
                        <li>
                          <Link to="#">Toilet Cleaners</Link>
                        </li>
                        <li>
                          <Link to="#">Glass Cleaner</Link>
                        </li>
                        <li>
                          <Link to="#">Garbage Bags</Link>
                        </li>
                        <li>
                          <Link to="#">Floor Cleaner</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/detergent?search=detergent" className="title">
                        Soaps &amp; Detergents
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="/detergent?search=detergent">
                            Detergent Powder
                          </Link>
                        </li>
                        <li>
                          <Link to="/detergent?search=detergent">
                            Washing Powder
                          </Link>
                        </li>
                        <li>
                          <Link to="/detergent?search=detergent">
                            Detergent Cake
                          </Link>
                        </li>
                        <li>
                          <Link to="/detergent?search=detergent">
                            Liquid Hand Wash
                          </Link>
                        </li>
                        <li>
                          <Link to="/detergent?search=detergent">
                            Handmade Soap
                          </Link>
                        </li>
                        <li>
                          <Link to="/detergent?search=detergent">
                            Herbal Soaps
                          </Link>
                        </li>
                        <li>
                          <Link to="/detergent?search=detergent">
                            Neem Soap
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="text-center">
                    <Link to="#" className="view view-main">
                      View All Categories in Home Supplies{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="item">
              <Link
                className="nav-item"
                to={
                  "seafood-spices-beverages-dairy-products-snacks-confectionery-nuts-seeds-suppliers"
                }
              >
                Food Products & Beverage
              </Link>
              <div className="submenu" style={{ display: "none" }}>
                <div className="container">
                  <ul className="submenu-wrap">
                    <li>
                      <Link to="# " className="title">
                        Beverages
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="# ">Juice</Link>
                        </li>
                        <li>
                          <Link to="# ">Mineral Water</Link>
                        </li>
                        <li>
                          <Link to="# ">Beer</Link>
                        </li>
                        <li>
                          <Link to="# ">Shakes</Link>
                        </li>
                        <li>
                          <Link to="# ">Amla Juice</Link>
                        </li>
                        <li>
                          <Link to="# ">Mango Juice</Link>
                        </li>
                        <li>
                          <Link to="# ">Health Drinks</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="# " className="title">
                        Food Additives
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="# ">Guar Gum</Link>
                        </li>
                        <li>
                          <Link to="# ">Ice Cream Powders</Link>
                        </li>
                        <li>
                          <Link to="# ">Citric Acid</Link>
                        </li>
                        <li>
                          <Link to="# ">Food Flavour</Link>
                        </li>
                        <li>
                          <Link to="# ">Vinegar</Link>
                        </li>
                        <li>
                          <Link to="# ">Food Colors</Link>
                        </li>
                        <li>
                          <Link to="# ">Camphor</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Spices
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="/#">Turmeric</Link>
                        </li>
                        <li>
                          <Link to="#">Pepper</Link>
                        </li>
                        <li>
                          <Link to="#">Coriander</Link>
                        </li>
                        <li>
                          <Link to="/#">Cardamom</Link>
                        </li>
                        <li>
                          <Link to="#">Black Pepper</Link>
                        </li>
                        <li>
                          <Link to="#">Red Chilli</Link>
                        </li>
                        <li>
                          <Link to="#">Tamarind</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="# " className="title">
                        Edible Oils
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="# ">Refined Oil</Link>
                        </li>
                        <li>
                          <Link to="# ">Seed Oil</Link>
                        </li>
                        <li>
                          <Link to="# ">Sunflower Oil</Link>
                        </li>
                        <li>
                          <Link to="# ">Coconut Oil</Link>
                        </li>
                        <li>
                          <Link to="# ">Olive Oil</Link>
                        </li>
                        <li>
                          <Link to="# ">Neem Oil</Link>
                        </li>
                        <li>
                          <Link to="# ">Mustard Oil</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/dryfruitsmanu?search=dry" className="title">
                        Dry Fruits &amp; Nuts
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="/dryfruitsmanu?search=drys">Dates</Link>
                        </li>
                        <li>
                          <Link to="/dryfruitsmanu?search=dry">Almond</Link>
                        </li>
                        <li>
                          <Link to="/dryfruitsmanu?search=dry">
                            Cashew Nuts
                          </Link>
                        </li>
                        <li>
                          <Link to="/dryfruitsmanu?search=dry">Walnuts</Link>
                        </li>
                        <li>
                          <Link to="/dryfruitsmanu?search=dry">Groundnut</Link>
                        </li>
                        <li>
                          <Link to="/dryfruitsmanu?search=dry">Peanut</Link>
                        </li>
                        <li>
                          <Link to="/dryfruitsmanu?search=dry">Raisins</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="text-center">
                    <Link className="view view-main" to={"# "}>
                      View All Categories in Food Products &amp; Beverage
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="item">
              {/* <a
                                href="chemicals"
                                className="nav-item"
                            >
                                Chemicals
                            </a> */}
              <Link className="nav-item" to={"#"}>
                Equipments
              </Link>
              <div className="submenu" style={{ display: "none" }}>
                <div className="container">
                  <ul className="submenu-wrap">
                    <li>
                      <Link to="#" className="title">
                        Nuts, Bolts, Screws &amp; Fasteners
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Screws</Link>
                        </li>
                        <li>
                          <Link to="#">Nuts</Link>
                        </li>
                        <li>
                          <Link to="#">Bolts</Link>
                        </li>
                        <li>
                          <Link to="#">U Bolts</Link>
                        </li>
                        <li>
                          <Link to="#">Washers</Link>
                        </li>
                        <li>
                          <Link to="#">Connectors</Link>
                        </li>
                        <li>
                          <Link to="#">Rivets</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Adhesives &amp; Sealants
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Tapes</Link>
                        </li>
                        <li>
                          <Link to="#">Self Adhesives</Link>
                        </li>
                        <li>
                          <Link to="#">Bopp Tapes</Link>
                        </li>
                        <li>
                          <Link to="#">Sealants</Link>
                        </li>
                        <li>
                          <Link to="#">Elastic Tapes</Link>
                        </li>
                        <li>
                          <Link to="#">Tile Adhesives</Link>
                        </li>
                        <li>
                          <Link to="#">Admixtures</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Industrial Chains &amp; Belts
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Conveyor Belts</Link>
                        </li>
                        <li>
                          <Link to="#">Sprockets</Link>
                        </li>
                        <li>
                          <Link to="#">Chain Blocks</Link>
                        </li>
                        <li>
                          <Link to="#">Industrial Rollers</Link>
                        </li>
                        <li>
                          <Link to="#">Worm Wheel</Link>
                        </li>
                        <li>
                          <Link to="#">Transmission Belts</Link>
                        </li>
                        <li>
                          <Link to="#">Augers</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Measurement Instruments
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Flow Meter</Link>
                        </li>
                        <li>
                          <Link to="#">Weighing Scale</Link>
                        </li>
                        <li>
                          <Link to="#">Pressure Gauges</Link>
                        </li>
                        <li>
                          <Link to="#">Digital Controller</Link>
                        </li>
                        <li>
                          <Link to="#">Sensors</Link>
                        </li>
                        <li>
                          <Link to="#">Regulators</Link>
                        </li>
                        <li>
                          <Link to="#">Thermocouple</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Pumps
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Water Pumps</Link>
                        </li>
                        <li>
                          <Link to="#">Centrifugal Pumps</Link>
                        </li>
                        <li>
                          <Link to="#">Vacuum Pumps</Link>
                        </li>
                        <li>
                          <Link to="#">Diesel Pumps</Link>
                        </li>
                        <li>
                          <Link to="#">Gear Pump</Link>
                        </li>
                        <li>
                          <Link to="#">Hydraulic Pumps</Link>
                        </li>
                        <li>
                          <Link to="#">Oil Pump</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="text-center">
                    <Link to="#" className="view">
                      View All Categories in Industrial Supplies
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="item">
              <Link className="nav-item" to={"gifts-items"}>
                Gifts & Items
              </Link>
              <div className="submenu" style={{ display: "none" }}>
                {/* <div className="container">
                                    <ul className="submenu-wrap">
                                        <li>
                                            <a
                                                href="/"
                                                className="title"
                                            >
                                                Electric Fans
                                            </a>
                                            <ul className="sublinks">
                                                <li>
                                                    <a href="/">
                                                        Electric Fans
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        Ceiling Fans
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        Exhaust Fans
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        Table Fans
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        Pedestal Fan
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        Wall Fan
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        Axial Fans
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a
                                                href="/"
                                                className="title"
                                            >
                                                Cable &amp; Wire
                                            </a>
                                            <ul className="sublinks">
                                                <li>
                                                    <a href="/">
                                                        Copper Wires
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        Cable Gland
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/galvanized-wire">
                                                        Galvanized Wire
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/barbed-wire">
                                                        Barbed Wire
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/power-cables">
                                                        Power Cables
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/pvc-cables">
                                                        PVC Cables
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/brass-cables">
                                                        Brass Cables
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a
                                                href="/"
                                                className="title"
                                            >
                                                Consumer Electronics
                                            </a>
                                            <ul className="sublinks">
                                                <li>
                                                    <a href="/led-tv">
                                                        LED TV
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/air-conditioners">
                                                        Air Conditioners
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/refrigerator">
                                                        Refrigerator
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/cameras">
                                                        Cameras
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/heaters">
                                                        Heaters
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/speakers">
                                                        Speakers
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/air-coolers">
                                                        Air Coolers
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a
                                                href="/"
                                                className="title"
                                            >
                                                Lamps and Lighting Fixtures
                                            </a>
                                            <ul className="sublinks">
                                                <li>
                                                    <a href="/led-bulb">
                                                        Led Bulb
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/emergency-light">
                                                        Emergency Light
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/garden-lamp">
                                                        Garden Lamp
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/fog-lamp">
                                                        Fog Lamp
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/security-lights">
                                                        Security Lights
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/fluorescent-lights">
                                                        Fluorescent Lights
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/energy-saving-lamp">
                                                        Energy Saving Lamp
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a
                                                href="/"
                                                className="title"
                                            >
                                                Switches, Sockets &amp; Relays
                                            </a>
                                            <ul className="sublinks">
                                                <li>
                                                    <a href="/sockets">
                                                        Sockets
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/relays">
                                                        Relays
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/condensers">
                                                        Condensers
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/electrical-switches">
                                                        Electrical Switches
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/rotary-switches">
                                                        Rotary Switches
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/control-panels">
                                                        Control Panels
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/plc-panel">
                                                        PLC Panel
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <div className="text-center">
                                        <a
                                            href="industry/electronics-electrical"
                                            className="view"
                                        >
                                            View All Categories in Electronics &amp; Electrical{" "}
                                            {" "}
                                        </a>
                                    </div>
                                </div> */}
                <div className="container">
                  <ul className="submenu-wrap">
                    <li>
                      <Link to="#" className="title">
                        Toys
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Action Figures</Link>
                        </li>
                        <li>
                          <Link to="#">Puzzles</Link>
                        </li>
                        <li>
                          <Link to="#">Board Games</Link>
                        </li>
                        <li>
                          <Link to="#">Stuffed Animals</Link>
                        </li>
                        <li>
                          <Link to="#">Building Sets</Link>
                        </li>
                        <li>
                          <Link to="#">Dolls</Link>
                        </li>
                        <li>
                          <Link to="#">Outdoor Toys</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Books
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Fiction</Link>
                        </li>
                        <li>
                          <Link to="#">Non-Fiction</Link>
                        </li>
                        <li>
                          <Link to="#">Biographies</Link>
                        </li>
                        <li>
                          <Link to="#">Science Fiction</Link>
                        </li>
                        <li>
                          <Link to="#">Fantasy</Link>
                        </li>
                        <li>
                          <Link to="#">Mystery</Link>
                        </li>
                        <li>
                          <Link to="#">Children's Books</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Gadgets
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Smartphones</Link>
                        </li>
                        <li>
                          <Link to="#">Tablets</Link>
                        </li>
                        <li>
                          <Link to="#">Smartwatches</Link>
                        </li>
                        <li>
                          <Link to="#">Headphones</Link>
                        </li>
                        <li>
                          <Link to="#">Speakers</Link>
                        </li>
                        <li>
                          <Link to="#">Drones</Link>
                        </li>
                        <li>
                          <Link to="#">Cameras</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/#" className="title">
                        Home Decor
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Vases</Link>
                        </li>
                        <li>
                          <Link to="/#">Picture Frames</Link>
                        </li>
                        <li>
                          <Link to="#?search=candles">Candles</Link>
                        </li>
                        <li>
                          <Link to="/#">Rugs</Link>
                        </li>
                        <li>
                          <Link to="#">Lamps</Link>
                        </li>
                        <li>
                          <Link to="/#">Wall Art</Link>
                        </li>
                        <li>
                          <Link to="#">Throws</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Fashion Accessories
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Watches</Link>
                        </li>
                        <li>
                          <Link to="#">Sunglasses</Link>
                        </li>
                        <li>
                          <Link to="#">Hats</Link>
                        </li>
                        <li>
                          <Link to="#">Scarves</Link>
                        </li>
                        <li>
                          <Link to="#">Belts</Link>
                        </li>
                        <li>
                          <Link to="#">Jewelry</Link>
                        </li>
                        <li>
                          <Link to="#">Bags</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="text-center">
                    <Link to="#" className="view">
                      View All Categories in Gifts
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="item">
              <Link className="nav-item" to={"jewellery-necklaces-suppliers"}>
                jewellery
              </Link>
              <div className="submenu" style={{ display: "none" }}>
                <div className="container">
                  <ul className="submenu-wrap">
                    <li>
                      <Link to="# " className="title">
                        Rings
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="# ">Engagement Rings</Link>
                        </li>
                        <li>
                          <Link to="# ">Wedding Bands</Link>
                        </li>
                        <li>
                          <Link to="# ">Cocktail Rings</Link>
                        </li>
                        <li>
                          <Link to="# ">Statement Rings</Link>
                        </li>
                        <li>
                          <Link to="# ">Stackable Rings</Link>
                        </li>
                        <li>
                          <Link to="# ">Promise Rings</Link>
                        </li>
                        <li>
                          <Link to="# ">Birthstone Rings</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="# " className="title">
                        Necklaces
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="# ">Pendants</Link>
                        </li>
                        <li>
                          <Link to="# ">Chains</Link>
                        </li>
                        <li>
                          <Link to="# ">Chokers</Link>
                        </li>
                        <li>
                          <Link to="# ">Lockets</Link>
                        </li>
                        <li>
                          <Link to="# ">Beaded Necklaces</Link>
                        </li>
                        <li>
                          <Link to="# ">Layered Necklaces</Link>
                        </li>
                        <li>
                          <Link to="# ">Statement Necklaces</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="# " className="title">
                        Earrings
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="# ">Stud Earrings</Link>
                        </li>
                        <li>
                          <Link to="# ">Hoop Earrings</Link>
                        </li>
                        <li>
                          <Link to="# ">Dangle Earrings</Link>
                        </li>
                        <li>
                          <Link to="# ">Drop Earrings</Link>
                        </li>
                        <li>
                          <Link to="# ">Cluster Earrings</Link>
                        </li>
                        <li>
                          <Link to="# ">Huggie Earrings</Link>
                        </li>
                        <li>
                          <Link to="# ">Chandelier Earrings</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="# " className="title">
                        Bracelets
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="# ">Cuff Bracelets</Link>
                        </li>
                        <li>
                          <Link to="# ">Charm Bracelets</Link>
                        </li>
                        <li>
                          <Link to="# ">Bangle Bracelets</Link>
                        </li>
                        <li>
                          <Link to="# ">Beaded Bracelets</Link>
                        </li>
                        <li>
                          <Link to="# ">Chain Bracelets</Link>
                        </li>
                        <li>
                          <Link to="# ">Link Bracelets</Link>
                        </li>
                        <li>
                          <Link to="# ">Tennis Bracelets</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="# " className="title">
                        Jewelry Sets
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="# ">Necklace and Earring Sets</Link>
                        </li>
                        <li>
                          <Link to="# ">Bracelet and Ring Sets</Link>
                        </li>
                        <li>
                          <Link to="# ">Complete Sets</Link>
                        </li>
                        <li>
                          <Link to="# ">Matching Sets</Link>
                        </li>
                        <li>
                          <Link to="# ">Wedding Sets</Link>
                        </li>
                        <li>
                          <Link to="# ">Vintage Sets</Link>
                        </li>
                        <li>
                          <Link to="# ">Fashion Sets</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="text-center">
                    <Link to="# " className="view">
                      View All Categories in Jewelry
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="item">
              <Link
                className="nav-item"
                to={"#"}
              >
                Chemicals
              </Link>
              <div className="submenu" style={{ display: "none" }}>
                <div className="container">
                  <ul className="submenu-wrap">
                    <li>
                      <Link to="#" className="title">
                        Industrial Chemicals
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Acids</Link>
                        </li>
                        <li>
                          <Link to="#">Alkalis</Link>
                        </li>
                        <li>
                          <Link to="#">Solvents</Link>
                        </li>
                        <li>
                          <Link to="#">Catalysts</Link>
                        </li>
                        <li>
                          <Link to="#">Polymers</Link>
                        </li>
                        <li>
                          <Link to="#">Specialty Chemicals</Link>
                        </li>
                        <li>
                          <Link to="#">Chemical Reagents</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Agricultural Chemicals
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="/#">Fertilizers</Link>
                        </li>
                        <li>
                          <Link to="/#">Pesticides</Link>
                        </li>
                        <li>
                          <Link to="/#">Herbicides</Link>
                        </li>
                        <li>
                          <Link to="/#">Insecticides</Link>
                        </li>
                        <li>
                          <Link to="/#">Plant Growth Regulators</Link>
                        </li>
                        <li>
                          <Link to="/#">Soil Conditioners</Link>
                        </li>
                        <li>
                          <Link to="/#">Adjuvants</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Pharmaceutical Chemicals
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Active Pharmaceutical Ingredients</Link>
                        </li>
                        <li>
                          <Link to="#">Excipients</Link>
                        </li>
                        <li>
                          <Link to="#">Intermediates</Link>
                        </li>
                        <li>
                          <Link to="#">Biopharmaceuticals</Link>
                        </li>
                        <li>
                          <Link to="#">Nutraceuticals</Link>
                        </li>
                        <li>
                          <Link to="#">Veterinary Chemicals</Link>
                        </li>
                        <li>
                          <Link to="#">Pharmaceutical Solvents</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Cosmetic Chemicals
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Emollients</Link>
                        </li>
                        <li>
                          <Link to="#">Surfactants</Link>
                        </li>
                        <li>
                          <Link to="#">Preservatives</Link>
                        </li>
                        <li>
                          <Link to="#">Fragrances</Link>
                        </li>
                        <li>
                          <Link to="#">Colorants</Link>
                        </li>
                        <li>
                          <Link to="#">Humectants</Link>
                        </li>
                        <li>
                          <Link to="#">Thickeners</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Laboratory Chemicals
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Analytical Reagents</Link>
                        </li>
                        <li>
                          <Link to="#">Biochemicals</Link>
                        </li>
                        <li>
                          <Link to="#">Laboratory Acids</Link>
                        </li>
                        <li>
                          <Link to="#">Laboratory Solvents</Link>
                        </li>
                        <li>
                          <Link to="#">Organic Chemicals</Link>
                        </li>
                        <li>
                          <Link to="#">Inorganic Chemicals</Link>
                        </li>
                        <li>
                          <Link to="#">Chromatography Reagents</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="text-center">
                    <Link to="#" className="view">
                      View All Categories in Chemicals
                    </Link>
                  </div>
                </div>
              </div>
            </li>

            <li className="item">
              {/* <a
                                href="furnitures"
                                className="nav-item"
                            >
                                Furnitures
                            </a> */}
              <Link className="nav-item" to={"#"}>
                {" "}
                Furnitures
              </Link>
              <div className="submenu" style={{ display: "none" }}>
                <div className="container">
                  <ul className="submenu-wrap">
                    <li>
                      <Link to="#" className="title">
                        Antique Furniture
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Antique Chairs</Link>
                        </li>
                        <li>
                          <Link to="#">Antique Beds</Link>
                        </li>
                        <li>
                          <Link to="#">Antique Tables</Link>
                        </li>
                        <li>
                          <Link to="#">Wooden Bed</Link>
                        </li>
                        <li>
                          <Link to="#">Antique Almirah</Link>
                        </li>
                        <li>
                          <Link to="#">Antique Wood Tables</Link>
                        </li>
                        <li>
                          <Link to="#">Antique Furniture</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Home Furniture
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Sofa Set</Link>
                        </li>
                        <li>
                          <Link to="#">Center Tables</Link>
                        </li>
                        <li>
                          <Link to="#">Double Bed</Link>
                        </li>
                        <li>
                          <Link to="#">Rack</Link>
                        </li>
                        <li>
                          <Link to="#">TV Stand</Link>
                        </li>
                        <li>
                          <Link to="#">Stool</Link>
                        </li>
                        <li>
                          <Link to="#">Bedroom Wardrobe</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Commercial Use Furniture
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Executive Table</Link>
                        </li>
                        <li>
                          <Link to="#">Wall Racks</Link>
                        </li>
                        <li>
                          <Link to="#">Banquet Tables</Link>
                        </li>
                        <li>
                          <Link to="#">Cafe Furniture</Link>
                        </li>
                        <li>
                          <Link to="#">Computer Table</Link>
                        </li>
                        <li>
                          <Link to="#">Revolving Chairs</Link>
                        </li>
                        <li>
                          <Link to="#">Wine Racks</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Plastic Furniture
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Plastic Chairs</Link>
                        </li>
                        <li>
                          <Link to="#">Plastic Tables</Link>
                        </li>
                        <li>
                          <Link to="#">Plastic Stool</Link>
                        </li>
                        <li>
                          <Link to="#">Plastic Cabinets</Link>
                        </li>
                        <li>
                          <Link to="#">Plastic Rack</Link>
                        </li>
                        <li>
                          <Link to="#">PVC Chair</Link>
                        </li>
                        <li>
                          <Link to="#">PVC Table</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Furniture Accessories
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Table Top</Link>
                        </li>
                        <li>
                          <Link to="#">Stretcher</Link>
                        </li>
                        <li>
                          <Link to="#">Bed Frames</Link>
                        </li>
                        <li>
                          <Link to="#">Chair Base</Link>
                        </li>
                        <li>
                          <Link to="#">Chair Handles</Link>
                        </li>
                        <li>
                          <Link to="#">Sofa Leg</Link>
                        </li>
                        <li>
                          <Link to="#">Rack Panels</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="text-center">
                    <Link to="#" className="view">
                      View All Categories in Furniture
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="item">
              <Link to={"#"} className="nav-item">
                {" "}
                Clothing and Fashion
              </Link>
              <div className="submenu" style={{ display: "none" }}>
                {/* <div className="container">
                                    <ul className="submenu-wrap">
                                        <li>
                                            <a
                                                href="#"
                                                className="title"
                                            >
                                                Antique Furniture
                                            </a>
                                            <ul className="sublinks">
                                                <li>
                                                    <a href="/">
                                                        Antique Chairs
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        Antique Beds
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        Antique Tables
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        Wooden Bed
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        Antique Almirah
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        Antique Wood Tables
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        Antique Furniture
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a
                                                href="/"
                                                className="title"
                                            >
                                                Home Furniture
                                            </a>
                                            <ul className="sublinks">
                                                <li>
                                                    <a href="/sofa-set">
                                                        Sofa Set
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/center-tables">
                                                        Center Tables
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/double-bed">
                                                        Double Bed
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/rack">
                                                        Rack
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/tv-stand">
                                                        TV Stand
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/stool">
                                                        Stool
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/bedroom-wardrobe">
                                                        Bedroom Wardrobe
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a
                                                href="/"
                                                className="title"
                                            >
                                                Commercial Use Furniture
                                            </a>
                                            <ul className="sublinks">
                                                <li>
                                                    <a href="/">
                                                        Executive Table
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/wall-racks">
                                                        Wall Racks
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/banquet-tables">
                                                        Banquet Tables
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/cafe-furniture">
                                                        Cafe Furniture
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/computer-table">
                                                        Computer Table
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/revolving-chairs">
                                                        Revolving Chairs
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/wine-racks">
                                                        Wine Racks
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a
                                                href="/"
                                                className="title"
                                            >
                                                Plastic Furniture{" "}
                                            </a>
                                            <ul className="sublinks">
                                                <li>
                                                    <a href="/plastic-chairs">
                                                        Plastic Chairs
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/plastic-tables">
                                                        Plastic Tables
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/plastic-stool">
                                                        Plastic Stool
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/plastic-cabinets">
                                                        Plastic Cabinets
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/plastic-rack">
                                                        Plastic Rack
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/pvc-chair">
                                                        PVC Chair
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/pvc-table">
                                                        PVC Table
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a
                                                href="/"
                                                className="title"
                                            >
                                                Furniture Accessories
                                            </a>
                                            <ul className="sublinks">
                                                <li>
                                                    <a href="/table-top">
                                                        Table Top
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/stretcher">
                                                        Stretcher
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/bed-frames">
                                                        Bed Frames
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/chair-base">
                                                        Chair Base
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/chair-handles">
                                                        Chair Handles
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/sofa-leg">
                                                        Sofa Leg
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/rack-panels">
                                                        Rack Panels
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <div className="text-center">
                                        <a
                                            href="/"
                                            className="view"
                                        >
                                            View All Categories in Furniture{" "}
                                            {" "}
                                        </a>
                                    </div>
                                </div> */}
                <div className="container">
                  <ul className="submenu-wrap">
                    <li>
                      <Link to="#" className="title">
                        Men's Clothing
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">T-Shirts</Link>
                        </li>
                        <li>
                          <Link to="#">Shirts</Link>
                        </li>
                        <li>
                          <Link to="#">Jeans</Link>
                        </li>
                        <li>
                          <Link to="#">Trousers</Link>
                        </li>
                        <li>
                          <Link to="#">Suits</Link>
                        </li>
                        <li>
                          <Link to="#">Jackets</Link>
                        </li>
                        <li>
                          <Link to="#">Activewear</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Women's Clothing
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Dresses</Link>
                        </li>
                        <li>
                          <Link to="#">Tops</Link>
                        </li>
                        <li>
                          <Link to="#">Skirts</Link>
                        </li>
                        <li>
                          <Link to="#">Pants</Link>
                        </li>
                        <li>
                          <Link to="#">Blouses</Link>
                        </li>
                        <li>
                          <Link to="#">Sweaters</Link>
                        </li>
                        <li>
                          <Link to="#">Outerwear</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Kid's Clothing
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Baby Clothing</Link>
                        </li>
                        <li>
                          <Link to="#">Toddler Clothing</Link>
                        </li>
                        <li>
                          <Link to="/#">Boy's Clothing</Link>
                        </li>
                        <li>
                          <Link to="#">Girl's Clothing</Link>
                        </li>
                        <li>
                          <Link to="#">School Uniforms</Link>
                        </li>
                        <li>
                          <Link to="#">Sleepwear</Link>
                        </li>
                        <li>
                          <Link to="#">Activewear</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Footwear
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Men's Footwear</Link>
                        </li>
                        <li>
                          <Link to="#">Women's Footwear</Link>
                        </li>
                        <li>
                          <Link to="#">Kid's Footwear</Link>
                        </li>
                        <li>
                          <Link to="#">Sports Shoes</Link>
                        </li>
                        <li>
                          <Link to="#">Casual Shoes</Link>
                        </li>
                        <li>
                          <Link to="#">Formal Shoes</Link>
                        </li>
                        <li>
                          <Link to="#">Sandals & Slippers</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#" className="title">
                        Accessories
                      </Link>
                      <ul className="sublinks">
                        <li>
                          <Link to="#">Bags & Purses</Link>
                        </li>
                        <li>
                          <Link to="#">Belts</Link>
                        </li>
                        <li>
                          <Link to="#">Hats & Caps</Link>
                        </li>
                        <li>
                          <Link to="#">Scarves</Link>
                        </li>
                        <li>
                          <Link to="#">Sunglasses</Link>
                        </li>
                        <li>
                          <Link to="#">Watches</Link>
                        </li>
                        <li>
                          <Link to="#">Jewelry</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="text-center">
                    <Link to="#" className="view">
                      View All Categories in Fashion
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <h2 id="trending-category"> Trending Categories </h2>

      <div id="phone-category" className="tc-list">
        <div className="tcl-iteam">
          <Link to="/#?search=footwear">
            <div className="tc-img">
              <img
                src="./assets/1foot.jpeg"
                alt="Footwear for Men, Women, and Kids"
                width={200}
                height={200}
                decoding="async"
                fetchpriority="low"
              />
            </div>
            <div className="tc-title">Men, Women &amp; Kids Footwear</div>
          </Link>
        </div>
        <div className="tcl-iteam">
          <Link to="#">
            <div className="tc-img">
              <img
                src="./assets/images-bed.jpeg"
                alt="Furniture"
                width={200}
                height={200}
                decoding="async"
                fetchpriority="low"
              />
            </div>
            <div className="tc-title">Wood and Lumber</div>
          </Link>
        </div>
        <div className="tcl-iteam">
          <Link to="/">
            <div className="tc-img">
              <img
                src="/assets/curt.jpeg"
                alt="Drapes and Curtains"
                width={200}
                height={200}
                decoding="async"
                fetchpriority="low"
              />
            </div>
            <div className="tc-title">Curtains and Drapers</div>
          </Link>
        </div>
        <div className="tcl-iteam">
          <Link to="/">
            <div className="tc-img">
              <img
                src="./assets/agri.jpeg"
                alt="# Equipment and Supplies"
                width={200}
                height={200}
                loading="lazy"
                decoding="async"
                fetchpriority="low"
              />
            </div>
            <div className="tc-title"># Equipment and Supplies</div>
          </Link>
        </div>
        <div className="tcl-iteam">
          <Link to="/foodproductstore?search=food">
            <div className="tc-img">
              <img
                src="./assets/food.jpeg"
                alt="Food & #"
                width={200}
                height={200}
                loading="lazy"
                decoding="async"
                fetchpriority="low"
              />
            </div>
            <div className="tc-title">Food & #</div>
          </Link>
        </div>
        <div className="tcl-iteam">
          <Link to="#">
            <div className="tc-img">
              <img
                src="./assets/suitcase.jpeg"
                alt="Portfolio, Suitcase, Briefcases, & Laptop Bags"
                width={200}
                height={200}
                loading="lazy"
                decoding="async"
                fetchpriority="low"
              />
            </div>
            <div className="tc-title">
              Portfolio, Suitcase, Briefcases, & Laptop Bags
            </div>
          </Link>
        </div>
        <div className="tcl-iteam">
          <Link to="#">
            <div className="tc-img">
              <img
                src="./assets/dress.jpeg"
                alt="Clothing & Dress"
                width={200}
                height={200}
                loading="lazy"
                decoding="async"
                fetchpriority="low"
              />
            </div>
            <div className="tc-title">Clothing & Dress</div>
          </Link>
        </div>
        <div className="tcl-iteam">
          <Link to="/medicinestore?search=medicine">
            <div className="tc-img">
              <img
                src="./assets/needles.jpeg"
                alt="Surgical Needles & Scissors"
                width={200}
                height={200}
                loading="lazy"
                decoding="async"
                fetchpriority="low"
              />
            </div>
            <div className="tc-title">Surgical Needles &amp; Scissors</div>
          </Link>
        </div>
        <div className="tcl-iteam">
          <Link to="/seat/covers?search=covers">
            <div className="tc-img">
              <img
                src="./assets/tent.jpeg"
                alt="Covers, Tarpaulins & Tents"
                width={200}
                height={200}
                loading="lazy"
                decoding="async"
                fetchpriority="low"
              />
            </div>
            <div className="tc-title">Covers, Tarpaulins &amp; Tents</div>
          </Link>
        </div>
        <div className="tcl-iteam">
          <Link to="#">
            <div className="tc-title">Kitchenware</div>
          </Link>
        </div>
        <div className="tcl-iteam">
          <Link to="#">
            <div className="tc-img">
              <img
                src="./assets/ac.jpeg"
                alt="Domestic Fans, AC & Coolers"
                width={200}
                height={200}
                loading="lazy"
                decoding="async"
                fetchpriority="low"
              />
            </div>
            <div className="tc-title">Domestic Fans, AC &amp; Coolers</div>
          </Link>
        </div>
        <div className="tcl-iteam">
          <Link to="/bathwaredealers?search=bucket">
            <div className="tc-img">
              <img
                src="./assets/washroom.jpeg"
                alt="Storage Bins & Buckets, Mugs"
                width={200}
                height={200}
                loading="lazy"
                decoding="async"
                fetchpriority="low"
              />
            </div>
            <div className="tc-title">Storage Bins &amp; Buckets, Mugs</div>
          </Link>
        </div>
        <div className="tcl-iteam">
          <Link to="/beautyproductsdealer?search=beauty">
            <div className="tc-img">
              <img
                src="./assets/health.jpeg"
                alt="Health & Beauty Products"
                width={200}
                height={200}
                loading="lazy"
                decoding="async"
                fetchpriority="low"
              />
            </div>
            <div className="tc-title">Health & Beauty Products</div>
          </Link>
        </div>
        <div className="tcl-iteam">
          <Link to="#">
            <div className="tc-img">
              <img
                src="./assets#.jpeg"
                alt="Chemicals"
                width={200}
                height={200}
                loading="lazy"
                decoding="async"
                fetchpriority="low"
              />
            </div>
            <div className="tc-title">Chemicals</div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default MaincountryCateg;
