import React, { useState, useEffect } from "react";
import "./OnionBuy.css"; // Assuming the same CSS file can be used, otherwise create a new CSS file.
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import Whyglobal from "../Components/Whyglobal";

const CoconutOilBuys = [
  {
    _id: "667eb3f09644befaa95480c1",
    name: "SUROJ ENTERPRISE",
    email: "sujeetkumar41d@gmail.com",
    mobileNumber: "7561983539",
    locationandDate: "Poland 12-01-2025",
    lookingFor: "High-quality coconut oil for export",
    productOrService: "coconut oil",
    quantity: 15,
    unit: "Ton",
    __v: 0,
  },
  {
    _id: "667eb3f09644befaa95480c2",
    name: "SHREE TRADERS",
    email: "shreetraders@gmail.com",
    mobileNumber: "9845012345",
    locationandDate: " China 11-01-2025",
    lookingFor: "Premium grade coconut oil for food processing",
    productOrService: "coconut oil",
    quantity: 20,
    unit: "Ton",
    __v: 0,
  },
  {
    _id: "667eb3f09644befaa95480c3",
    name: "GANESH OIL MILLS",
    email: "ganeshoilmills@gmail.com",
    mobileNumber: "9023456781",
    locationandDate: " India 10-01-2025",
    lookingFor: "Bulk coconut oil for industrial use",
    productOrService: "coconut oil",
    quantity: 25,
    unit: "Ton",
    __v: 0,
  },
  {
    _id: "667eb3f09644befaa95480c4",
    name: "NATURAL OIL SUPPLIERS",
    email: "naturaloil@gmail.com",
    mobileNumber: "9632587410",
    locationandDate: "Gurugram 09-01-2025",
    lookingFor: "Cold-pressed coconut oil for health products",
    productOrService: "coconut oil",
    quantity: 12,
    unit: "Ton",
    __v: 0,
  },
  {
    _id: "667eb3f09644befaa95480c5",
    name: "GREEN EARTH ENTERPRISES",
    email: "greenearth@gmail.com",
    mobileNumber: "8887766554",
    locationandDate: "15-07-2025",
    lookingFor: "Organic coconut oil for cosmetic production",
    productOrService: "coconut oil",
    quantity: 18,
    unit: "Ton",
    __v: 0,
  },
  {
    _id: "667eb3f09644befaa95480c0",
    name: "SUROJ ENTERPRISE",
    email: "sujeetkumar41d@gmail.com",
    mobileNumber: "7561983539",
    locationandDate: "12-01-2025",
    lookingFor: "Urgently required coconut oil",
    productOrService: "coconut oil",
    quantity: 10,
    unit: "Ton",
    __v: 0,
  },
  {
    _id: "667eb4829644befaa95480c9",
    name: "Pranam",
    email: "pranamjaglan257@gmail.com",
    mobileNumber: "7355535959",
    locationandDate: "12-01-2025",
    lookingFor: "coconut oil Reselling",
    productOrService: "coconut oil",
    quantity: 1000,
    unit: "kg",
    __v: 0,
  },
  {
    _id: "667eb4ae9644befaa95480cb",
    name: "Randhawa Farms",
    email: "randhawafarmspremiumproducts@gmail.com",
    mobileNumber: "9878788883",
    locationandDate: "12-01-2025",
    lookingFor: "coconut oil required",
    productOrService: "coconut oil",
    quantity: 100,
    unit: "kg",
    __v: 0,
  },
  {
    _id: "667eb4d89644befaa95480cd",
    name: "Karthik",
    email: "kdk2981@gmail.com",
    mobileNumber: "6381881487",
    locationandDate: "12-01-2025",
    lookingFor: "coconut oil",
    productOrService: "coconut oil",
    quantity: 2,
    unit: "Ton",
    __v: 0,
  },
];

const CoconutOilBuy = () => {
  const { userId } = useAuth();
  const [userIsPremium, setUserIsPremium] = useState(false);
  const [shownNumbers, setShownNumbers] = useState([]);
  const [leadsViewed, setLeadsViewed] = useState(0);

  useEffect(() => {
    if (!userId) return;

    // Load the viewed leads from local storage
    const storedViewedLeads =
      JSON.parse(localStorage.getItem(`coconutOilViewedLeads_${userId}`)) || [];
    setShownNumbers(storedViewedLeads);

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/getUserWithPremiumStatus/${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserIsPremium(data.isPremium);
        setLeadsViewed(data.leadsViewed);
        console.log("Premium Status:", data.isPremium);
      })
      .catch((error) => {
        console.error("Error fetching user information:", error);
      });
  }, [userId]);

  const toggleNumber = async (index) => {
    if (!userIsPremium) return; // Only premium users can see numbers

    if (leadsViewed >= 25) {
      alert("You have reached the limit of 25 leads viewed per month.");
      return;
    }

    if (shownNumbers.includes(index)) return; // If already shown, do nothing

    const updatedShownNumbers = [...shownNumbers, index];
    setShownNumbers(updatedShownNumbers);

    // Update local storage
    localStorage.setItem(
      `coconutOilViewedLeads_${userId}`,
      JSON.stringify(updatedShownNumbers)
    );

    const buyer = CoconutOilBuys[index]; // Get the buyer information

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/incrementLeadsViewed/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            buyerName: buyer.name,
            mobileNo: buyer.mobileNumber,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLeadsViewed(data.leadsViewed);
      } else {
        const errorData = await response.json();
        console.error("Error incrementing leads viewed:", errorData.error);
      }
    } catch (error) {
      console.error("Error incrementing leads viewed:", error.message);
    }
  };

  return (
    <>
      <div className="container">
        <ol className="breadcrumb">
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px 30px",
            }}
            className="breadcrumb-item"
          >
            <Link to={"/"}>Home</Link>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px -25px",
            }}
          >
            /
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px 30px",
            }}
            className="breadcrumb-item active"
          >
            Fresh Leads
          </li>
        </ol>
      </div>
      <div className="containersx">
        <div style={{ width: "200px", padding: "10px" }} className="browsers">
          <h3 style={{ marginTop: "30px" }}>Browse by State</h3>
          <ul className="flt-list cust-scroll" id="state-lists">
            <li>
              <Link to="#">All India</Link>
            </li>
            <li>
              <Link to="#">Delhi</Link>
            </li>
            <li>
              <Link to="#">Maharashtra</Link>
            </li>
            <li>
              <Link to="#">Karnataka</Link>
            </li>
            <li>
              <Link to="#">West Bengal</Link>
            </li>
            <li>
              <Link to="#">Tamil Nadu</Link>
            </li>
          </ul>

          <ul style={{ marginTop: "20px" }} className="flt-list cust-scroll">
            <h3>All Category</h3>
            <li>
              <Link to="#">Coconut Oil</Link>
            </li>
          </ul>
        </div>
        <div className="buyers">
          {CoconutOilBuys.map((buyer, index) => (
            <div key={index} className="buyer-card">
              <h2 style={{ color: "red" }}> {buyer.lookingFor}</h2>
              <p>
                <strong>Buyer Name:</strong> {buyer.name}
              </p>
              <p>
                <strong>Quantity:</strong> {buyer.quantity} {buyer.unit}
              </p>
              <p>
                <strong>Date:</strong> {buyer.locationandDate}{" "}
              </p>
              <p>
                <strong>Mobile No.:</strong>{" "}
                {userIsPremium
                  ? shownNumbers.includes(index)
                    ? buyer.mobileNumber
                    : "********"
                  : "********"}
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                {userIsPremium && (
                  <button
                    className="detail-btn"
                    style={{
                      padding: "5px 10px",
                      background: "#f7f1f1",
                      color: "orange",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleNumber(index)}
                  >
                    Click to see
                  </button>
                )}
              </div>
              <div className="btns-imp">
                <Link to={"/login"}>
                  <button className="detailing-btn">View More</button>
                </Link>
                <Link to={"/register-Company"}>
                  <button className="regis-btn">Register Now</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="sidebar">
          <div className="mains-slide">
            <div className="tk23-hkCont_onergt">
              <div className="tk23-hkCont_premserv__box">
                <h1 className="tk23-hkCont_premserv__ttl">
                  Our Membership Plans
                </h1>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Standard Plan
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Advance Plan
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Premium Plan
                      </span>
                    </div>
                  </Link>
                  <Link to={"/packages"}>
                    <div className="reach">
                      <span className="reahhere">Call Now</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Whyglobal />
      </div>
    </>
  );
};

export default CoconutOilBuy;
