import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./FreeCataloge.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faShieldHalved,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const products = [
  { id: 1, name: "Plastic Chairs", image: "./assets/plastic1.jpg" },
  { id: 3, name: "Plastic Tables", image: "./assets/plastic2.jpg" },
  { id: 5, name: "Plastic Storage", image: "./assets/plastic3.jpg" },
  { id: 6, name: "Plastic Containers", image: "./assets/plastic4.jpg" },
  { id: 1, name: "Plastic Furniture", image: "./assets/plastic-fur.jpg" },
  { id: 3, name: "Toilet Paper Holder", image: "./assets/plastic6.jpg" },
  { id: 5, name: "Plastic Tables", image: "./assets/plastic1.jpg" },
  { id: 6, name: "Plastic Chairs", image: "./assets/plastic2.jpg" },
];

const DhirenCataloge2 = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [highlight, setHighlight] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleReachEnd = () => {
    if (swiperInstance) {
      const isEnd = swiperInstance.isEnd;
      const navigationButton = document.querySelector(".swiper-button-next");
      if (navigationButton) {
        navigationButton.style.display = isEnd ? "none" : "block";
      }
    }
  };

  const handleReachStart = () => {
    if (swiperInstance) {
      const isBeginning = swiperInstance.isBeginning;
      const navigationButton = document.querySelector(".swiper-button-prev");
      if (navigationButton) {
        navigationButton.style.display = isBeginning ? "none" : "block";
      }
    }
  };

  const handleProductClick = () => {
    setActiveSection("products");
  };

  const renderContent = () => {
    switch (activeSection) {
      case "products":
        return (
          <div className="swiper-container">
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              onSwiper={setSwiperInstance}
              onReachEnd={handleReachEnd}
              onReachBeginning={handleReachStart}
              breakpoints={{
                640: { slidesPerView: 2, spaceBetween: 20 },
                768: { slidesPerView: 4, spaceBetween: 40 },
                1024: { slidesPerView: 7, spaceBetween: 30 },
              }}
              className="mySwiper"
            >
              {products.map((product) => (
                <SwiperSlide key={`product-${product.id}`}>
                  <div className="card" onClick={handleProductClick}>
                    <img src={product.image} alt={product.name} />
                    <p>{product.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        );

      default:
        return (
          <div>
            <div className="swiper-container">
              <Swiper
                modules={[Autoplay, Navigation, Pagination]}
                slidesPerView={1}
                spaceBetween={10}
                pagination={{ clickable: true }}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onSwiper={setSwiperInstance}
                onReachEnd={handleReachEnd}
                onReachBeginning={handleReachStart}
                breakpoints={{
                  640: { slidesPerView: 2, spaceBetween: 20 },
                  768: { slidesPerView: 4, spaceBetween: 40 },
                  1024: { slidesPerView: 7, spaceBetween: 30 },
                }}
                className="mySwiper"
              >
                {products.map((product) => (
                  <SwiperSlide key={`product-${product.id}`}>
                    <div className="card">
                      <img src={product.image} alt={product.name} />
                      <p>{product.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="welcome">
              <h1>Welcome to the Dhiren Plastic Industries Company</h1>
              <p>
                Dhiren Plastic Industries is a trusted name in the manufacturing
                and supply of high-quality plastic bottles. With years of
                experience in the industry, we specialize in providing durable,
                reliable, and eco-friendly packaging solutions tailored to meet
                the diverse needs of our customers. Our product range includes a
                wide variety of plastic bottles designed for multiple
                industries, including pharmaceuticals, food and beverages,
                cosmetics, and household products. Available in various shapes,
                sizes, and colors, our bottles are crafted using premium-grade
                materials to ensure they are lightweight, leak-proof, and
                long-lasting.{" "}
              </p>
              <h2>We Deals in All kind of Bathware Products</h2>
              <p>
                Dhiren Plastic Industries, we prioritize innovation and
                sustainability. Our manufacturing processes are designed to
                reduce waste and adhere to environmentally conscious practices.
                We also offer customizable solutions, enabling businesses to
                personalize bottles with unique designs, logos, and branding.
                Customer satisfaction is our top priority. With a focus on
                quality, timely delivery, and competitive pricing, we have built
                a reputation as a reliable partner for businesses seeking
                superior plastic packaging. Whether you need small or bulk
                orders, our team is committed to meeting your requirements with
                excellence.
              </p>
              {/* <h2>Our Products :</h2> */}
              <p>
                Dhiren Plastic Industries, we understand that every customer has
                unique tastes and requirements. That’s why we offer customizable
                designs and an extensive palette of colors to complement any
                décor. Our ergonomic designs are tailored to provide maximum
                comfort, making them suitable for both casual and professional
                settings.
              </p>
              <p>
                In addition to quality and style, we prioritize sustainability
                by adopting eco-friendly manufacturing practices. Our production
                processes are designed to minimize waste and reduce our
                environmental footprint, ensuring that our furniture aligns with
                modern sustainability standards. Customer satisfaction is the
                cornerstone of our business. With a dedicated team of
                professionals, we ensure prompt delivery, competitive pricing,
                and exceptional after-sales service. Over the years, we have
                built a loyal customer base, thanks to our unwavering commitment
                to quality and reliability.
              </p>
              {/* <p>Unmatched Expertise
                                With years of experience in the elevator industry, our skilled team brings unparalleled knowledge and proficiency to every project.</p> */}

              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets/plastic8.jpeg"
                      title="Kashmiri Saffron Threads"
                      alt="Kashmiri Saffron Threads"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Stainless Steel Triangle Shelf</h3>
                    </div>
                    <div className="pro_det_sec">
                      <ul className="attributes">
                        <li>
                          <span>Brand:</span> Dhiren Plastic Industries
                        </li>
                        <li>
                          <span>Product:</span> Stainless Steel Triangle Shelf
                        </li>
                        <li>
                          <span>Material:</span> Crafted from durable,
                          corrosion-resistant stainless steel, designed for
                          longevity and reliability
                        </li>
                        <li>
                          <span>Shape:</span> Triangle - Optimally designed to
                          utilize corner spaces efficiently, perfect for compact
                          areas
                        </li>
                        <li>
                          <span>Size:</span> 7 inches in width - Spacious enough
                          to store toiletries, kitchen essentials, or decorative
                          items with ease
                        </li>
                        <li>
                          <span>Number of Shelves:</span> 1 - A practical
                          single-shelf design, adaptable for various
                          applications
                        </li>
                        <li>
                          <span>Color:</span> Elegant silver finish - Enhances
                          sophistication while seamlessly blending with any
                          interior style
                        </li>
                        <li>
                          <span>Mounting Type:</span> Wall-mounted - Easy to
                          install, sturdy, and secure with included mounting
                          hardware
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="company_header">
        <div className="top_head">
          <div className="company_left zdasds">
            <span className="logo_img2" style={{ background: "#d1d786" }}>
              CF
            </span>

            <div className="company_info">
              <div className="company_name2">
                <h1>
                  Dhiren Plastic Industries{" "}
                  <div className="_oth_info">
                    <a
                      href="https://www.google.co.in/maps/dir//12.9715987,77.5945627"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <b className="fa fa-map-marker" />
                        Jaipur, Rajasthan, India{" "}
                      </span>
                    </a>
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_head">
          <div className={`Premium-Header ${highlight ? "highlight" : ""}`}>
            <ul className="menu_sec">
              <li>
                Home <FontAwesomeIcon icon={faGauge} />
              </li>
              <li>
                Products <FontAwesomeIcon icon={faShieldHalved} />
              </li>
              <li>
                About Us <FontAwesomeIcon icon={faMessage} />
                <ul className="dropdown"></ul>
              </li>
              <li>
                Contact Us <FontAwesomeIcon icon={faShieldHalved} />
                <ul className="dropdown"></ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {renderContent()}
    </>
  );
};

export default DhirenCataloge2;
