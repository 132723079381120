import React, { useState, useEffect } from "react";
import "./OnionBuy.css"; // Assuming the same CSS file can be used, otherwise create a new CSS file.
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import Whyglobal from "../Components/Whyglobal";

const MachineBuys = [
  {
    _id: "66869a299f2cecbc7cc11437",
    name: "VINAY",
    email: "uwithme1@gmail.com",
    mobileNumber: "+917483369139",
    lookingFor: "Agarbatti Machine, Urgent Purchase Request",
    locationandDate: "Bengaluru, 09-01-2025",
    productOrService: "Agarbatti Machine",
    quantity: 200,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11438",
    name: "Nabisab Marali",
    email: "nabisabnabisab780@gmail.com",
    mobileNumber: "+917676873622",
    lookingFor: "Paper Plate Making Machine, Urgent Purchase Request",
    locationandDate: "Gangavati, 09-01-2025",
    productOrService: "Paper Plate Making Machine",
    quantity: 50,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11439",
    name: "Deveppa H. Badagir",
    email: "deveppa.db96@gmail.com",
    mobileNumber: "+919611278829",
    lookingFor: "Agarbatti Machine, Urgent Purchase Request",
    locationandDate: "Kushtagi, 11-01-2025",
    productOrService: "Agarbatti Machine",
    quantity: 100,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc1143A",
    name: "Gpm Athani",
    email: "ganeshmore26497@hotmail.com",
    mobileNumber: "+919980828051",
    lookingFor: "Paper Plate Machine, Urgent Purchase Request",
    locationandDate: "Athani, 11-01-2025",
    productOrService: "Paper Plate Machine",
    quantity: 30,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc1143B",
    name: "Sumalatha G C",
    email: "ssumalathagc@gmail.com",
    mobileNumber: "+917899824172",
    lookingFor: "Atta Making Machine, Urgent Purchase Request",
    locationandDate: "Bangalore, 11-01-2025",
    productOrService: "Atta Making Machine",
    quantity: 20,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc1143C",
    name: "Lagamanna Pujeri",
    email: "lagamannapujeri63944@gmail.com",
    mobileNumber: "+919972204242",
    lookingFor: "Agarbatti Machine, Urgent Purchase Request",
    locationandDate: "Belgaum, 10-01-2025",
    productOrService: "Agarbatti Machine",
    quantity: 60,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc1143D",
    name: "Sharankumar Ik",
    email: "ikariyannvrsharankumar@gmail.com",
    mobileNumber: "+918618914815",
    lookingFor: "Paper Plate Making Machine, Urgent Purchase Request",
    locationandDate: "Bagalkot, 10-01-2025",
    productOrService: "Paper Plate Making Machine",
    quantity: 10,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc1143E",
    name: "Praveen Siddu",
    email: "siddupraveen70@gmail.com",
    mobileNumber: "+919620910150",
    lookingFor: "Atta Making Machine, Urgent Purchase Request",
    locationandDate: "Malavalli, 10-01-2025",
    productOrService: "Atta Making Machine",
    quantity: 15,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc1143F",
    name: "Dablu Jcb",
    email: "dablujcb71@gmail.com",
    mobileNumber: "+918088617717",
    lookingFor: "Agarbatti Machine, Urgent Purchase Request",
    locationandDate: "Bihar, 10-01-2025",
    productOrService: "Agarbatti Machine",
    quantity: 120,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11440",
    name: "Gopi Gopi",
    email: "shettygopi10@gmail.com",
    mobileNumber: "+917019687885",
    lookingFor: "Paper Plate Machine, Urgent Purchase Request",
    locationandDate: "Mysore, 09-01-2025",
    productOrService: "Paper Plate Machine",
    quantity: 25,
    unit: "pcs",
  },

  {
    _id: "66869a299f2cecbc7cc11437",
    name: "VINAY",
    email: "uwithme1@gmail.com",
    mobileNumber: "+917483369139",
    lookingFor: "Agarbatti Machine, Urgent Purchase Request",
    locationandDate: "Bengaluru, 09-01-2025",
    productOrService: "Agarbatti Machine",
    quantity: 200,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11438",
    name: "Deveppa.h. Badagir",
    email: "deveppa.db96@gmail.com",
    mobileNumber: "+919611278829",
    lookingFor: "Atta Making Machine, Urgently Needed",
    locationandDate: "Kushtagi, 09-01-2025",
    productOrService: "Atta Making Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11441",
    name: "Suresh Mathapati",
    email: "sureshmathapati001@gmail.com",
    mobileNumber: "+918660203311",
    lookingFor: "Agarbatti Machine, Urgent Purchase Request",
    locationandDate: "Hungund, 09-01-2025",
    productOrService: "Agarbatti Machine",
    quantity: 80,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11442",
    name: "Jyotiba Patil",
    email: "jyotibapatil02111998@gmail.com",
    mobileNumber: "+919902518416",
    lookingFor: "Paper Plate Machine, Urgent Purchase Request",
    locationandDate: "Chikodi Kerurwadi, 09-01-2025",
    productOrService: "Paper Plate Machine",
    quantity: 9000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11443",
    name: "Ningaraj Hosamani",
    email: "ningarajhosamani8@gmail.com",
    mobileNumber: "+917795278840",
    lookingFor: "Agarbatti Machine, Urgent Purchase Request",
    locationandDate: "Bagalkot, 09-01-2025",
    productOrService: "Agarbatti Machine",
    quantity: 150,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11444",
    name: "Jyoti Davaleshwar",
    email: "shivujyoti2018@gmail.com",
    mobileNumber: "+916364380433",
    lookingFor: "Paper Plate Making Machine, Urgent Purchase Request",
    locationandDate: "Darur, 09-01-2025",
    productOrService: "Paper Plate Making Machine",
    quantity: 60,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11445",
    name: "Pachchi N R",
    email: "Pmadiwalar243@gmail.com",
    mobileNumber: "+917022348360",
    lookingFor: "Atta Making Machine, Urgent Purchase Request",
    locationandDate: "Bijapur, 09-01-2025",
    productOrService: "Atta Making Machine",
    quantity: 25,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11446",
    name: "Chandu Gowdru",
    email: "kingchandu619@gmail.com",
    mobileNumber: "+918088671711",
    lookingFor: "Agarbatti Machine, Urgent Purchase Request",
    locationandDate: "Kunigal, 09-01-2025",
    productOrService: "Agarbatti Machine",
    quantity: 70,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11447",
    name: "Santhosh",
    email: "nandhimasalafoods@gmail.com",
    mobileNumber: "+919538475740",
    lookingFor: "Paper Plate Machine, Urgent Purchase Request",
    locationandDate: "Bangalore, 09-01-2025",
    productOrService: "Paper Plate Machine",
    quantity: 20,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11448",
    name: "Harshavardhan",
    email: "harshavardhanpujari@gmail.com",
    mobileNumber: "+919591581324",
    lookingFor: "Atta Making Machine, Urgent Purchase Request",
    locationandDate: "Bijapur, 09-01-2025",
    productOrService: "Atta Making Machine",
    quantity: 40,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11449",
    name: "Sharanu Bhure",
    email: "sharnubhure1@gmail.com",
    mobileNumber: "+917483972300",
    lookingFor: "Agarbatti Machine, Urgent Purchase Request",
    locationandDate: "Bhalki, 09-01-2025",
    productOrService: "Agarbatti Machine",
    quantity: 120,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc1144A",
    name: "Ananya A",
    email: "kavitaaneppanavar@gmail.com",
    mobileNumber: "+918123835294",
    lookingFor: "Paper Plate Machine, Urgent Purchase Request",
    locationandDate: "Mangalore, 09-01-2025",
    productOrService: "Paper Plate Machine",
    quantity: 50,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc1144B",
    name: "Gopi Badami",
    email: "gopibadami8172@gmail.com",
    mobileNumber: "+917619444893",
    lookingFor: "atta making machine, Urgent Purchase Request",
    locationandDate: "Hirekerur, 09-01-2025",
    productOrService: "atta making machine",
    quantity: "Yas",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc1144C",
    name: "Veeru Salient Boy",
    email: "veereshbhoosanurmath51@gmail.com",
    mobileNumber: "+919071967191",
    lookingFor: "Paper Plate Machine, Urgent Purchase Request",
    locationandDate: "Gajendragad, 09-01-2025",
    productOrService: "Paper Plate Machine",
    quantity: "Veeresh Bhoosanurmath",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc1144D",
    name: "Jayappa",
    email: "jayappagejjebavi@gmal.com",
    mobileNumber: "+916361374521",
    lookingFor: "atta making machine, Urgent Purchase Request",
    locationandDate: "Rachira-584126, 09-01-2025",
    productOrService: "atta making machine",
    quantity: "Jayappa",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc1144E",
    name: "Sanjay Sanjay",
    email: "sanjaysanjay22748@gmail.com",
    mobileNumber: "+917795629989",
    lookingFor: "Paper Plate Machine, Urgent Purchase Request",
    locationandDate: "Hubli, 09-01-2025",
    productOrService: "Paper Plate Machine",
    quantity: "Yas",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc1144F",
    name: "Veeresh Kademani",
    email: "irappa.basappa.kademani@gmail.com",
    mobileNumber: "+919113028905",
    lookingFor: "Paper Plate Making Machine, Urgent Purchase Request",
    locationandDate: "Dharwad, 09-01-2025",
    productOrService: "Paper Plate Making Machine",
    quantity: "Yes",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11450",
    name: "Tirupati",
    email: "tirupatiyadav143@gmail.com",
    mobileNumber: "+919590777163",
    lookingFor: "atta making machine, Urgent Purchase Request",
    locationandDate: "Yadgir, 09-01-2025",
    productOrService: "atta making machine",
    quantity: 1,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11451",
    name: "Shrishail Modify",
    email: "roopamodi91@gmail.com",
    mobileNumber: "+919113929360",
    lookingFor: "Paper Plate Machine, Urgent Purchase Request",
    locationandDate: "Jamkhandi, 09-01-2025",
    productOrService: "Paper Plate Machine",
    quantity: "Yes",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11452",
    name: "ಲಕ್ಷ್ಮಣ್ ಗೊಲ್ಲರ್",
    email: "gollarlakshman4@gmail.com",
    mobileNumber: "+918548002040",
    lookingFor: "atta making machine, Inquiry About Subsidy",
    locationandDate: "ಕೊಪ್ಪಳ, 09-01-2025",
    productOrService: "atta making machine",
    quantity: "Inquiry about materials and profits",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11453",
    name: "Mohan Kumar",
    email: "mohannskumar90233@gmail.com",
    mobileNumber: "+917259802694",
    lookingFor: "atta making machine, Urgent Purchase Request",
    locationandDate: "ಚಿಕ್ಕಮಗಳೂರು, 09-01-2025",
    productOrService: "atta making machine",
    quantity: "Pepar plat mishn",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11439",
    name: "Gpm Athani",
    email: "ganeshmore26497@hotmail.com",
    mobileNumber: "+919980828051",
    lookingFor: "paper plate machine, Immediate Requirement",
    locationandDate: "Athani, 08-01-2025",
    productOrService: "Paper Plate Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11440",
    name: "Sumalatha G C Sumakumar",
    email: "ssumalathagc@gmail.com",
    mobileNumber: "+917899824172",
    lookingFor: "Agarbatti Machine, Required Soon",
    locationandDate: "Bangalore, 08-01-2025",
    productOrService: "Agarbatti Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11441",
    name: "Lagamanna Pujeri",
    email: "lagamannapujeri63944@gmail.com",
    mobileNumber: "+919972204242",
    lookingFor: "Atta Making Machine, Required Immediately",
    locationandDate: "Belgaum, 07-01-2025",
    productOrService: "Atta Making Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11442",
    name: "Sharankumar Ik",
    email: "ikariyannvrsharankumar@gmail.com",
    mobileNumber: "+918618914815",
    lookingFor: "paper plate machine, Urgently Required",
    locationandDate: "Bagalkot, 07-01-2025",
    productOrService: "Paper Plate Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11443",
    name: "Praveen Siddu",
    email: "siddupraveen70@gmail.com",
    mobileNumber: "+919620910150",
    lookingFor: "Agarbatti Machine, Immediate Requirement",
    locationandDate: "Malavalli, 07-01-2025",
    productOrService: "Agarbatti Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11444",
    name: "Dablu Jcb",
    email: "dablujcb71@gmail.com",
    mobileNumber: "+918088617717",
    lookingFor: "Atta Making Machine, Urgent Requirement",
    locationandDate: "Bihar, 06-01-2025",
    productOrService: "Atta Making Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11445",
    name: "Gopi Gopi",
    email: "shettygopi10@gmail.com",
    mobileNumber: "+917019687885",
    lookingFor: "paper plate machine, Immediate Need",
    locationandDate: "Mysore, 06-01-2025",
    productOrService: "Paper Plate Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11446",
    name: "Suresh Mathapati",
    email: "sureshmathapati001@gmail.com",
    mobileNumber: "+918660203311",
    lookingFor: "Agarbatti Machine, Required Urgently",
    locationandDate: "Hungund, 06-01-2025",
    productOrService: "Agarbatti Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11447",
    name: "jyotiba patil 1998 i love you 1234",
    email: "jyotibapatil02111998@gmail.com",
    mobileNumber: "+919902518416",
    lookingFor: "Atta Making Machine, Urgent Requirement",
    locationandDate: "Chikodi Kerurwadi, 06-01-2025",
    productOrService: "Atta Making Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11448",
    name: "Ningaraj Hosamani",
    email: "ningarajhosamani8@gmail.com",
    mobileNumber: "+917795278840",
    lookingFor: "paper plate machine, Required Immediately",
    locationandDate: "Bagalkot, 06-01-2025",
    productOrService: "Paper Plate Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11449",
    name: "Jyoti Davaleshwar",
    email: "shivujyoti2018@gmail.com",
    mobileNumber: "+916364380433",
    lookingFor: "Agarbatti Machine, Urgently Needed",
    locationandDate: "Darur, 06-01-2025",
    productOrService: "Agarbatti Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11450",
    name: "Pachchi N R",
    email: "Pmadiwalar243@gmail.com",
    mobileNumber: "+917022348360",
    lookingFor: "Atta Making Machine, Urgently Needed",
    locationandDate: "Bijapur, 05-01-2025",
    productOrService: "Atta Making Machine",
    quantity: 34,
    unit: "pcs",
  },

  {
    _id: "66869a299f2cecbc7cc11436",
    name: "Rohan Ray",
    email: "priyarani@gmail.com",
    mobileNumber: "9876543210",
    lookingFor: "Hydrolic Press Machine",
    locationandDate: "Gurgaon, 23-12-2024",
    productOrService: "paper Plate Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11436",
    name: "Suraj Pal",
    email: "priyarani@gmail.com",
    mobileNumber: "9876543210",
    lookingFor: "Hydrolic Press Machine",
    locationandDate: "jaipur, 23-12-2024",
    productOrService: "paper Plate Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11436",
    name: "mahesh Patel",
    email: "priyarani@gmail.com",
    mobileNumber: "9876543210",
    lookingFor: "Hydrolic Press Machine",
    locationandDate: "Gujatat, 05-01-2025",
    productOrService: "paper Plate Machine",
    quantity: 34,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11436",
    name: "Priya Rani",
    email: "priyarani@gmail.com",
    mobileNumber: "9876543210",
    lookingFor: "Dough Kneading Machine",
    locationandDate: "Delhi, 14-12-2024",
    productOrService: "paper Plate Machine",
    quantity: 16,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11437",
    name: "Vijay Kumar",
    email: "vijaykumar@gmail.com",
    mobileNumber: "9888776655",
    lookingFor: "Pizza Oven",
    locationandDate: "Mumbai, 14-12-2024",
    productOrService: "Paper Plate Making Machine",
    quantity: 3,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11438",
    name: "Ravi Singh",
    email: "ravisingh@gmail.com",
    mobileNumber: "9556443322",
    lookingFor: "Ice Cream Machine",
    locationandDate: "Bangalore, 12-12-2024",
    productOrService: "Ice Cream Machine",
    quantity: 1,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11439",
    name: "Nisha Gupta",
    email: "nishagupta@gmail.com",
    mobileNumber: "9665332211",
    lookingFor: "Bread Slicing Machine",
    locationandDate: "Chennai, 13-12-2024",
    productOrService: "Bread Slicing Machine",
    quantity: 2,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11440",
    name: "Sunil Yadav",
    email: "sunilyadav@gmail.com",
    mobileNumber: "9777223344",
    lookingFor: "French Fries Machine",
    locationandDate: "Hyderabad, 11-12-2024",
    productOrService: "French Fries Machine",
    quantity: 2,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11441",
    name: "Manju Sharma",
    email: "manjusharma@gmail.com",
    mobileNumber: "9966224477",
    lookingFor: "Catering Trolley",
    locationandDate: "Kolkata, 10-12-2024",
    productOrService: "Catering Trolley",
    quantity: 4,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11435",
    name: "Ahmad",
    email: "shamimcrp1967@gmail.com",
    mobileNumber: "9955333131",
    lookingFor: "Noodle Making Machine",
    locationandDate: "Kolkata 13-08-2024",
    productOrService: "Noodle Making Machine",
    quantity: 2,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11434",
    name: "SUHAIL AHMED",
    email: "suhailbawa@gmail.com",
    mobileNumber: "7871936724",
    lookingFor: "Pasta Making Machine",
    locationandDate: "Vellore 13-08-2024",
    productOrService: "Pasta Making Machine",
    quantity: 3,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11433",
    name: "Naveen",
    email: "kumarnaveen92764@gmail.com",
    mobileNumber: "9042152226",
    lookingFor: "Automatic Agarbatti Making Machine",
    locationandDate: "Nawada 11-08-2024",
    productOrService: "Automatic Agarbatti Making",
    quantity: 2,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11436",
    name: "nabisab marali",
    email: "nabisabnabisab780@gmail.com",
    mobileNumber: "+917676873622",
    lookingFor: "paper plate machine, Urgently Required",
    locationandDate: "Gangavati, 05-01-2025",
    productOrService: "Paper Plate Machine",
    quantity: 34,
    unit: "pcs",
  },
];

const MachineBuy = () => {
  const { userId } = useAuth();
  const [userIsPremium, setUserIsPremium] = useState(false);
  const [shownNumbers, setShownNumbers] = useState([]);
  const [leadsViewed, setLeadsViewed] = useState(0);

  useEffect(() => {
    if (!userId) return;

    // Load the viewed leads from local storage
    const storedViewedLeads =
      JSON.parse(localStorage.getItem(`machineViewedLeads_${userId}`)) || [];
    setShownNumbers(storedViewedLeads);

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/getUserWithPremiumStatus/${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserIsPremium(data.isPremium);
        setLeadsViewed(data.leadsViewed);
        console.log("Premium Status:", data.isPremium);
      })
      .catch((error) => {
        console.error("Error fetching user information:", error);
      });
  }, [userId]);

  const toggleNumber = async (index) => {
    if (!userIsPremium) return; // Only premium users can see numbers

    if (leadsViewed >= 25) {
      alert("You have reached the limit of 25 leads viewed per month.");
      return;
    }

    if (shownNumbers.includes(index)) return; // If already shown, do nothing

    const updatedShownNumbers = [...shownNumbers, index];
    setShownNumbers(updatedShownNumbers);

    // Update local storage
    localStorage.setItem(
      `machineViewedLeads_${userId}`,
      JSON.stringify(updatedShownNumbers)
    );

    const buyer = MachineBuys[index]; // Get the buyer information

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/incrementLeadsViewed/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            buyerName: buyer.name,
            mobileNo: buyer.mobileNumber,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLeadsViewed(data.leadsViewed);
      } else {
        const errorData = await response.json();
        console.error("Error incrementing leads viewed:", errorData.error);
      }
    } catch (error) {
      console.error("Error incrementing leads viewed:", error.message);
    }
  };

  return (
    <>
      <div className="container">
        <ol className="breadcrumb">
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px 30px",
            }}
            className="breadcrumb-item"
          >
            <Link to={"/"}>Home</Link>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px -25px",
            }}
          >
            /
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px 30px",
            }}
            className="breadcrumb-item active"
          >
            Fresh Leads
          </li>
        </ol>
      </div>
      <div className="containersx">
        <div style={{ width: "200px", padding: "10px" }} className="browsers">
          <h3 style={{ marginTop: "30px" }}>Browse by State</h3>
          <ul className="flt-list cust-scroll" id="state-lists">
            <li>
              <Link to="#">All India</Link>
            </li>
            <li>
              <Link to="#">Delhi</Link>
            </li>
            <li>
              <Link to="#">Maharashtra</Link>
            </li>
            <li>
              <Link to="#">Karnataka</Link>
            </li>
            <li>
              <Link to="#">West Bengal</Link>
            </li>
            <li>
              <Link to="#">Tamil Nadu</Link>
            </li>
          </ul>

          <ul style={{ marginTop: "20px" }} className="flt-list cust-scroll">
            <h3>All Category</h3>
            <li>
              <Link to="#">3D Ceramic Floor Tiles</Link>
            </li>
            <li>
              <Link to="#">Eye Drops</Link>
            </li>
            <li>
              <Link to="#">Maize</Link>
            </li>
            <li>
              <Link to="#">Rice</Link>
            </li>
            <li>
              <Link to="#">Wheat</Link>
            </li>
            <li>
              <Link to="#">Barley</Link>
            </li>
            <li>
              <Link to="#">Soybean</Link>
            </li>
          </ul>
        </div>
        <div className="buyers">
          {MachineBuys.map((buyer, index) => (
            <div key={index} className="buyer-card">
              <h2 style={{ color: "red" }}>{buyer.lookingFor}</h2>
              <p>
                <strong>Buyer Name:</strong> {buyer.name}
              </p>
              <p>
                <strong>Quantity:</strong> {buyer.quantity} {buyer.unit}
              </p>
              <p>
                <strong>Location & Date:</strong> {buyer.locationandDate}
              </p>
              <p>
                <strong>Mobile No.:</strong>{" "}
                {userIsPremium
                  ? shownNumbers.includes(index)
                    ? buyer.mobileNumber
                    : "********"
                  : "********"}
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                {userIsPremium && (
                  <button
                    className="detail-btn"
                    style={{
                      padding: "5px 10px",
                      background: "#f7f1f1",
                      color: "orange",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleNumber(index)}
                  >
                    Click to see
                  </button>
                )}
              </div>
              <div className="btns-imp">
                <Link to={"/login"}>
                  <button className="detailing-btn">View More</button>
                </Link>
                <Link to={"/register-Company"}>
                  <button className="regis-btn">Register Now</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="sidebar">
          <div className="mains-slide">
            <div className="tk23-hkCont_onergt">
              <div className="tk23-hkCont_premserv__box">
                <h1 className="tk23-hkCont_premserv__ttl">
                  Our Membership Plans
                </h1>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Standard Plan
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Advance Plan
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Premium Plan
                      </span>
                    </div>
                  </Link>
                  <Link to={"/packages"}>
                    <div className="reach">
                      <span className="reahhere">Call Now</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Whyglobal />
      </div>
    </>
  );
};

export default MachineBuy;
