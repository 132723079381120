import React, { useState, useEffect } from "react";
import "./OnionBuy.css"; // Assuming a new CSS file for spice buyer styles
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import Whyglobal from "../Components/Whyglobal";
import BuyerSide from "./BuyerSidebuyer";

const SpiceBuyers = [
  {
    name: "Brett Lee",
    email: "golu06154@gmail.com",
    mobileNumber: "7766884449",
    locationandDate: "Delhi, 08-01-2025",
    lookingFor:
      "Looking for 600kg spices powder for export to international markets.",
    productOrService: "Spices Powder",
    quantity: 600,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_6",
  },
  {
    name: "Mohamed Idrish",
    email: "idrisha89@gmail.com",
    mobileNumber: "9443107887",
    locationandDate: "Pudukkottai, 08-05-2025",
    lookingFor:
      "Looking for bulk spices powder for large-scale food processing.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_7",
  },
  {
    name: "The_DRV",
    email: "dhruvdrv7@gmail.com",
    mobileNumber: "9053512866",
    locationandDate: "Rewari, 02-01-2025",
    lookingFor:
      "Looking for 1 ton of spices powder for new seasoning production.",
    productOrService: "Spices Powder",
    quantity: 1000,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_8",
  },
  {
    name: "Mõhït Ñàgwáñshï",
    email: "themohitnagwanshi@gmail.com",
    mobileNumber: "6264919450",
    locationandDate: "Ujjain, 17-11-2024",
    lookingFor:
      "Looking for bulk spices powder for regional spice blend manufacturing.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_9",
  },
  {
    name: "पं श्रीसुयश महाराज",
    email: "mrshubhpathak@gmail.com",
    mobileNumber: "9301480427",
    locationandDate: "Chhatarpur, 16-11-2024",
    lookingFor:
      "Looking for bulk spices powder for distribution in rural markets.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_10",
  },
  {
    name: "Imtiaz",
    email: "imtiaznova6@gmail.com",
    mobileNumber: "8638868660",
    locationandDate: "Karimganj, 15-11-2024",
    lookingFor:
      "Looking for 1000kgs spices powder for export to Gulf countries.",
    productOrService: "Spices Powder",
    quantity: 1000,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_11",
  },
  {
    name: "Lakshmi Yadav",
    email: "lakshmiyadav916@gmail.com",
    mobileNumber: "8297944088",
    locationandDate: "Anantapur, 08-01-2025",
    lookingFor:
      "Looking for over 1000kgs spices powder for a wholesale grocery store.",
    productOrService: "Spices Powder",
    quantity: 1000,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_12",
  },
  {
    name: "Ramratan Kaswan",
    email: "ramratan6033@gmail.com",
    mobileNumber: "9828445111",
    locationandDate: "Bikaner, 01-01-2025",
    lookingFor: "Looking for bulk spices powder for catering services.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_13",
  },
  {
    name: "Madhukar Waman Thate",
    email: "madhukarthate44@gmail.com",
    mobileNumber: "9021129494",
    locationandDate: "Pune, 18-11-2024",
    lookingFor:
      "Looking for bulk spices powder for retail distribution in Pune.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_14",
  },
  {
    name: "Prantik Mukherjee",
    email: "prantik.prantik.mukherjee@gmail.com",
    mobileNumber: "9681244350",
    locationandDate: "Kolkata, 17-11-2024",
    lookingFor: "Looking for bulk spices powder for regional culinary events.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_15",
  },
  {
    name: "Samy Kumar",
    email: "samyslm04@gmail.com",
    mobileNumber: "9786666002",
    locationandDate: "Erode, 07-01-2025",
    lookingFor: "Looking for bulk spices powder for food stalls and catering.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_16",
  },
  {
    name: "Dilip Dakalia",
    email: "lalithas.dakalia@gmail.com",
    mobileNumber: "9479133829",
    locationandDate: "Coimbatore, 01-01-2025",
    lookingFor:
      "Looking for bulk spices powder for small-scale packaging business.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_17",
  },
  {
    name: "Pushpendra Kumar",
    email: "pk0080075@gmail.com",
    mobileNumber: "917088442257",
    locationandDate: "Hathras, 18-11-2024",
    lookingFor:
      "Looking for bulk spices powder for distribution to local vendors.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_18",
  },
  {
    name: "Ronil Goti",
    email: "disskcifwiwicgieisi@gmail.com",
    mobileNumber: "9624021710",
    locationandDate: "Surat, 06-01-2025",
    lookingFor:
      "Looking for bulk spices powder for a new culinary school project.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_19",
  },
  {
    name: "Keyur Patel",
    email: "keyurp.bapa@gmail.com",
    mobileNumber: "9825476903",
    locationandDate: "Navsari, 05-01-2025",
    lookingFor:
      "Looking for bulk purchase of spices powder for food processing.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_1",
  },
  {
    name: "Jay",
    email: "jayantparate93@gmail.com",
    mobileNumber: "7828475379",
    locationandDate: "Bhopal, 05-01-2025",
    lookingFor:
      "Looking for bulk purchase of spices powder for food processing.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_2",
  },
  {
    name: "Bhavik Karani",
    email: "bhavik.karani@example.com",
    mobileNumber: "9930099400",
    locationandDate: "Mumbai, 04-01-2025",
    lookingFor: "Looking for spices powder for food processing.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_3",
  },
  {
    name: "Pavan Reddy",
    email: "pavanckpm2002@gmail.com",
    mobileNumber: "7569237791",
    locationandDate: "Andhra Pradesh, 04-01-2025",
    lookingFor:
      "Looking for bulk purchase of spices powder for food processing.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_4",
  },
  {
    name: "Yogesh Rao",
    email: "raoy952@gmail.com",
    mobileNumber: "9728856363",
    locationandDate: "Gurugram, 03-01-2025",
    lookingFor:
      "Looking for bulk purchase of spices powder for food processing.",
    productOrService: "Spices Powder",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_spices_powder_buyer_5",
  },
  {
    name: "Subankar Dass",
    email: "subankardas.2017@gmail.com",
    mobileNumber: "9706389278",
    locationandDate: "Guwahati, Assam, 03-01-2025",
    lookingFor: "Looking for bulk purchase of red chilli for food processing.",
    productOrService: "Red Chilli",
    quantity: 500,
    unit: "kilograms",
    _id: "unique_id_for_red_chilli_buyer_1",
  },

  {
    name: "Rajesh Kumar",
    email: "rajesh.kumar@gmail.com",
    mobileNumber: "9998776655",
    locationandDate: "Delhi, 02-01-2025",
    lookingFor: "Looking for bulk purchase of cumin seeds for food processing.",
    productOrService: "Cumin Seeds",
    quantity: 200,
    unit: "kilograms",
    _id: "unique_id_for_cumin_seeds_buyer_1",
  },
  {
    name: "Sneha Desai",
    email: "sneha.desai@gmail.com",
    mobileNumber: "8888776655",
    lookingFor: "Need high-quality turmeric powder for retail sale.",
    productOrService: "Turmeric Powder",
    locationandDate: "Mumbai, 01-01-2025",
    quantity: 150,
    unit: "kilograms",
  },
  {
    name: "Vikram Singh",
    email: "vikram.singh@gmail.com",
    mobileNumber: "7777665544",
    lookingFor: "Looking for organic black pepper for restaurant use.",
    productOrService: "Black Pepper",
    locationandDate: "Bangalore, 02-01-2025",
    quantity: 100,
    unit: "kilograms",
  },
  {
    name: "Priya Mehta",
    email: "priya.mehta@gmail.com",
    mobileNumber: "6666554433",
    lookingFor: "Need chili powder in bulk for food processing.",
    productOrService: "Chili Powder",
    locationandDate: "Chennai, 18-11-2024",
    quantity: 300,
    unit: "kilograms",
  },
  {
    name: "Anil Yadav",
    email: "anil.yadav@gmail.com",
    mobileNumber: "5555443322",
    lookingFor:
      "Looking for high-quality coriander seeds for spice manufacturing.",
    productOrService: "Coriander Seeds",
    locationandDate: "Kolkata, 17-11-2024",
    quantity: 250,
    unit: "kilograms",
  },
];

const SpiceBuy = () => {
  const { userId } = useAuth();
  const [userIsPremium, setUserIsPremium] = useState(false);
  const [shownNumbers, setShownNumbers] = useState([]);
  const [leadsViewed, setLeadsViewed] = useState(0);

  useEffect(() => {
    if (!userId) return;

    // Load the viewed leads from local storage
    const storedViewedLeads =
      JSON.parse(localStorage.getItem(`spiceViewedLeads_${userId}`)) || [];
    setShownNumbers(storedViewedLeads);

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/getUserWithPremiumStatus/${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserIsPremium(data.isPremium);
        setLeadsViewed(data.leadsViewed);
        console.log("Premium Status:", data.isPremium);
      })
      .catch((error) => {
        console.error("Error fetching user information:", error);
      });
  }, [userId]);

  const toggleNumber = async (index) => {
    if (!userIsPremium) return; // Only premium users can see numbers

    if (leadsViewed >= 25) {
      alert("You have reached the limit of 25 leads viewed per month.");
      return;
    }

    if (shownNumbers.includes(index)) return; // If already shown, do nothing

    const updatedShownNumbers = [...shownNumbers, index];
    setShownNumbers(updatedShownNumbers);

    // Update local storage
    localStorage.setItem(
      `spiceViewedLeads_${userId}`,
      JSON.stringify(updatedShownNumbers)
    );

    const buyer = SpiceBuyers[index]; // Get the buyer information

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/incrementLeadsViewed/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            buyerName: buyer.name,
            mobileNo: buyer.mobileNumber,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLeadsViewed(data.leadsViewed);
      } else {
        const errorData = await response.json();
        console.error("Error incrementing leads viewed:", errorData.error);
      }
    } catch (error) {
      console.error("Error incrementing leads viewed:", error.message);
    }
  };

  return (
    <>
      <div className="container">
        <ol className="breadcrumb">
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px 30px",
            }}
            className="breadcrumb-item"
          >
            <Link to={"/"}>Home</Link>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px -25px",
            }}
          >
            /
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px 30px",
            }}
            className="breadcrumb-item active"
          >
            Fresh Leads
          </li>
        </ol>
      </div>
      <div className="containersx">
        <div style={{ width: "200px", padding: "10px" }} className="browsers">
          <h3 style={{ marginTop: "30px" }}>Browse by State</h3>
          <ul className="flt-list cust-scroll" id="state-lists">
            <li>
              <Link to="#">All India</Link>
            </li>
            <li>
              <Link to="#">Delhi</Link>
            </li>
            <li>
              <Link to="#">Maharashtra</Link>
            </li>
            <li>
              <Link to="#">Karnataka</Link>
            </li>
            <li>
              <Link to="#">West Bengal</Link>
            </li>
            <li>
              <Link to="#">Tamil Nadu</Link>
            </li>
          </ul>

          <ul style={{ marginTop: "20px" }} className="flt-list cust-scroll">
            <h3>All Category</h3>
            <li>
              <Link to="#">Cumin Seeds</Link>
            </li>
            <li>
              <Link to="#">Turmeric Powder</Link>
            </li>
            <li>
              <Link to="#">Black Pepper</Link>
            </li>
            <li>
              <Link to="#">Chili Powder</Link>
            </li>
            <li>
              <Link to="#">Coriander Seeds</Link>
            </li>
          </ul>
        </div>
        <div className="buyers">
          {SpiceBuyers.map((buyer, index) => (
            <div key={index} className="buyer-card">
              <h2 style={{ color: "red" }}>{buyer.lookingFor}</h2>
              <p>
                <strong>Buyer Name:</strong> {buyer.name}
              </p>
              <p>
                <strong>Quantity:</strong> {buyer.quantity} {buyer.unit}
              </p>
              <p>
                <strong>Location & Date:</strong> {buyer.locationandDate}
              </p>
              <p>
                <strong>Mobile No.:</strong>{" "}
                {userIsPremium
                  ? shownNumbers.includes(index)
                    ? buyer.mobileNumber
                    : "********"
                  : "********"}
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                {userIsPremium && (
                  <button
                    className="detail-btn"
                    style={{
                      padding: "5px 10px",
                      background: "#f7f1f1",
                      color: "orange",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleNumber(index)}
                  >
                    Click to see
                  </button>
                )}
              </div>
              <div className="btns-imp">
                <Link to={"/login"}>
                  <button className="detailing-btn">View More</button>
                </Link>
                <Link to={"/register-Company"}>
                  <button className="regis-btn">Register Now</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <BuyerSide />
      </div>
      <Whyglobal />
    </>
  );
};

export default SpiceBuy;
