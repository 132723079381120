import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./FreeCataloge.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faShieldHalved,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const products = [
  { id: 1, name: "Plastic Chairs", image: "./assets/plastic1.jpg" },
  { id: 3, name: "Plastic Tables", image: "./assets/plastic2.jpg" },
  { id: 5, name: "Plastic Storage", image: "./assets/plastic3.jpg" },
  { id: 6, name: "Plastic Containers", image: "./assets/plastic4.jpg" },
  { id: 1, name: "Plastic Furniture", image: "./assets/plastic-fur.jpg" },
  { id: 3, name: "Toilet Paper Holder", image: "./assets/plastic6.jpg" },
  { id: 5, name: "Plastic Tables", image: "./assets/plastic1.jpg" },
  { id: 6, name: "Plastic Chairs", image: "./assets/plastic2.jpg" },
];

const SanjaySharmaCataloge = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [highlight, setHighlight] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleReachEnd = () => {
    if (swiperInstance) {
      const isEnd = swiperInstance.isEnd;
      const navigationButton = document.querySelector(".swiper-button-next");
      if (navigationButton) {
        navigationButton.style.display = isEnd ? "none" : "block";
      }
    }
  };

  const handleReachStart = () => {
    if (swiperInstance) {
      const isBeginning = swiperInstance.isBeginning;
      const navigationButton = document.querySelector(".swiper-button-prev");
      if (navigationButton) {
        navigationButton.style.display = isBeginning ? "none" : "block";
      }
    }
  };

  const handleProductClick = () => {
    setActiveSection("products");
  };

  const renderContent = () => {
    switch (activeSection) {
      case "products":
        return (
          <div className="swiper-container">
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              onSwiper={setSwiperInstance}
              onReachEnd={handleReachEnd}
              onReachBeginning={handleReachStart}
              breakpoints={{
                640: { slidesPerView: 2, spaceBetween: 20 },
                768: { slidesPerView: 4, spaceBetween: 40 },
                1024: { slidesPerView: 7, spaceBetween: 30 },
              }}
              className="mySwiper"
            >
              {products.map((product) => (
                <SwiperSlide key={`product-${product.id}`}>
                  <div className="card" onClick={handleProductClick}>
                    <img src={product.image} alt={product.name} />
                    <p>{product.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        );

      default:
        return (
          <div>
            <div className="swiper-container">
              <Swiper
                modules={[Autoplay, Navigation, Pagination]}
                slidesPerView={1}
                spaceBetween={10}
                pagination={{ clickable: true }}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onSwiper={setSwiperInstance}
                onReachEnd={handleReachEnd}
                onReachBeginning={handleReachStart}
                breakpoints={{
                  640: { slidesPerView: 2, spaceBetween: 20 },
                  768: { slidesPerView: 4, spaceBetween: 40 },
                  1024: { slidesPerView: 7, spaceBetween: 30 },
                }}
                className="mySwiper"
              >
                {products.map((product) => (
                  <SwiperSlide key={`product-${product.id}`}>
                    <div className="card">
                      <img src={product.image} alt={product.name} />
                      <p>{product.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="welcome">
              <h1>Welcome to the Sanjay Sarma Plastic Items Manufacturer</h1>
              <p>
                Sanjay Sarma Plastic Items Manufacturer, we prioritize quality
                and reliability. Our products are made using high-grade plastic
                materials, ensuring they are resistant to wear, weather, and UV
                damage, making them suitable for both indoor and outdoor use.
                Our focus on innovation allows us to provide contemporary
                designs that enhance your space while being affordable and easy
                to maintain.{" "}
              </p>
              <h2>We Deals in All kind of Plastic Products</h2>
              <p>
                We are committed to excellent customer service, timely delivery,
                and competitive pricing. Whether you're furnishing a home,
                office, or commercial space, Sanjay Sarma Plastic Items
                Manufacturer is your trusted partner for all your plastic
                furniture and storage needs.
              </p>
              {/* <h2>Our Products :</h2> */}
              <p>
                Sanjay Sarma Plastic Items Manufacturer is a renowned name in
                the manufacturing and supply of high-quality plastic furniture
                and storage solutions. With years of experience in the industry,
                we specialize in providing durable, stylish, and cost-effective
                plastic tables, chairs, and storage products that cater to both
                residential and commercial needs. Our product range includes a
                variety of plastic furniture designed for comfort, durability,
                and aesthetic appeal:
              </p>
              <p>
                With a dedicated team and advanced production facilities, we
                ensure timely delivery, competitive pricing, and exceptional
                service. Over the years, we have earned the trust of our clients
                by consistently providing reliable and innovative packaging
                solutions. ChooseSanjay Sarma Plastic Items Manufacturer for all
                your plastic packaging needs and experience the perfect
                combination of quality, efficiency, and sustainability. Let us
                help you pack your products with excellence.
              </p>
              {/* <p>Unmatched Expertise
                                With years of experience in the elevator industry, our skilled team brings unparalleled knowledge and proficiency to every project.</p> */}

              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets//plastic2.jpg"
                      title="Kashmiri Saffron Threads"
                      alt="Kashmiri Saffron Threads"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Plastic Containers</h3>
                    </div>
                    <div className="pro_det_sec">
                      <ul className="attributes">
                        <li>
                          <span>Brand:</span> Sanjay Sarma Plastic Items
                          Manufacturer
                        </li>
                        <li>
                          <span>Product:</span> Plastic Container
                        </li>
                        <li>
                          <span>Material:</span> Made from high-quality,
                          BPA-free, food-grade plastic, ensuring safety and
                          durability
                        </li>
                        <li>
                          <span>Shape:</span> Rectangular - Designed for optimal
                          space utilization in cabinets or refrigerators
                        </li>
                        <li>
                          <span>Size:</span> 1.5 liters - Perfect for storing
                          snacks, cereals, spices, or other kitchen essentials
                        </li>
                        <li>
                          <span>Capacity:</span> Ample storage capacity with an
                          airtight lid to keep contents fresh and secure
                        </li>
                        <li>
                          <span>Color:</span> Transparent body with vibrant lid
                          options - Allows easy visibility of stored items while
                          adding a touch of style
                        </li>
                        <li>
                          <span>Usage:</span> Multipurpose - Suitable for
                          kitchen storage, office supplies, or organizing
                          miscellaneous items
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="company_header">
        <div className="top_head">
          <div className="company_left zdasds">
            <span className="logo_img2" style={{ background: "#d1d786" }}>
              CF
            </span>

            <div className="company_info">
              <div className="company_name2">
                <h1>
                  Sanjay Sarma Plastic Items Manufacturer{" "}
                  <div className="_oth_info">
                    <a
                      href="https://www.google.co.in/maps/dir//12.9715987,77.5945627"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <b className="fa fa-map-marker" />
                        Gurgaon, India{" "}
                      </span>
                    </a>
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_head">
          <div className={`Premium-Header ${highlight ? "highlight" : ""}`}>
            <ul className="menu_sec">
              <li>
                Home <FontAwesomeIcon icon={faGauge} />
              </li>
              <li>
                Products <FontAwesomeIcon icon={faShieldHalved} />
              </li>
              <li>
                About Us <FontAwesomeIcon icon={faMessage} />
                <ul className="dropdown"></ul>
              </li>
              <li>
                Contact Us <FontAwesomeIcon icon={faShieldHalved} />
                <ul className="dropdown"></ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {renderContent()}
    </>
  );
};

export default SanjaySharmaCataloge;
