import React, { useState, useEffect } from "react";
import "./OnionBuy.css"; // Assuming a similar CSS file is used.
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import Whyglobal from "../Components/Whyglobal";
const SareeBuys = [
  {
    _id: "1",
    name: "Mohammed Parveen Oo9",
    email: "mohamedbariyas461@gmail.com",
    mobileNumber: "+919042706923",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Thanjavur 21-01-2025",
    productOrService: "Silk Saree",
    quantity: "200",
    unit: "pcs",
  },
  {
    _id: "2",
    name: "Magilchi",
    email: "nivitharajkumar@gmail.com",
    mobileNumber: "+919488047458",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Singapore 21-01-2025",
    productOrService: "Silk Saree",
    quantity: "300",
    unit: "pcs",
  },
  {
    _id: "3",
    name: "Mani Raja",
    email: "manitoilor97@gmail.com",
    mobileNumber: "+919578130935",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Coimbatore 20-01-2025",
    productOrService: "Silk Saree",
    quantity: "1000",
    unit: "pcs",
  },
  {
    _id: "4",
    name: "Anbu Devi",
    email: "anbudevi7857@gmail.com",
    mobileNumber: "+918220837955",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Tirupur 20-01-2025",
    productOrService: "Silk Saree",
    quantity: "400",
    unit: "pcs",
  },
  {
    _id: "5",
    name: "Sai Ram",
    email: "srmbuilders7@gmail.com",
    mobileNumber: "+919500563123",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "நாமக்கல் 20-01-2025",
    productOrService: "Silk Saree",
    quantity: "200",
    unit: "pcs",
  },
  {
    _id: "6",
    name: "Sreenivasan S",
    email: "sreenivasanskpy123@gmail.com",
    mobileNumber: "+919846768055",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Tirupur 20-01-2025",
    productOrService: "Silk Saree",
    quantity: "400",
    unit: "pcs",
  },
  {
    _id: "7",
    name: "Maha Lakshmi",
    email: "madanmaha653@gmail.com",
    mobileNumber: "9965548653",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Tirunelveli 20-01-2025",
    productOrService: "Silk Saree",
    quantity: "100",
    unit: "pcs",
  },
  {
    _id: "8",
    name: "Minmini Boutique",
    email: "premalathanov22@gmail.com",
    mobileNumber: "+919585784002",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Chennai 19-01-2025",
    productOrService: "Silk Saree",
    quantity: "300",
    unit: "pcs",
  },
  {
    _id: "9",
    name: "Maha Kavi",
    email: "mahakavi@gmail.31com",
    mobileNumber: "+919600484060",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Tamilnadu 18-01-2025",
    productOrService: "Silk Saree",
    quantity: "Hi",
    unit: "pcs",
  },
  {
    _id: "10",
    name: "Muthudivya",
    email: "mageswari966@gmail.com",
    mobileNumber: "+919488534383",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Bodi 18-01-2025",
    productOrService: "Silk Saree",
    quantity: "100",
    unit: "pcs",
  },
  {
    _id: "11",
    name: "Gohila Kannan",
    email: "kgohiladevi@gmail.com",
    mobileNumber: "+919952260713",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Kadayanallur 18-01-2025",
    productOrService: "Silk Saree",
    quantity: "300",
    unit: "pcs",
  },
  {
    _id: "12",
    name: "Anu",
    email: "anuma4pp@gmail.com",
    mobileNumber: "+919344087122",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Anu 17-01-2025",
    productOrService: "Silk Saree",
    quantity: "100",
    unit: "pcs",
  },
  {
    _id: "13",
    name: "Ishwaryarai Prasanth",
    email: "ishwa030503@gmail.com",
    mobileNumber: "+916379797009",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Hosur 18-01-2025",
    productOrService: "Silk Saree",
    quantity: "1000",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11483",
    name: "Varalakshmi Thanigaivelan",
    email: "varalakshmi392@gmail.com",
    mobileNumber: "9940443270",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Chennai 09-01-2025",
    productOrService: "Silk Saree",
    quantity: "1000",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11484",
    name: "Aman Dagar",
    email: "yuvapanjalai1@gmail.com",
    mobileNumber: "+917092019652",
    lookingFor: "Bulk silk sarees for retail - Quantity required: Very good",
    locationandDate: "Polur 09-01-2025",
    productOrService: "Silk Saree",
    quantity: "2000",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11485",
    name: "vinotha",
    email: "vijivinothavijivinotha@gmail.com",
    mobileNumber: "+918825446130",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Salem 09-01-2025",
    productOrService: "Silk Saree",
    quantity: "2100",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11486",
    name: "Elampillai SPM Sarees",
    email: "mani007salem@gmail.com",
    mobileNumber: "+919566647775",
    lookingFor:
      "Bulk silk sarees for retail - Quantity required: Kalyani cotton manufacturing",
    locationandDate: "Ilampillai 12-01-2025",
    productOrService: "Silk Saree",
    quantity: "1000",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11487",
    name: "Sabari Nathan",
    email: "na@gmail.com",
    mobileNumber: "+918012117894",
    lookingFor: "Bulk silk sarees for retail ",
    locationandDate: "Ariyalur 12-01-2025",
    productOrService: "Silk Saree",
    quantity: "600",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11488",
    name: "arumugam",
    email: "asbuilders7@gmail.com",
    mobileNumber: "+919445631271",
    lookingFor: "Bulk silk sarees for retail ",
    locationandDate: "Kanchipuram 12-01-2025",
    productOrService: "Silk Saree",
    quantity: "900",
    unit: "pieces",
  },
  {
    _id: "66869a299f2cecbc7cc11489",
    name: "Padmakshetra | Bridal Makeup Artist Madurai | Makeup Academy",
    email: "kkb.priya@yahoo.in",
    mobileNumber: "+918681827186",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Madurai 13-01-2025",
    productOrService: "Silk Saree",
    quantity: "50",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11490",
    name: "Ramya Ramya",
    email: "duraimani1985@gmail.com",
    mobileNumber: "+919944733839",
    lookingFor: "Bulk silk sarees for retail - Quantity required: Bulk",
    locationandDate: "Hosur 13-01-2025",
    productOrService: "Silk Saree",
    quantity: "Bulk",
    unit: "98",
  },
  {
    _id: "66869a299f2cecbc7cc11482",
    name: "Lakshmi",
    email: "lakshmikavimega@gmail.com",
    mobileNumber: "8248251240",
    lookingFor: "Bulk silk sarees for retail",
    locationandDate: "Hosur 08-01-2025",
    productOrService: "Silk Saree",
    quantity: "300",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11483",
    name: "Naresh",
    email: "naresh021999@gmail.com",
    mobileNumber: "+919944184732",
    lookingFor: "Premium silk sarees for gifting",
    locationandDate: "Salem 08-01-2025",
    productOrService: "Silk Saree",
    quantity: 309,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11484",
    name: "Devi.G",
    email: "yamenidevi@gmail.com",
    mobileNumber: "+919962016582",
    lookingFor: "Single silk saree for special occasion",
    locationandDate: "Minjur 08-01-2025",
    productOrService: "Silk Saree",
    quantity: 199,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11485",
    name: "HAUTE_COUTURE_HUB",
    email: "nusaibakp1997@gmail.com",
    mobileNumber: "+918129112420",
    lookingFor: "Bulk silk sarees for boutique",
    locationandDate: "Kozhikode 08-01-2025",
    productOrService: "Silk Saree",
    quantity: "900",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11486",
    name: "Bambhaniya Dhruvisha",
    email: "dhruvishabambhaniya343@gmail.com99",
    mobileNumber: "+917048711856",
    lookingFor: "Unique silk sarees for personal use",
    locationandDate: "Iv 08-01-2025",
    productOrService: "Silk Saree",
    quantity: "800",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11487",
    name: "Reka Ramesh",
    email: "dzinebyrrfabz@gmail.com",
    mobileNumber: "+918883706198",
    lookingFor: "Silk sarees for traditional events",
    locationandDate: "Tirunelveli 08-01-2025",
    productOrService: "Silk Saree",
    quantity: 250,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11488",
    name: "DIYA MANIKANDAN",
    email: "sindhiyam80@gmail.com",
    mobileNumber: "+919360502292",
    lookingFor: "Elegant silk sarees for special occasions",
    locationandDate: "Tirupur 08-01-2025",
    productOrService: "Silk Saree",
    quantity: "800",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11489",
    name: "Kutima",
    email: "yrani349@gmail.com",
    mobileNumber: "6374165237",
    lookingFor: "Premium quality silk sarees",
    locationandDate: "Madurai 08-01-2025",
    productOrService: "Silk Saree",
    quantity: "200",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11490",
    name: "Nandhini Nanthu",
    email: "saravanannandinisaravanannandh@gmail.com",
    mobileNumber: "+918754880633",
    lookingFor: "Bulk silk sarees for family events",
    locationandDate: "Erode 08-01-2025",
    productOrService: "Silk Saree",
    quantity: "900",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11491",
    name: "Sangitha Veeramani",
    email: "lovelyrange@gmail.com",
    mobileNumber: "+919751348729",
    lookingFor: "Silk sarees of various designs",
    locationandDate: "Trichy 08-01-2025",
    productOrService: "Silk Saree",
    quantity: "800",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11492",
    name: "Kousalya_Sakthivel",
    email: "uniteddeveloperspromoters@gmail.com",
    mobileNumber: "7358612707",
    lookingFor: "Traditional silk sarees",
    locationandDate: "Chennai 08-01-2025",
    productOrService: "Silk Saree",
    quantity: 1000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11472",
    name: "Arumugam. M",
    email: "1roofsolutions777@gmail.com",
    mobileNumber: "+919841777547",
    lookingFor: "Custom silk sarees with intricate patterns",
    locationandDate: "Chennai 08-01-2025",
    productOrService: "Silk Saree",
    quantity: 200,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11473",
    name: "Vinstudio Vincy",
    email: "vinstudio2024@gmail.com",
    mobileNumber: "+917639921868",
    lookingFor: "Designer silk sarees for studio use",
    locationandDate: "Peyankuzhi 08-01-2025",
    productOrService: "Silk Saree",
    quantity: 1000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11474",
    name: "Brindha Govind",
    email: "brindhagnanasekar95@gmail.com",
    mobileNumber: "8056566289",
    lookingFor: "Elegant silk sarees for a wedding",
    locationandDate: "Chennai 08-01-2025",
    productOrService: "Silk Saree",
    quantity: "5000",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11475",
    name: "Sara Saran Sarasaran",
    email: "arthisaran53@gmail.com",
    mobileNumber: "+919361490847",
    lookingFor: "Traditional silk sarees for an event",
    locationandDate: "Madurai 08-01-2025",
    productOrService: "Silk Saree",
    quantity: 1000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11476",
    name: "Nithisha Nithisha",
    email: "rameshmala2323@gmail.com",
    mobileNumber: "7550273882",
    lookingFor: "Lightweight silk sarees for daily wear",
    locationandDate: "Chennai 08-01-2025",
    productOrService: "Silk Saree",
    quantity: 1000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11477",
    name: "Suresh Deepa",
    email: "deepatn2015@gmail.com",
    mobileNumber: "+919952296475",
    lookingFor: "Festival silk sarees with unique colors",
    locationandDate: "Thuckalay 08-01-2025",
    productOrService: "Silk Saree",
    quantity: "200",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11478",
    name: "Jammu Suresh",
    email: "saijamuna1998@gmail.com",
    mobileNumber: "+919361744919",
    lookingFor: "Silk sarees for reselling purposes",
    locationandDate: "Tiruvannamalai 08-01-2025",
    productOrService: "Silk Saree",
    quantity: "290",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11479",
    name: "Amman Ladieschoice",
    email: "www.ladieschoice@gmail.com",
    mobileNumber: "+919600766759",
    lookingFor: "Bulk order of silk sarees for retail",
    locationandDate: "Thanjavur 08-01-2025",
    productOrService: "Silk Saree",
    quantity: "800",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11480",
    name: "Mirna",
    email: "jenimirna17@gmail.com",
    mobileNumber: "8056810512",
    lookingFor: "Luxury silk sarees for a gift",
    locationandDate: "Pondicherry 08-01-2025",
    productOrService: "Silk Saree",
    quantity: 2000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11481",
    name: "Shobana",
    email: "shobanaj23@gmail.com",
    mobileNumber: "9787523783",
    lookingFor: "Silk sarees for bridal trousseau",
    locationandDate: "Kancheepuram 08-01-2025",
    productOrService: "Silk Saree",
    quantity: "9000",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11444",
    name: "Sundharavalli A",
    email: "asundharavalli958@gmail.com",
    mobileNumber: "+919500279283",
    lookingFor: "Exclusive handwoven sarees with intricate designs",
    locationandDate: "Dindigul 05-01-2025",
    productOrService: "Sarees",
    quantity: "300",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11445",
    name: "Harini Duraimurugan",
    email: "yashodhagaa@yahoo.com",
    mobileNumber: "+919790173559",
    lookingFor: "Traditional Kanjivaram sarees for festive occasions",
    locationandDate: "Bangalore 06-01-2025",
    productOrService: "Sarees",
    quantity: 500,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11446",
    name: "Logeswari R",
    email: "sujiramasamy547@gmail.com",
    mobileNumber: "+919952173552",
    lookingFor: "Cotton sarees for daily wear in vibrant colors",
    locationandDate: "Karur DT, Kulithalai TK 04-01-2025",
    productOrService: "Sarees",
    quantity: 200,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11447",
    name: "Mayavan",
    email: "mayavanpoweraudio@gmail.com",
    mobileNumber: "+919698515826",
    lookingFor: "Elegant silk sarees with zari borders",
    locationandDate: "Salem 06-01-2025",
    productOrService: "Sarees",
    quantity: "600",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11448",
    name: "Udhayakumar K",
    email: "udhayakumar372220@gmail.com",
    mobileNumber: "+919965559089",
    lookingFor: "Saree samples for boutique display",
    locationandDate: "Coimbatore 641302 07-01-2025",
    productOrService: "Sarees",
    quantity: "500",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11449",
    name: "Nirmala Selva",
    email: "selvanirmala67@gmail.com",
    mobileNumber: "+919626749856",
    lookingFor: "Exclusive bridal sarees with rich embroidery",
    locationandDate: "Pudukkottai 04-01-2025",
    productOrService: "Sarees",
    quantity: "150",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11450",
    name: "Sheela Johnson",
    email: "sheelajohnsonsheelajohnson1@gmail.com",
    mobileNumber: "+917358178529",
    lookingFor: "Lightweight sarees for office wear",
    locationandDate: "Tiruvannamalai 06-01-2025",
    productOrService: "Sarees",
    quantity: "500",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11451",
    name: "M. Parvathi",
    email: "m.parvathi2005@gamil.com",
    mobileNumber: "+918760911511",
    lookingFor: "Affordable sarees for wholesale purchase",
    locationandDate: "Palacode 05-01-2025",
    productOrService: "Sarees",
    quantity: "200",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11452",
    name: "Parkavi",
    email: "parkavisamy27@gmail.com",
    mobileNumber: "8098843830",
    lookingFor: "Designer sarees with floral patterns",
    locationandDate: "Madurai 07-01-2025",
    productOrService: "Sarees",
    quantity: 400,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11453",
    name: "Priyanka Balaji",
    email: "mithrapriyanka91@gmail.com",
    mobileNumber: "+919751768144",
    lookingFor: "Unique sarees for gifting purposes",
    locationandDate: "Madurai 07-01-2025",
    productOrService: "Sarees",
    quantity: "300",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11454",
    name: "Rajendran",
    email: "rajboonthalir@mail.com",
    mobileNumber: "+918667639023",
    lookingFor: "Premium silk sarees for boutique",
    locationandDate: "Salem 07-01-2025",
    productOrService: "Sarees",
    quantity: 900,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11455",
    name: "T Ramesh Kumar",
    email: "trameshkumar86@gmail.com",
    mobileNumber: "+919842718011",
    lookingFor: "Handloom cotton sarees for retail",
    locationandDate: "Erode 06-01-2025",
    productOrService: "Sarees",
    quantity: "988",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11456",
    name: "Psathiyamoorthi Sathya",
    email: "psathiyamoorthim@gmail.com",
    mobileNumber: "+919786602214",
    lookingFor: "Soft silk sarees for wedding collections",
    locationandDate: "Tiruchirappalli 05-01-2025",
    productOrService: "Sarees",
    quantity: 499,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11457",
    name: "Diya Tex",
    email: "pvasuki47@gmail.com",
    mobileNumber: "+917708248390",
    lookingFor: "Lightweight sarees for daily wear",
    locationandDate: "Ellampilai 05-01-2025",
    productOrService: "Sarees",
    quantity: "800",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11458",
    name: "Ramzan Banu",
    email: "ramzanbanu9629@gmail.com",
    mobileNumber: "+919629606267",
    lookingFor: "Exclusive sarees for festive collections",
    locationandDate: "Tirupur 04-01-2025",
    productOrService: "Sarees",
    quantity: "500",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11459",
    name: "Ganesh Ananya",
    email: "ganapathiganesh385@gmail.com",
    mobileNumber: "+919384525060",
    lookingFor: "Designer sarees for boutique sales",
    locationandDate: "Krishnagiri 04-01-2025",
    productOrService: "Sarees",
    quantity: "400",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11460",
    name: "Umamaheswari Theni",
    email: "umamaheswaric181989@gmail.com",
    mobileNumber: "+919655658654",
    lookingFor: "Affordable sarees for wholesale business",
    locationandDate: "Theni 03-01-2025",
    productOrService: "Sarees",
    quantity: "500",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11461",
    name: "Velmurugan",
    email: "elevelmurugan@gmail.com",
    mobileNumber: "+916374975587",
    lookingFor: "High-quality sarees with quotations",
    locationandDate: "Erode 03-01-2025",
    productOrService: "Sarees",
    quantity: "300",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11462",
    name: "Arumugam. M",
    email: "1roofsolutions777@gmail.com",
    mobileNumber: "+919841777547",
    lookingFor: "Custom-designed sarees for personal use",
    locationandDate: "Chennai 08-01-2025",
    productOrService: "Sarees",
    quantity: 2,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11463",
    name: "Vinstudio Vincy",
    email: "vinstudio2024@gmail.com",
    mobileNumber: "+917639921868",
    lookingFor: "High-quality sarees for studio events",
    locationandDate: "Peyankuzhi 08-01-2025",
    productOrService: "Sarees",
    quantity: 100,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11464",
    name: "Brindha Govind",
    email: "brindhagnanasekar95@gmail.com",
    mobileNumber: "8056566289",
    lookingFor: "Unique saree designs for gifting",
    locationandDate: "Chennai 08-01-2025",
    productOrService: "Sarees",
    quantity: "Ok",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11465",
    name: "Sara Saran Sarasaran",
    email: "arthisaran53@gmail.com",
    mobileNumber: "+919361490847",
    lookingFor: "Traditional sarees for a special occasion",
    locationandDate: "Madurai 08-01-2025",
    productOrService: "Sarees",
    quantity: 10,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11466",
    name: "Nithisha Nithisha",
    email: "rameshmala2323@gmail.com",
    mobileNumber: "7550273882",
    lookingFor: "Lightweight sarees for office wear",
    locationandDate: "Chennai 08-01-2025",
    productOrService: "Sarees",
    quantity: 10,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11467",
    name: "Suresh Deepa",
    email: "deepatn2015@gmail.com",
    mobileNumber: "+919952296475",
    lookingFor: "Elegant sarees for festive wear",
    locationandDate: "Thuckalay 08-01-2025",
    productOrService: "Sarees",
    quantity: "Yes",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11468",
    name: "Jammu Suresh",
    email: "saijamuna1998@gmail.com",
    mobileNumber: "+919361744919",
    lookingFor: "Reseller-focused sarees for wholesale",
    locationandDate: "Tiruvannamalai 08-01-2025",
    productOrService: "Sarees",
    quantity: "Reseller",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11469",
    name: "Amman Ladieschoice",
    email: "www.ladieschoice@gmail.com",
    mobileNumber: "+919600766759",
    lookingFor: "Bulk sarees for retail distribution",
    locationandDate: "Thanjavur 08-01-2025",
    productOrService: "Sarees",
    quantity: "Bulk",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11470",
    name: "Mirna",
    email: "jenimirna17@gmail.com",
    mobileNumber: "8056810512",
    lookingFor: "Designer sarees for personal use",
    locationandDate: "Pondicherry 08-01-2025",
    productOrService: "Sarees",
    quantity: 20,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11471",
    name: "Shobana",
    email: "shobanaj23@gmail.com",
    mobileNumber: "9787523783",
    lookingFor: "Exclusive sarees for bridal collection",
    locationandDate: "Kancheepuram 08-01-2025",
    productOrService: "Sarees",
    quantity: "Shobana",
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11443",
    name: "Ramesh",
    email: "ramesh@example.com",
    mobileNumber: "9876543210",
    lookingFor: "Silk Saree unique pattern designs",
    locationandDate: "Chennai 25-10-2024",
    productOrService: "Silk Saree",
    quantity: 2500,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11444",
    name: "Anjali",
    email: "anjali@example.com",
    mobileNumber: "9123456789",
    lookingFor: "Silk Saree unique pattern designs",
    locationandDate: "Delhi 25-10-2024",
    productOrService: "Silk Saree",
    quantity: 3500,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11445",
    name: "Priya",
    email: "priya@example.com",
    mobileNumber: "9234567890",
    lookingFor: "Silk Saree unique pattern designs",
    locationandDate: "Mumbai 25-10-2024",
    productOrService: "Silk Saree",
    quantity: 4000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11446",
    name: "Vikram",
    email: "vikram@example.com",
    mobileNumber: "9345678901",
    lookingFor: "Silk Saree unique pattern designs",
    locationandDate: "Hyderabad 25-10-2024",
    productOrService: "Silk Saree",
    quantity: 2000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11447",
    name: "Nisha",
    email: "nisha@example.com",
    mobileNumber: "9456789012",
    lookingFor: "Silk Saree unique pattern designs",
    locationandDate: "Pune 25-10-2024",
    productOrService: "Silk Saree",
    quantity: 3200,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11448",
    name: "Amit",
    email: "amit@example.com",
    mobileNumber: "9567890123",
    lookingFor: "Silk Saree unique pattern designs",
    locationandDate: "Kolkata 25-10-2024",
    productOrService: "Silk Saree",
    quantity: 2700,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11449",
    name: "Sunita",
    email: "sunita@example.com",
    mobileNumber: "9678901234",
    lookingFor: "Silk Saree unique pattern designs",
    locationandDate: "Bangalore 25-10-2024",
    productOrService: "Silk Saree",
    quantity: 2900,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11450",
    name: "Rajesh",
    email: "rajesh@example.com",
    mobileNumber: "9789012345",
    lookingFor: "Silk Saree unique pattern designs",
    locationandDate: "Ahmedabad 25-10-2024",
    productOrService: "Silk Saree",
    quantity: 3300,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11451",
    name: "Sneha",
    email: "sneha@example.com",
    mobileNumber: "9890123456",
    lookingFor: "Silk Saree unique pattern designs",
    locationandDate: "Surat 25-10-2024",
    productOrService: "Silk Saree",
    quantity: 3100,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11452",
    name: "Manoj",
    email: "manoj@example.com",
    mobileNumber: "9901234567",
    lookingFor: "Silk Saree unique pattern designs",
    locationandDate: "Coimbatore 25-10-2024",
    productOrService: "Silk Saree",
    quantity: 2800,
    unit: "pcs",
  },
  {
    name: "Meena Gupta",
    email: "meena.gupta@gmail.com",
    mobileNumber: "9765432109",
    lookingFor: "Cotton Saree Required urgently",
    locationandDate: "Chennai 16-10-2024",
    productOrService: "Cotton Saree",
    quantity: 100,
    unit: "pcs",
  },

  {
    name: "Anita Kapoor",
    email: "anita.kapoor@gmail.com",
    mobileNumber: "8765432109",
    lookingFor: "Banarasi Saree Required urgently",
    locationandDate: "Kolkata 16-10-2024",
    productOrService: "Banarasi Saree",
    quantity: 25,
    unit: "pcs",
  },

  {
    name: "Nidhi Sharma",
    email: "nidhi.sharma@gmail.com",
    mobileNumber: "9876543210",
    lookingFor: "Chiffon Saree Required urgently",
    locationandDate: "Jaipur 16-10-2024",
    productOrService: "Chiffon Saree",
    quantity: 75,
    unit: "pcs",
  },

  {
    _id: "saree004",
    name: "Nidhi Sharma",
    email: "nidhi.sharma@gmail.com",
    mobileNumber: "+919876543210",
    locationandDate: "Jaipur 16-10-2024",
    productOrService: "Chiffon Sarees",
    quantity: 75,
    unit: "pcs",
    lookingFor: "Urgent saree, best party wear design",
  },

  {
    _id: "66869a299f2cecbc7cc11440",
    name: "Anjali",
    email: "anjali@example.com",
    mobileNumber: "9876543210",
    lookingFor: "banarasi  Saree Required urgently",
    locationandDate: "Delhi 16-10-2024",
    productOrService: "banarasi  Saree",
    quantity: 400,
    unit: "pcs",
  },
  {
    name: "Ritika Jain",
    email: "ritika.jain@gmail.com",
    mobileNumber: "9834567890",
    lookingFor: "Silk Saree Required urgently",
    locationandDate: "Surat 16-10-2024",
    productOrService: "Silk Saree",
    quantity: 50,
    unit: "pcs",
  },

  {
    _id: "66869a299f2cecbc7cc11441",
    name: "Priya",
    email: "priya@example.com",
    mobileNumber: "9123456780",
    lookingFor: "Bandni Saree for bulk purchase",
    locationandDate: "Mumbai 14-10-2024",
    productOrService: "Bandni Saree",
    quantity: 2000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11442",
    name: "Geeta",
    email: "geeta@example.com",
    mobileNumber: "9988776655",
    lookingFor: "Silk Saree unique pattern designs",
    locationandDate: "Bangalore 15-10-2024",
    productOrService: "Silk Saree",
    quantity: 3000,
    unit: "pcs",
  },
];

const SareeBuy = () => {
  const { userId } = useAuth();
  const [userIsPremium, setUserIsPremium] = useState(false);
  const [shownNumbers, setShownNumbers] = useState([]);
  const [leadsViewed, setLeadsViewed] = useState(0);

  useEffect(() => {
    if (!userId) return;

    // Load the viewed leads from local storage
    const storedViewedLeads =
      JSON.parse(localStorage.getItem(`sareeViewedLeads_${userId}`)) || [];
    setShownNumbers(storedViewedLeads);

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/getUserWithPremiumStatus/${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserIsPremium(data.isPremium);
        setLeadsViewed(data.leadsViewed);
        console.log("Premium Status:", data.isPremium);
      })
      .catch((error) => {
        console.error("Error fetching user information:", error);
      });
  }, [userId]);

  const toggleNumber = async (index) => {
    if (!userIsPremium) return; // Only premium users can see numbers

    if (leadsViewed >= 25) {
      alert("You have reached the limit of 25 leads viewed per month.");
      return;
    }

    if (shownNumbers.includes(index)) return; // If already shown, do nothing

    const updatedShownNumbers = [...shownNumbers, index];
    setShownNumbers(updatedShownNumbers);

    // Update local storage
    localStorage.setItem(
      `sareeViewedLeads_${userId}`,
      JSON.stringify(updatedShownNumbers)
    );

    const buyer = SareeBuys[index]; // Get the buyer information

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/incrementLeadsViewed/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            buyerName: buyer.name,
            mobileNo: buyer.mobileNumber,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLeadsViewed(data.leadsViewed);
      } else {
        const errorData = await response.json();
        console.error("Error incrementing leads viewed:", errorData.error);
      }
    } catch (error) {
      console.error("Error incrementing leads viewed:", error.message);
    }
  };

  return (
    <>
      <div className="container">
        <ol className="breadcrumb">
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px 30px",
            }}
            className="breadcrumb-item"
          >
            <Link to={"/"}>Home</Link>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px -25px",
            }}
          >
            /
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px 30px",
            }}
            className="breadcrumb-item active"
          >
            Saree Leads
          </li>
        </ol>
      </div>
      <div className="containersx">
        <div style={{ width: "200px", padding: "10px" }} className="browsers">
          <h3 style={{ marginTop: "30px" }}>Browse by Category</h3>
          <ul className="flt-list cust-scroll" id="category-lists">
            <li>
              <Link to="#">All Sarees</Link>
            </li>
            <li>
              <Link to="#">banarasi Sarees</Link>
            </li>
            <li>
              <Link to="#">Bandni Sarees</Link>
            </li>
            <li>
              <Link to="#">Silk Sarees</Link>
            </li>
          </ul>
        </div>
        <div className="buyers">
          {SareeBuys.map((buyer, index) => (
            <div key={index} className="buyer-card">
              <h2 style={{ color: "red" }}>{buyer.lookingFor}</h2>
              <p>
                <strong>Buyer Name:</strong> {buyer.name}
              </p>
              <p>
                <strong>Quantity:</strong> {buyer.quantity} {buyer.unit}
              </p>
              <p>
                <strong>Location & Date:</strong> {buyer.locationandDate}
              </p>
              <p>
                <strong>Mobile No.:</strong>{" "}
                {userIsPremium
                  ? shownNumbers.includes(index)
                    ? buyer.mobileNumber
                    : "********"
                  : "********"}
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                {userIsPremium && (
                  <button
                    className="detail-btn"
                    style={{
                      padding: "5px 10px",
                      background: "#f7f1f1",
                      color: "orange",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleNumber(index)}
                  >
                    Click to see
                  </button>
                )}
              </div>
              <div className="btns-imp">
                <Link to={"/login"}>
                  <button className="detailing-btn">View More</button>
                </Link>
                <Link to={"/register-Company"}>
                  <button className="regis-btn">Register Now</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="sidebar">
          <div className="mains-slide">
            <div className="tk23-hkCont_onergt">
              <div className="tk23-hkCont_premserv__box">
                <h1 className="tk23-hkCont_premserv__ttl">
                  Our Membership Plans
                </h1>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Standard Plan
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Advance Plan
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Premium Plan
                      </span>
                    </div>
                  </Link>
                  <Link to={"/packages"}>
                    <div className="reach">
                      <span className="reahhere">Call Now</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Whyglobal />
      </div>
    </>
  );
};

export default SareeBuy;
