import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Catalog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faShieldHalved,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const products = [
  { id: 1, image: "./assets/prt2.jpeg" },
  {
    id: 3,

    image: "./assets/prt10.jpeg",
  },
  {
    id: 5,

    image: "./assets/prt3.jpeg",
  },
  { id: 6, image: "./assets/prt4.jpeg" },
  { id: 4, image: "./assets/part9.jpeg" },
  { id: 8, image: "./assets/prt6.jpeg" },
  { id: 1, image: "./assets/prt7.jpeg" },
  { id: 1, image: "./assets/prt8.jpeg" },
  //   { id: 2, name: "Civil Law", image: "./assets/samc1.jpg" },
  //   { id: 9, name: "Court case", image: "./assets/samc2.jpg" },
];

const TwentyNineCataloge = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [highlight, setHighlight] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleReachEnd = () => {
    if (swiperInstance) {
      const isEnd = swiperInstance.isEnd;
      const navigationButton = document.querySelector(".swiper-button-next");
      if (navigationButton) {
        navigationButton.style.display = isEnd ? "none" : "block";
      }
    }
  };

  const handleReachStart = () => {
    if (swiperInstance) {
      const isBeginning = swiperInstance.isBeginning;
      const navigationButton = document.querySelector(".swiper-button-prev");
      if (navigationButton) {
        navigationButton.style.display = isBeginning ? "none" : "block";
      }
    }
  };

  const handleProductClick = () => {
    setActiveSection("products");
  };

  const renderContent = () => {
    switch (activeSection) {
      case "products":
        return (
          <div className="swiper-container">
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              onSwiper={setSwiperInstance}
              onReachEnd={handleReachEnd}
              onReachBeginning={handleReachStart}
              breakpoints={{
                640: { slidesPerView: 2, spaceBetween: 20 },
                768: { slidesPerView: 4, spaceBetween: 40 },
                1024: { slidesPerView: 7, spaceBetween: 30 },
              }}
              className="mySwiper"
            >
              {products.map((product) => (
                <SwiperSlide key={`product-${product.id}`}>
                  <div className="card" onClick={handleProductClick}>
                    <img src={product.image} alt={product.name} />
                    {/* <p>{product.name}</p> */}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="welcome">
              <h1>Our Products</h1>

              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets/prt6.jpeg"
                      title="Art Glass"
                      alt="Art Glass"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Art Glass</h3>
                    </div>
                    <div className="pro_det_sec">
                      <li>
                        <span>Product:</span> Art Glass
                      </li>
                      <li>
                        <span>Availability:</span> Custom-Made Pieces
                      </li>
                      <li>
                        <span>Description:</span> Art glass highlights the
                        artistry of glassmakers using methods such as blowing
                        and fusing, creating one-of-a-kind, vibrant pieces.
                        Whether it’s vases or stained glass windows, art glass
                        brings sophistication and beauty to any environment.
                      </li>
                      <li>
                        <span>Key Benefits:</span>
                        <ul>
                          <li>
                            <span>Unique Designs:</span> Each piece is a
                            handcrafted masterpiece, ensuring no two are the
                            same.
                          </li>
                          <li>
                            <span>Versatile Applications:</span> Ideal for home
                            decor, offices, and galleries, adding elegance and
                            charm.
                          </li>
                          <li>
                            <span>High Craftsmanship:</span> Made using advanced
                            techniques like glass blowing and fusing by skilled
                            artisans.
                          </li>
                          <li>
                            <span>Wide Range of Options:</span> Available in
                            various forms such as vases, bowls, and stained
                            glass windows.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>Usage:</span> Perfect for enhancing the aesthetics
                        of any space with vibrant and artistic glass designs.
                      </li>
                      <li>
                        <span>Contact Us:</span> Reach out today to explore our
                        collection of art glass and customize your own stunning
                        pieces.
                      </li>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets/light_jhumer1.jpeg"
                      title="Light Jhumar"
                      alt="Light Jhumar"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Light Jhumar</h3>
                    </div>
                    <div className="pro_det_sec">
                      <li>
                        <span>Product:</span> Light Jhumar
                      </li>
                      <li>
                        <span>Availability:</span> Available for Custom Orders
                      </li>
                      <li>
                        <span>Description:</span> A glass light jhumar is a
                        beautiful decorative item crafted from detailed glass
                        components. It captures and reflects light, producing a
                        breathtaking atmosphere. Ideal for any space, it brings
                        charm, luxury, and a hint of sophistication.
                      </li>
                      <li>
                        <span>Categories:</span> All Products, Chandelier Light,
                        Light Jhumar, New Arrival
                      </li>
                      <li>
                        <span>Key Benefits:</span>
                        <ul>
                          <li>
                            <span>Elegant Design:</span> Crafted with intricate
                            glass details, perfect for adding a touch of luxury.
                          </li>
                          <li>
                            <span>Light Reflection:</span> Reflects light
                            beautifully, creating a captivating ambiance.
                          </li>
                          <li>
                            <span>Versatile Application:</span> Suitable for
                            various spaces including homes, hotels, and banquet
                            halls.
                          </li>
                          <li>
                            <span>New Arrival:</span> A fresh addition to our
                            collection, offering both modern elegance and
                            timeless charm.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>Usage:</span> Ideal for spaces requiring a
                        sophisticated touch, perfect for living rooms, dining
                        rooms, or commercial spaces.
                      </li>
                      <li>
                        <span>Contact Us:</span> Reach out to inquire about
                        customization options or to place an order for this
                        exquisite light jhumar.
                      </li>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
      case "about":
        return (
          <div className="about-us-container">
            <div className="intro"></div>
            <div className="company-info">
              <h1>Welcome to in Our Company</h1>
              <h2>About Us</h2>
              <p>
                We specialize in high-quality glasswork, providing unique
                designs and outstanding craftsmanship. Our offerings include
                breathtaking chandeliers, stylish door handles, and artistic
                glass creations. We prioritize durability, elegance, and vibrant
                artistry. Discover our dedication to excellence in every piece.
              </p>
              <p>
                Prince Quality Glasses, every piece showcases exceptional
                craftsmanship and artistic creativity. Our chandeliers, door
                handles, door knobs, and art glass are crafted to add elegance
                and sophistication to any environment. The detailed designs and
                vivid artistry of our glasswork produce stunning reflections and
                an enchanting atmosphere. Discover the timeless beauty and
                allure of our unique creations that elevate ordinary spaces into
                something truly remarkable.
              </p>

              {/* <h3>All Kind of  We Do</h3> */}
              <p>
                We are Prince Quality Glasses, specialists in high-quality
                glasswork based in Firozabad. Offering unique designs and
                exceptional craftsmanship, our products range from stunning
                chandeliers and elegant door handles to artistic glass pieces.
                We focus on durability, elegance, and vibrant artistry.
                Experience our commitment to excellence in every piece.
              </p>
              {/* <p>
                Sustainable Practices Our firm prioritizes sustainability by
                sourcing raw materials responsibly and implementing eco-friendly
                practices in production. Customer-Centric Approach At Pooja Agri
                Products, customer satisfaction is paramount. We actively our
                products. Innovative Solutions We continually invest in research
                and development to innovate and improve our product range. By
                incorporating the latest advancements in technology and trends.
              </p>
              <p>
                At Prince Quality Galss, we understand the importance of
                customer satisfaction and strive to build long-lasting
                relationships with our clients.
              </p>
              <p>
                {" "}
                Art glass highlights the artistry of glassmakers using methods
                such as blowing and fusing, creating one-of-a-kind, vibrant
                pieces. Whether it’s vases or stained glass windows, art glass
                brings sophistication and beauty to any environment.
              </p> */}

              <div className="video-container">
                <img src="./assets/ce-krishboxaraa Fashion.jpg" alt="" />
              </div>
            </div>
          </div>
        );
      case "contact":
        return (
          <div>
            {" "}
            <div className="contact-info">
              <h2>Contact Us</h2>
              <p>
                <strong>Address:</strong>
                H.No.-810,Ram pyare wali gali nai basti firozabad-283203
              </p>
              {/* <p>Contact Person : Mr shalok shrivastav ji </p> */}
              {/* <p><strong>Contact Person:</strong>Mr. Rohit Patel</p> */}
            </div>
            <div className="map-container">
              <h3>Our Location</h3>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14200.807535042664!2d78.37236451781185!3d27.149937587160746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397446ae6e5f6949%3A0xd7673865e7cc1be4!2sNai%20Basti%20Road%20%26%20Nanak%20Wali%20Gali%2C%20Kotla%20Mohalla%2C%20Joshiyan%20Mohalla%2C%20Firozabad%2C%20Uttar%20Pradesh%20283203!5e0!3m2!1sen!2sin!4v1736358652604!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        );
      default:
        return (
          <div>
            <div className="swiper-container">
              <Swiper
                modules={[Autoplay, Navigation, Pagination]}
                slidesPerView={1}
                spaceBetween={10}
                pagination={{ clickable: true }}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onSwiper={setSwiperInstance}
                onReachEnd={handleReachEnd}
                onReachBeginning={handleReachStart}
                breakpoints={{
                  640: { slidesPerView: 2, spaceBetween: 20 },
                  768: { slidesPerView: 4, spaceBetween: 40 },
                  1024: { slidesPerView: 7, spaceBetween: 30 },
                }}
                className="mySwiper"
              >
                {products.map((product) => (
                  <SwiperSlide key={`product-${product.id}`}>
                    <div className="card" onClick={handleProductClick}>
                      <img src={product.image} alt={product.name} />
                      <p>{product.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="welcome">
              <h1>Welcome to the Prince Quality Galss</h1>
              <p>
                Prince Quality Galss is a prominent manufacturer and trader of
                Aloe Vera-based products, established in 2017. Located in Surat,
                Gujarat, our company specializes in producing high-quality Aloe
                Vera Gel, Juice, and Pulp. With a well-equipped infrastructure
                and a commitment to adhering to industry standards, we ensure
                exceptional product quality.
              </p>

              <p>
                we manufacture a diverse range of products, including Aloe Vera
                Gel, Juice, and Pulp, tailored to meet the evolving needs of our
                customers. Our state-of-the-art facility in Surat, Gujarat, is
                equipped with advanced technology, allowing us to maintain
                stringent quality control throughout the production process.
              </p>

              <p>
                With a strong focus on quality and sustainability, we
                manufacture a diverse range of products, including Aloe Vera
                Gel, Juice, and Pulp, tailored to meet the evolving needs of our
                customers.
              </p>
              {/* <h2>High-Quality Fabrics: Premium Kalyani cotton and silk for comfort and durability.:</h2> */}
              <p>
                Innovative – Constantly developing new Aloe Vera products.
                Reliable – Delivering consistent quality in every batch.
              </p>
              <p>
                Sustainable – Focused on eco-friendly manufacturing processes.
                Ethical – Upholding integrity and transparency in all
                operations.
              </p>

              <p>
                Aloe Vera Gel is a versatile, natural skincare product prized
                for its hydrating, soothing, and healing properties. Extracted
                from the Aloe Vera plant, this gel is rich in vitamins,
                minerals, and antioxidants that nourish the skin. It’s widely
                used to treat sunburn, minor cuts, and wounds due to its
                anti-inflammatory and cooling effects. Beyond healing, Aloe Vera
                Gel also deeply moisturizes the skin, making it smoother and
                more supple. Its lightweight, non-greasy texture absorbs
                quickly, making it perfect for everyday use. Suitable for all
                skin types, it leaves the skin feeling refreshed, rejuvenated,
                and healthier.
              </p>
              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets/jhumer1.jpeg"
                      title="Glass Jhumar"
                      alt="Glass Jhumar"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Glass Jhumar</h3>
                    </div>
                    <div className="pro_det_sec">
                      <li>
                        <span>Product:</span> Glass Jhumar (Chandelier Light)
                      </li>
                      <li>
                        <span>Availability:</span> Customizable Designs
                      </li>
                      <li>
                        <span>Description:</span> A chandelier light, with its
                        dazzling array of crystals and intricate design, serves
                        as the centerpiece of any room. It not only illuminates
                        the space but also adds a touch of elegance and
                        sophistication. Each crystal reflects light beautifully,
                        creating a mesmerizing dance of shadows and brilliance.
                      </li>
                      <li>
                        <span>Key Benefits:</span>
                        <ul>
                          <li>
                            <span>Timeless Elegance:</span> Adds sophistication
                            and charm to any environment.
                          </li>
                          <li>
                            <span>Mesmerizing Light Effects:</span> Crystals
                            reflect and refract light, creating a dazzling
                            display.
                          </li>
                          <li>
                            <span>Versatile Styles:</span> Available in designs
                            ranging from classic and ornate to modern and
                            minimalist.
                          </li>
                          <li>
                            <span>Durable Craftsmanship:</span> Made with
                            high-quality materials to ensure long-lasting beauty
                            and functionality.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>Usage:</span> Perfect for enhancing the ambiance
                        of homes, hotels, grand ballrooms, and luxurious spaces.
                      </li>
                      <li>
                        <span>Contact Us:</span> Explore our range of glass
                        jhumars and let us help you select or customize the
                        perfect chandelier to elevate your space.
                      </li>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="company_header">
        <div className="top_head">
          <div className="company_left zdasds">
            <span className="logo_img2" style={{ background: "#d1d786" }}>
              PQ
            </span>

            <div className="company_info">
              <div className="company_name2">
                <h1>
                  Prince Quality Glass{" "}
                  <div className="_oth_info">
                    <a
                      href="https://www.google.co.in/maps/dir//12.9715987,77.5945627"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <b className="fa fa-map-marker" />
                        H.No.-810,Ram pyare wali gali nai basti firozabad-283203{" "}
                      </span>
                    </a>
                  </div>
                </h1>
              </div>
            </div>
          </div>
          {/* <div className="company_right">
                        <Link to="tel:9327973636" className="contact_link">
                            <FontAwesomeIcon icon={faGauge} /> Contact Supplier
                        </Link>
                    </div> */}
        </div>
        <div className="bottom_head">
          <div className={`Premium-Header ${highlight ? "highlight" : ""}`}>
            <ul className="menu_sec">
              <li
                className={activeSection === "home" ? "active" : ""}
                onClick={() => setActiveSection("home")}
              >
                Home <FontAwesomeIcon icon={faGauge} />
              </li>
              <li
                className={activeSection === "products" ? "active" : ""}
                onClick={() => setActiveSection("products")}
              >
                Products <FontAwesomeIcon icon={faShieldHalved} />
              </li>
              <li
                className={activeSection === "about" ? "active" : ""}
                onClick={() => setActiveSection("about")}
              >
                About Us <FontAwesomeIcon icon={faMessage} />
                <ul className="dropdown"></ul>
              </li>
              <li
                className={activeSection === "contact" ? "active" : ""}
                onClick={() => setActiveSection("contact")}
              >
                Contact Us <FontAwesomeIcon icon={faShieldHalved} />
                <ul className="dropdown"></ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {renderContent()}
    </>
  );
};

export default TwentyNineCataloge;
